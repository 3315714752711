import { FormattedMessage, useIntl } from 'react-intl';
import { Typography, View, XStack } from '@shinetools/sunshine-universal';

import { PlanIcon } from '../../PlanIcon';
import { Table } from '../../Table';

import { type MigrationContentComponent } from '..';

export const BusinessToBusinessTable: MigrationContentComponent = ({
  newPlan,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Table
      highlightColumn={2}
      rows={[
        [
          null,
          <Table.Header
            title={formatMessage({
              id: 'pricing.migration.page.current',
            })}
          >
            <Typography.Text bold>Shine Business</Typography.Text>
          </Table.Header>,
          <Table.Header
            title={formatMessage({
              id: 'pricing.migration.page.new',
            })}
          >
            <XStack alignItems="center" gap="$space.8">
              <PlanIcon planId={newPlan.id} />
              <View>
                <Typography.Text bold>{newPlan.brandName}</Typography.Text>
              </View>
            </XStack>
          </Table.Header>,
        ],
        [
          <Table.Header
            title={formatMessage({
              id: 'pricing.migration.page.tables.team_access',
            })}
          />,
          <Table.FeatureInfo variant="discreet">15</Table.FeatureInfo>,
          <Table.FeatureInfo>
            <FormattedMessage id="pricing.migration.page.tables.team_access.unlimited" />
          </Table.FeatureInfo>,
        ],
        [
          <Table.Header
            title={formatMessage({
              id: 'pricing.migration.page.tables.premium_card',
            })}
          />,
          <Table.FeatureInfo variant="discreet">5</Table.FeatureInfo>,
          <Table.FeatureInfo>10</Table.FeatureInfo>,
        ],
        [
          <Table.Header
            title={formatMessage({
              id: 'pricing.migration.page.tables.international_fees',
            })}
          />,
          <Table.FeatureInfo variant="discreet">1,9&nbsp;%</Table.FeatureInfo>,
          <Table.FeatureInfo>1&nbsp;%</Table.FeatureInfo>,
        ],
      ]}
    />
  );
};
