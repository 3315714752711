import { useCallback } from 'react';

import { ChallengeMethod } from '__generated__/GQL';

import useStrongAuthenticationChallengeSubscription from '../graphql/subscriptions/useStrongAuthenticationChallengeSubscription';
import { type ExecuteStrongAuthenticationOperationFunction } from '../utils/types';
import { useExecuteSCAOperation } from './useExecuteSCAOperation';

export type ExecuteMdaSCAOperationPayload = {
  challengeId: string;
  challengeMethod: ChallengeMethod;
  payload: unknown;
  executeStrongAuthenticationOperation: ExecuteStrongAuthenticationOperationFunction;
};

export const useVerifyMdaSCAOperation = ({
  challengeId,
  challengeMethod,
  executeStrongAuthenticationOperation,
  payload,
}: ExecuteMdaSCAOperationPayload) => {
  const { data: verificationResult, loading: isVerifying } =
    useStrongAuthenticationChallengeSubscription(challengeId, {
      skip: !challengeId && challengeMethod !== ChallengeMethod.Mda,
    });

  const { executeOperation, isRequesting } = useExecuteSCAOperation({
    executeStrongAuthenticationOperation,
    payload,
  });

  const verifyMdaOperation = useCallback(async () => {
    if (!verificationResult) {
      return undefined;
    }

    await executeOperation(
      verificationResult.strongAuthenticationChallengeResult,
    );

    return verificationResult.strongAuthenticationChallengeResult;
  }, [verificationResult, executeOperation]);

  return {
    isRequesting,
    isVerifying,
    verifyMdaOperation,
  };
};
