import { useEffect, useRef } from 'react';

import { logEvent } from '../../../features/Analytics/analytics';
import logger from '../../../helpers/logger';

export type PageLoggerArgs<T extends JSONObject = JSONObject> = {
  properties?: T;
  name: string;
  trigger?: boolean;
};

/**
 * Custom hook for logging page views and associated properties.
 * Ensures that the event is logged only once per component mount.
 * Can be triggered to run after data fetching is complete, for example.
 *
 * @template T - The type of the properties object (defaults to `JSONObject`).
 *
 * @param {Object} args - The logger arguments.
 * @param {string} args.name - The name of the event to log.
 * @param {T} [args.properties] - Additional properties to include in the log event.
 * @param {boolean} [args.trigger=true] - Whether the event should be logged.
 *
 * @returns {void}
 *
 * @example
 * usePageLogger({
 *   name: 'Dashboard View',
 *   properties: { userId: 123, plan: 'premium' },
 * });
 */
const usePageLogger = <T extends JSONObject = JSONObject>({
  name,
  properties,
  trigger = true,
}: PageLoggerArgs<T>): void => {
  const hasLogged = useRef(false);

  useEffect(() => {
    if (!trigger || hasLogged.current) {
      return;
    }

    const environment = import.meta.env.VITE_API_ENV;

    logEvent({
      name,
      properties,
    });

    if (environment !== 'production') {
      logger.info(
        `ℹ️ TRACKED VIEW: ${name}`,
        properties ? JSON.stringify(properties) : '',
      );
    }

    hasLogged.current = true;
  }, [properties, name, trigger]);
};

export default usePageLogger;
