import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    close: 'Close',
    description: 'Thank you, you can return to your request.',
    title: 'Request validated',
  },
  fr: {
    close: 'Fermer',
    description: 'Merci, vous pouvez retourner à votre demande.',
    title: 'Demande validée',
  },
} as const);

export default locales;
