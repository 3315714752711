import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography, XStack, YStack } from '@shinetools/sunshine-universal';

import {
  type PlanId,
  type PricingPlan,
  SubscriptionPlanFrequency,
} from '__generated__/GQL';
import SunshineCard from 'components/_core/SunshineCard';
import { logEvent } from 'features/Analytics/analytics';

import { MonthlyPlan } from './components/MonthlyPlan';
import { YearlyPlan } from './components/YearlyPlan';

interface PlanFrequencySwitchProps {
  currentPricingPlanId: PlanId;
  discountPeriodEnd: string | null;
  isDisabled: boolean;
  selectedPricingPlanId: PlanId;
  setSelectedPricingPlanId: (planId: PlanId) => void;
  yearlyPlan: PricingPlan;
  monthlyPlan: PricingPlan;
}

const PlanFrequencySwitch: FC<PlanFrequencySwitchProps> = ({
  currentPricingPlanId,
  discountPeriodEnd,
  isDisabled,
  monthlyPlan,
  selectedPricingPlanId,
  setSelectedPricingPlanId,
  yearlyPlan,
}) => (
  <YStack gap="$space.16">
    <Typography.Text bold size="large">
      <FormattedMessage id="subscription.plans_details.plan_options" />
    </Typography.Text>
    <SunshineCard.RadioGroup
      onChange={(nextValue: PlanId) => setSelectedPricingPlanId(nextValue)}
      value={selectedPricingPlanId}
    >
      <XStack gap="$space.16">
        {[yearlyPlan, monthlyPlan].map((plan) => {
          const isCurrentPlan = currentPricingPlanId === plan.id;

          return (
            <SunshineCard
              isActive={selectedPricingPlanId === plan.id}
              isDisabled={isCurrentPlan || isDisabled}
              onChange={() => {
                if (plan.id === yearlyPlan.id) {
                  logEvent({
                    name: `Account Closure Retention Modal Choose Yearly Subscription ${selectedPricingPlanId}`,
                  });
                }
              }}
              opacity={isCurrentPlan ? 0.5 : 1}
              value={plan.id}
              variant="radio"
            >
              <Typography.Text>
                <FormattedMessage
                  id="subscription.plans_details.plan_options.payment_frequency"
                  values={{
                    isCurrentPlan,
                    isMonthly:
                      plan.billingFrequency ===
                      SubscriptionPlanFrequency.Monthly,
                  }}
                />
              </Typography.Text>
              <YStack gap="$space.8">
                {plan.billingFrequency === SubscriptionPlanFrequency.Monthly ? (
                  <MonthlyPlan
                    discountPeriodEnd={discountPeriodEnd}
                    monthlyPlan={plan}
                  />
                ) : (
                  <YearlyPlan monthlyPlan={monthlyPlan} yearlyPlan={plan} />
                )}
              </YStack>
            </SunshineCard>
          );
        })}
      </XStack>
    </SunshineCard.RadioGroup>
    <Typography.Text size="small">
      {selectedPricingPlanId === monthlyPlan.id ? (
        <FormattedMessage id="subscription.plans_details.plan_options.monthly" />
      ) : (
        <FormattedMessage id="subscription.plans_details.plan_options.yearly" />
      )}
    </Typography.Text>
  </YStack>
);

export default PlanFrequencySwitch;
