import * as Types from '../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type VerifyEmailChallengePublicMutationVariables = Types.Exact<{
  input: Types.VerifyEmailChallengePublicInput;
}>;


export type VerifyEmailChallengePublicMutation = { __typename: 'Mutation', verifyEmailChallengePublic: boolean };


export const VerifyEmailChallengePublicDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"VerifyEmailChallengePublic"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"VerifyEmailChallengePublicInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"verifyEmailChallengePublic"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<VerifyEmailChallengePublicMutation, VerifyEmailChallengePublicMutationVariables>;