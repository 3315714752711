import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Image } from '@chakra-ui/react';
import {
  Button,
  Typography,
  View,
  YStack,
} from '@shinetools/sunshine-universal';

import handWithPhoneNotif from 'assets/images/strongAuthentication/handWithPhoneNotif.svg';
import SunshineModal from 'components/_core/SunshineModal';

export type ConnectedDevicesVerifyingChallengeProps = {
  isOpen: boolean;
  onClose: (arg0: boolean) => void;
  isVerifying: boolean;
};

const ConnectedDevicesVerifyingChallenge: FC<
  ConnectedDevicesVerifyingChallengeProps
> = ({ isOpen, isVerifying, onClose }) => {
  return (
    <SunshineModal isOpen={isOpen} onClose={() => onClose(true)}>
      <YStack alignItems="flex-end" flexDirection="column">
        <View padding="$space.16">
          <Button
            hugContent={true}
            icon="cross"
            isLoading={isVerifying}
            onPress={() => {}}
            variant="secondary"
          >
            <FormattedMessage id="authentication.challenge.verifying" />
          </Button>
        </View>
      </YStack>
      <SunshineModal.Body padding={0}>
        <View backgroundColor="$grey.200" height={208} padding={0}>
          <Image marginX="auto" src={handWithPhoneNotif} width="416px" />
        </View>

        <View gap="$space.16" padding="$space.32">
          <SunshineModal.Title>
            <FormattedMessage id="authentication.challenge.verifying_connected_devices_title" />
          </SunshineModal.Title>
          <Typography.Text>
            <FormattedMessage id="authentication.challenge.verifying_connected_devices_description" />
          </Typography.Text>
        </View>
      </SunshineModal.Body>
    </SunshineModal>
  );
};

export default ConnectedDevicesVerifyingChallenge;
