import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/client';
import { Stack } from '@chakra-ui/react';
import { Feature } from '@shinetools/pricing-plan-library';
import { Typography } from '@shinetools/sunshine-universal';

import { type PlanId } from '__generated__/GQL';
import { useCurrentPricingPlan } from 'common/hooks/useCurrentPricingPlan';
import Button from 'components/_core/Button';
import Modal, { type ModalProps } from 'components/_core/Modal';

import { Table } from '../Table';
import { FeesCtx } from './context';
import * as GQL from './fees.gql';
import { AccountIrregularUse } from './fees/AccountIrregularUse';
import { AdditionalCard } from './fees/AdditionalCard';
import { AtdManagement } from './fees/AtdManagement';
import { BalanceLetterConfirmation } from './fees/BalanceLetterConfirmation';
import { BankTransferCertificate } from './fees/BankTransferCertificate';
import { CardPayinEuro } from './fees/CardPayinEuro';
import { CardPayinNonEuro } from './fees/CardPayinNonEuro';
import { CardPaymentDispute } from './fees/CardPaymentDispute';
import { CardPayoutEuro } from './fees/CardPayoutEuro';
import { CardPayoutNonEuro } from './fees/CardPayoutNonEuro';
import { CardReplacement } from './fees/CardReplacement';
import { CashDeposit } from './fees/CashDeposit';
import { CheckDeposit } from './fees/CheckDeposit';
import { CheckDepositInvalid } from './fees/CheckDepositInvalid';
import { CheckDepositRefused } from './fees/CheckDepositRefused';
import { OtherCertificate } from './fees/OtherCertificate';
import { PaymentCard } from './fees/PaymentCard';
import { SepaTransfer } from './fees/SepaTransfer';
import { WithdrawalEuro } from './fees/WithdrawalEuro';
import { WithdrawalNonEuro } from './fees/WithdrawalNonEuro';

const columnsWidth = '60% 40%';

export type ModalFeesProps = Omit<ModalProps, 'children'> & {
  companyProfileId: string;
  planId: PlanId;
};

export const ModalFees: FC<ModalFeesProps> = (props) => {
  const { companyProfileId, planId, ...rest } = props;

  const { isFeatureAvailable } = useCurrentPricingPlan();

  const { data, loading } = useQuery(GQL.SubscriptionManagementFeesDocument, {
    variables: {
      companyProfileId: companyProfileId,
    },
  });

  const currentPlan = data?.viewer.company.currentPlan;
  const subscriptionPlan = data?.viewer.company.subscriptionPlan;

  return (
    <Modal {...rest} scrollBehavior="inside">
      <Modal.Header borderBottom="1px solid" borderColor="grey.3" />
      <Modal.Body paddingY="space-32">
        <Stack spacing="space-32">
          <Stack spacing="space-8">
            <Typography.Header>
              <FormattedMessage
                id="subscription.fees_modal.heading"
                values={{
                  plan: currentPlan?.brandName,
                }}
              />
            </Typography.Header>

            <Typography.Text variant="secondary">
              <FormattedMessage id="subscription.fees_modal.subheading" />
            </Typography.Text>
          </Stack>

          <Stack spacing="space-16">
            {!loading && currentPlan && subscriptionPlan ? (
              <FeesCtx.Provider
                value={{
                  fees: currentPlan.fees,
                  plan: currentPlan,
                  quotas: subscriptionPlan.quotas,
                }}
              >
                <Table gridTemplateColumns={columnsWidth}>
                  <Table.Header>
                    <Table.Column>
                      <FormattedMessage id="subscription.fees_modal.column_label" />
                    </Table.Column>

                    <Table.Column width="full">
                      <Typography.Text variant="secondary">
                        <FormattedMessage id="subscription.fees_modal.column_value" />
                      </Typography.Text>
                    </Table.Column>
                  </Table.Header>

                  <Table.Body>
                    <SepaTransfer />
                    <PaymentCard />
                  </Table.Body>
                </Table>

                <Table gridTemplateColumns={columnsWidth}>
                  <CardPayoutEuro />
                  <CardPayoutNonEuro />
                </Table>

                <Table gridTemplateColumns={columnsWidth}>
                  <WithdrawalEuro />
                  <WithdrawalNonEuro />
                </Table>

                <Table gridTemplateColumns={columnsWidth}>
                  <CardPayinEuro />
                  <CardPayinNonEuro />
                </Table>

                {isFeatureAvailable(Feature.CheckDeposit) ? (
                  <Table gridTemplateColumns={columnsWidth}>
                    <CheckDeposit />
                    <CheckDepositInvalid />
                    <CheckDepositRefused />
                  </Table>
                ) : null}

                {isFeatureAvailable(Feature.CashDeposit) ? (
                  <Table gridTemplateColumns={columnsWidth}>
                    <CashDeposit />
                  </Table>
                ) : null}

                {isFeatureAvailable(Feature.TeamManagement) ? (
                  <Table gridTemplateColumns={columnsWidth}>
                    <AdditionalCard />
                  </Table>
                ) : null}

                <Table gridTemplateColumns={columnsWidth}>
                  <BankTransferCertificate />
                  <OtherCertificate />
                </Table>

                <Table gridTemplateColumns={columnsWidth}>
                  <CardReplacement />
                  <CardPaymentDispute />
                  <AccountIrregularUse />
                  <AtdManagement />
                  <BalanceLetterConfirmation />
                </Table>
              </FeesCtx.Provider>
            ) : (
              <Table gridTemplateColumns={columnsWidth}>
                <Table.Header>
                  <Table.Column>
                    <FormattedMessage id="subscription.fees_modal.column_label" />
                  </Table.Column>

                  <Table.Column width="full">
                    <Typography.Text variant="secondary">
                      <FormattedMessage id="subscription.fees_modal.column_value" />
                    </Typography.Text>
                  </Table.Column>
                </Table.Header>
                <Table.Body>
                  <Table.LoadingRow
                    in
                    noOfColumns={2}
                    noOfLinesTemplate={[1, 1]}
                    noOfRows={8}
                  />
                </Table.Body>
              </Table>
            )}
          </Stack>
        </Stack>
      </Modal.Body>

      <Modal.Footer justifyContent="flex-end">
        <Button isDisabled={loading} onClick={props.onClose}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
