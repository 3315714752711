import { type FC } from 'react';

import WaitingForValidationChallenge from '../../components/WaitingForValidationChallenge';
import { useVerifyMdaSCAOperation } from '../../hooks/useVerifyMdaSCAOperation';
import { type StrongAuthenticationChallengeChildModalProps } from '../../utils/types';

const AsyncChallenge: FC<StrongAuthenticationChallengeChildModalProps> = ({
  challengeId,
  challengeMethod,
  executeStrongAuthenticationOperation,
  isOpen,
  onClose,
  payload,
}) => {
  // Hook that executed only if challengeId is set
  const { isRequesting, isVerifying, verifyMdaOperation } =
    useVerifyMdaSCAOperation({
      challengeId,
      challengeMethod,
      executeStrongAuthenticationOperation,
      payload,
    });

  return (
    <WaitingForValidationChallenge
      isOpen={isOpen}
      isRequesting={isRequesting}
      isVerifying={isVerifying}
      method={challengeMethod}
      onClose={onClose}
      verifyMdaOperation={verifyMdaOperation}
    />
  );
};

export default AsyncChallenge;
