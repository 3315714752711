import { type ReactNode } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { type IconName } from '@shinetools/sunshine-icons';

import { PlanId } from '__generated__/GQL';
import { type OnboardingPlanDisplay } from 'features/Onboarding/modules/Pricing/types';
import { PLANS_CARD_IMAGES } from 'features/SubscriptionManagement/lib/cardImages';
import { type SubscriptionManagementPlansDetailFragment } from 'features/SubscriptionManagement/subscriptionManagementPlansDetail.gql';

export type PlanMetadataDescription =
  | Pick<SubscriptionManagementPlansDetailFragment, 'limits' | 'fees'>
  | Pick<OnboardingPlanDisplay, 'limits' | 'fees'>;

export type PlansMetadata = Record<
  PlanId,
  {
    card: string;
    requiresInsuranceTermsAgreement: boolean;
    summary: Array<{
      icon: IconName;
      description: (pricingPlan: PlanMetadataDescription) => ReactNode;
    }>;
    sellingPoints: Array<
      (pricingPlan: SubscriptionManagementPlansDetailFragment) => ReactNode
    >;
    lostFeaturesOnDowngrade: Partial<
      Record<
        PlanId,
        Array<{
          icon: IconName;
          description: () => ReactNode;
        }>
      >
    >;
  }
>;

export const PLANS_METADATA: PlansMetadata = {
  /**
   * 2025 plans
   */
  [PlanId.FreeMonthly_2025]: {
    card: PLANS_CARD_IMAGES[PlanId.FreeMonthly_2025],
    lostFeaturesOnDowngrade: {},
    requiresInsuranceTermsAgreement: false,
    sellingPoints: [
      () => (
        <FormattedMessage id="subscription.change_plan.basic.checklist.pro_bank_account" />
      ),
      (pricingPlan) => (
        <FormattedMessage
          id="subscription.change_plan.basic.checklist.physical_payment_card"
          values={{
            cardName: (
              <FormattedMessage id="subscription.plans.payment_card.mastercard_basic" />
            ),
            count: pricingPlan.limits.physicalPaymentCard.value,
          }}
        />
      ),
      (pricingPlan) => (
        <FormattedMessage
          id="subscription.change_plan.basic.checklist.sepa_transfer_month"
          values={{
            count: pricingPlan.limits.sepaTransferMonth.value,
          }}
        />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.basic.checklist.basic_features" />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.basic.checklist.basic_support" />
      ),
    ],
    summary: [
      {
        description: (pricingPlan) => (
          <FormattedMessage
            id="subscription.plan_details_2025.summary.free.account_and_card"
            values={{
              count: pricingPlan.limits.physicalPaymentCard.value,
            }}
          />
        ),
        icon: 'tick',
      },
      {
        description: (pricingPlan) => (
          <FormattedMessage
            id="subscription.plan_details_2025.summary.free.sepa_transfer"
            values={{
              count: pricingPlan.limits.sepaTransferMonth.value,
            }}
          />
        ),
        icon: 'tick',
      },
      {
        description: (pricingPlan) => (
          <FormattedMessage
            id="subscription.plan_details_2025.summary.free.invoicing_quota"
            values={{
              count: pricingPlan.limits.invoicingMonth.value,
            }}
          />
        ),
        icon: 'tick',
      },
    ],
  },
  [PlanId.StartMonthly_2025]: {
    card: PLANS_CARD_IMAGES[PlanId.StartMonthly_2025],
    lostFeaturesOnDowngrade: {
      [PlanId.FreeMonthly_2025]: [
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.pro_to_basic.credit_card_limits_down" />
          ),
          icon: 'card-recto',
        },
      ],
    },
    requiresInsuranceTermsAgreement: false,
    sellingPoints: [
      () => (
        <FormattedMessage id="subscription.change_plan.basic.checklist.pro_bank_account" />
      ),
      (pricingPlan) => (
        <FormattedMessage
          id="subscription.change_plan.basic.checklist.physical_payment_card"
          values={{
            cardName: (
              <FormattedMessage id="subscription.plans.payment_card.mastercard_basic" />
            ),
            count: pricingPlan.limits.physicalPaymentCard.value,
          }}
        />
      ),
      (pricingPlan) => (
        <FormattedMessage
          id="subscription.change_plan.basic.checklist.sepa_transfer_month"
          values={{
            count: pricingPlan.limits.sepaTransferMonth.value,
          }}
        />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.basic.checklist.basic_features" />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.basic.checklist.basic_support" />
      ),
    ],
    summary: [
      {
        description: (pricingPlan) => (
          <FormattedMessage
            id="subscription.plan_details_2025.summary.start.wallets"
            values={{ count: pricingPlan.limits.activeBankAccounts.value - 1 }}
          />
        ),
        icon: 'tick',
      },
      {
        description: () => (
          <FormattedMessage id="subscription.plan_details_2025.summary.start.sepa_transfer" />
        ),
        icon: 'tick',
      },
      {
        description: () => (
          <FormattedMessage id="subscription.plan_details_2025.summary.start.invoicing_quota" />
        ),
        icon: 'tick',
      },
      {
        description: () => (
          <FormattedMessage id="subscription.plan_details_2025.summary.start.accounting" />
        ),
        icon: 'tick',
      },
    ],
  },
  [PlanId.StartYearly_2025]: {
    card: PLANS_CARD_IMAGES[PlanId.StartYearly_2025],
    lostFeaturesOnDowngrade: {
      [PlanId.FreeMonthly_2025]: [
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.pro_to_basic.credit_card_limits_down" />
          ),
          icon: 'card-recto',
        },
      ],
    },
    requiresInsuranceTermsAgreement: false,
    sellingPoints: [
      () => (
        <FormattedMessage id="subscription.change_plan.basic.checklist.pro_bank_account" />
      ),
      (pricingPlan) => (
        <FormattedMessage
          id="subscription.change_plan.basic.checklist.physical_payment_card"
          values={{
            cardName: (
              <FormattedMessage id="subscription.plans.payment_card.mastercard_basic" />
            ),
            count: pricingPlan.limits.physicalPaymentCard.value,
          }}
        />
      ),
      (pricingPlan) => (
        <FormattedMessage
          id="subscription.change_plan.basic.checklist.sepa_transfer_month"
          values={{
            count: pricingPlan.limits.sepaTransferMonth.value,
          }}
        />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.basic.checklist.basic_features" />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.basic.checklist.basic_support" />
      ),
    ],
    summary: [
      {
        description: (pricingPlan) => (
          <FormattedMessage
            id="subscription.plan_details_2025.summary.start.wallets"
            // activeBankAccounts includes both main and wallet accounts so we subtract 1 (main)
            values={{ count: pricingPlan.limits.activeBankAccounts.value - 1 }}
          />
        ),
        icon: 'tick',
      },
      {
        description: () => (
          <FormattedMessage id="subscription.plan_details_2025.summary.start.sepa_transfer" />
        ),
        icon: 'tick',
      },
      {
        description: () => (
          <FormattedMessage id="subscription.plan_details_2025.summary.start.invoicing_quota" />
        ),
        icon: 'tick',
      },
      {
        description: () => (
          <FormattedMessage id="subscription.plan_details_2025.summary.start.accounting" />
        ),
        icon: 'tick',
      },
    ],
  },
  [PlanId.PlusMonthly_2025]: {
    card: PLANS_CARD_IMAGES[PlanId.PlusMonthly_2025],
    lostFeaturesOnDowngrade: {
      [PlanId.FreeMonthly_2025]: [
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.pro_to_basic.team_access_down" />
          ),
          icon: 'team',
        },
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.pro_to_basic.credit_card_limits_down" />
          ),
          icon: 'card-recto',
        },
      ],
      [PlanId.StartMonthly_2025]: [
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.pro_to_plus.team_access_down" />
          ),
          icon: 'team',
        },
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.pro_to_plus.credit_card_limits_down" />
          ),
          icon: 'card-recto',
        },
      ],
      [PlanId.StartYearly_2025]: [
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.pro_to_plus.team_access_down" />
          ),
          icon: 'team',
        },
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.pro_to_plus.credit_card_limits_down" />
          ),
          icon: 'card-recto',
        },
      ],
    },
    requiresInsuranceTermsAgreement: true,
    sellingPoints: [
      () => (
        <FormattedMessage id="subscription.change_plan.pro.checklist.intro" />
      ),
      (pricingPlan) => (
        <FormattedMessage
          id="subscription.change_plan.pro.checklist.sepa_transfer_month"
          values={{
            count: pricingPlan.limits.sepaTransferMonth.value,
          }}
        />
      ),
      (pricingPlan) => (
        <FormattedMessage
          id="subscription.change_plan.pro.checklist.cash_deposit"
          values={{
            amount: (
              <FormattedNumber
                currency="EUR"
                maximumFractionDigits={0}
                style="currency"
                value={
                  (pricingPlan.limits.cashDepositMonth.value *
                    pricingPlan.limits.cashDepositMaxAmountPerDeposit.value) /
                  100
                }
              />
            ),
          }}
        />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.pro.checklist.accountant_access" />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.pro.checklist.team_access" />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.pro.checklist.more_insurances" />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.pro.checklist.assistance" />
      ),
    ],
    summary: [
      {
        description: (pricingPlan) => (
          <FormattedMessage
            id="subscription.plan_details_2025.summary.plus.cards_and_members"
            values={{
              // companyUserAccess doesn't include AH so we add 1
              count: pricingPlan.limits.companyUserAccess.value + 1,
            }}
          />
        ),
        icon: 'tick',
      },
      {
        description: () => (
          <FormattedMessage id="subscription.plan_details_2025.summary.plus.sepa_transfer" />
        ),
        icon: 'tick',
      },
      {
        description: (pricingPlan) => (
          <FormattedMessage
            id="subscription.plan_details_2025.summary.plus.wallet"
            values={{
              // activeBankAccounts includes both main and wallet accounts so we subtract 1 (main)
              count: pricingPlan.limits.activeBankAccounts.value - 1,
            }}
          />
        ),
        icon: 'tick',
      },
      {
        description: () => (
          <FormattedMessage id="subscription.plan_details_2025.summary.plus.insurances" />
        ),
        icon: 'tick',
      },
    ],
  },
  [PlanId.PlusYearly_2025]: {
    card: PLANS_CARD_IMAGES[PlanId.PlusYearly_2025],
    lostFeaturesOnDowngrade: {
      [PlanId.FreeMonthly_2025]: [
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.pro_to_basic.team_access_down" />
          ),
          icon: 'team',
        },
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.pro_to_basic.credit_card_limits_down" />
          ),
          icon: 'card-recto',
        },
      ],
      [PlanId.StartMonthly_2025]: [
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.pro_to_plus.team_access_down" />
          ),
          icon: 'team',
        },
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.pro_to_plus.credit_card_limits_down" />
          ),
          icon: 'card-recto',
        },
      ],
      [PlanId.StartYearly_2025]: [
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.pro_to_plus.team_access_down" />
          ),
          icon: 'team',
        },
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.pro_to_plus.credit_card_limits_down" />
          ),
          icon: 'card-recto',
        },
      ],
    },
    requiresInsuranceTermsAgreement: true,
    sellingPoints: [
      () => (
        <FormattedMessage id="subscription.change_plan.pro.checklist.intro" />
      ),
      (pricingPlan) => (
        <FormattedMessage
          id="subscription.change_plan.pro.checklist.sepa_transfer_month"
          values={{
            count: pricingPlan.limits.sepaTransferMonth.value,
          }}
        />
      ),
      (pricingPlan) => (
        <FormattedMessage
          id="subscription.change_plan.pro.checklist.cash_deposit"
          values={{
            amount: (
              <FormattedNumber
                currency="EUR"
                maximumFractionDigits={0}
                style="currency"
                value={
                  (pricingPlan.limits.cashDepositMonth.value *
                    pricingPlan.limits.cashDepositMaxAmountPerDeposit.value) /
                  100
                }
              />
            ),
          }}
        />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.pro.checklist.accountant_access" />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.pro.checklist.team_access" />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.pro.checklist.more_insurances" />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.pro.checklist.assistance" />
      ),
    ],
    summary: [
      {
        description: (pricingPlan) => (
          <FormattedMessage
            id="subscription.plan_details_2025.summary.plus.cards_and_members"
            values={{
              // companyUserAccess doesn't include AH so we add 1
              count: pricingPlan.limits.companyUserAccess.value + 1,
            }}
          />
        ),
        icon: 'tick',
      },
      {
        description: () => (
          <FormattedMessage id="subscription.plan_details_2025.summary.plus.sepa_transfer" />
        ),
        icon: 'tick',
      },
      {
        description: (pricingPlan) => (
          <FormattedMessage
            id="subscription.plan_details_2025.summary.plus.wallet"
            values={{
              // activeBankAccounts includes both main and wallet accounts so we subtract 1 (main)
              count: pricingPlan.limits.activeBankAccounts.value - 1,
            }}
          />
        ),
        icon: 'tick',
      },
      {
        description: () => (
          <FormattedMessage id="subscription.plan_details_2025.summary.plus.insurances" />
        ),
        icon: 'tick',
      },
    ],
  },
  [PlanId.BusinessMonthly_2025]: {
    card: PLANS_CARD_IMAGES[PlanId.BusinessMonthly_2025],
    lostFeaturesOnDowngrade: {
      [PlanId.FreeMonthly_2025]: [
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_basic.team_access_down" />
          ),
          icon: 'team',
        },
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_basic.credit_card_limits_down" />
          ),
          icon: 'card-recto',
        },
      ],
      [PlanId.StartMonthly_2025]: [
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_basic.team_access_down" />
          ),
          icon: 'team',
        },
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_basic.credit_card_limits_down" />
          ),
          icon: 'card-recto',
        },
      ],
      [PlanId.StartYearly_2025]: [
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_basic.team_access_down" />
          ),
          icon: 'team',
        },
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_basic.credit_card_limits_down" />
          ),
          icon: 'card-recto',
        },
      ],
      [PlanId.PlusMonthly_2025]: [
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_plus.team_access_down" />
          ),
          icon: 'team',
        },
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_plus.credit_card_limits_down" />
          ),
          icon: 'card-recto',
        },
      ],
      [PlanId.PlusYearly_2025]: [
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_plus.team_access_down" />
          ),
          icon: 'team',
        },
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_plus.credit_card_limits_down" />
          ),
          icon: 'card-recto',
        },
      ],
    },
    requiresInsuranceTermsAgreement: true,
    sellingPoints: [
      () => (
        <FormattedMessage id="subscription.change_plan.business.checklist.intro" />
      ),
      (pricingPlan) => (
        <FormattedMessage
          id="subscription.change_plan.business.checklist.sepa_transfer_month"
          values={{
            count: pricingPlan.limits.sepaTransferMonth.value,
          }}
        />
      ),
      (pricingPlan) => (
        <FormattedMessage
          id="subscription.change_plan.business.checklist.cash_deposit"
          values={{
            amount: (
              <FormattedNumber
                currency="EUR"
                maximumFractionDigits={0}
                style="currency"
                value={
                  (pricingPlan.limits.cashDepositMonth.value *
                    pricingPlan.limits.cashDepositMaxAmountPerDeposit.value) /
                  100
                }
              />
            ),
          }}
        />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.business.checklist.accountant_access" />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.business.checklist.team_access" />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.business.checklist.more_insurances" />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.business.checklist.assistance" />
      ),
    ],
    summary: [
      {
        description: () => (
          <FormattedMessage id="subscription.plan_details_2025.summary.business.team_access" />
        ),
        icon: 'tick',
      },
      {
        description: () => (
          <FormattedMessage id="subscription.plan_details_2025.summary.business.team_expenses" />
        ),
        icon: 'tick',
      },
      {
        description: (pricingPlan) => (
          <FormattedMessage
            id="subscription.plan_details_2025.summary.business.cards"
            values={{
              count: pricingPlan.limits.physicalPaymentCard.value,
            }}
          />
        ),
        icon: 'tick',
      },
      {
        description: () => (
          <FormattedMessage id="subscription.plan_details_2025.summary.business.sepa_transfer_quota" />
        ),
        icon: 'tick',
      },
      {
        description: () => (
          <FormattedMessage id="subscription.plan_details_2025.summary.business.insurances" />
        ),
        icon: 'tick',
      },
    ],
  },
  [PlanId.BusinessYearly_2025]: {
    card: PLANS_CARD_IMAGES[PlanId.BusinessYearly_2025],
    lostFeaturesOnDowngrade: {
      [PlanId.FreeMonthly_2025]: [
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_basic.team_access_down" />
          ),
          icon: 'team',
        },
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_basic.credit_card_limits_down" />
          ),
          icon: 'card-recto',
        },
      ],
      [PlanId.StartMonthly_2025]: [
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_basic.team_access_down" />
          ),
          icon: 'team',
        },
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_basic.credit_card_limits_down" />
          ),
          icon: 'card-recto',
        },
      ],
      [PlanId.StartYearly_2025]: [
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_basic.team_access_down" />
          ),
          icon: 'team',
        },
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_basic.credit_card_limits_down" />
          ),
          icon: 'card-recto',
        },
      ],
      [PlanId.PlusMonthly_2025]: [
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_plus.team_access_down" />
          ),
          icon: 'team',
        },
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_plus.credit_card_limits_down" />
          ),
          icon: 'card-recto',
        },
      ],
      [PlanId.PlusYearly_2025]: [
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_plus.team_access_down" />
          ),
          icon: 'team',
        },
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_plus.credit_card_limits_down" />
          ),
          icon: 'card-recto',
        },
      ],
    },
    requiresInsuranceTermsAgreement: true,
    sellingPoints: [
      () => (
        <FormattedMessage id="subscription.change_plan.business.checklist.intro" />
      ),
      (pricingPlan) => (
        <FormattedMessage
          id="subscription.change_plan.business.checklist.sepa_transfer_month"
          values={{
            count: pricingPlan.limits.sepaTransferMonth.value,
          }}
        />
      ),
      (pricingPlan) => (
        <FormattedMessage
          id="subscription.change_plan.business.checklist.cash_deposit"
          values={{
            amount: (
              <FormattedNumber
                currency="EUR"
                maximumFractionDigits={0}
                style="currency"
                value={
                  (pricingPlan.limits.cashDepositMonth.value *
                    pricingPlan.limits.cashDepositMaxAmountPerDeposit.value) /
                  100
                }
              />
            ),
          }}
        />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.business.checklist.accountant_access" />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.business.checklist.team_access" />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.business.checklist.more_insurances" />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.business.checklist.assistance" />
      ),
    ],
    summary: [
      {
        description: () => (
          <FormattedMessage id="subscription.plan_details_2025.summary.business.team_access" />
        ),
        icon: 'tick',
      },
      {
        description: () => (
          <FormattedMessage id="subscription.plan_details_2025.summary.business.team_expenses" />
        ),
        icon: 'tick',
      },
      {
        description: (pricingPlan) => (
          <FormattedMessage
            id="subscription.plan_details_2025.summary.business.cards"
            values={{
              count: pricingPlan.limits.physicalPaymentCard.value,
            }}
          />
        ),
        icon: 'tick',
      },
      {
        description: () => (
          <FormattedMessage id="subscription.plan_details_2025.summary.business.sepa_transfer_quota" />
        ),
        icon: 'tick',
      },
      {
        description: () => (
          <FormattedMessage id="subscription.plan_details_2025.summary.business.insurances" />
        ),
        icon: 'tick',
      },
    ],
  },

  /**
   * 2023 plans
   */
  [PlanId.Basic]: {
    card: PLANS_CARD_IMAGES[PlanId.Basic],
    lostFeaturesOnDowngrade: {},
    requiresInsuranceTermsAgreement: false,
    sellingPoints: [
      () => (
        <FormattedMessage id="subscription.change_plan.basic.checklist.pro_bank_account" />
      ),
      (pricingPlan) => (
        <FormattedMessage
          id="subscription.change_plan.basic.checklist.physical_payment_card"
          values={{
            cardName: (
              <FormattedMessage id="subscription.plans.payment_card.mastercard_basic" />
            ),
            count: pricingPlan.limits.physicalPaymentCard.value,
          }}
        />
      ),
      (pricingPlan) => (
        <FormattedMessage
          id="subscription.change_plan.basic.checklist.sepa_transfer_month"
          values={{
            count: pricingPlan.limits.sepaTransferMonth.value,
          }}
        />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.basic.checklist.basic_features" />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.basic.checklist.basic_support" />
      ),
    ],
    summary: [
      {
        description: () => (
          <FormattedMessage id="subscription.plan_details.summary.basic.pro_account" />
        ),
        icon: 'wallet',
      },
      {
        description: (pricingPlan) => (
          <FormattedMessage
            id="subscription.plan_details.summary.basic.physical_payment_card"
            values={{
              count: pricingPlan.limits.physicalPaymentCard.value,
            }}
          />
        ),
        icon: 'card-recto',
      },
      {
        description: (pricingPlan) => (
          <FormattedMessage
            id="subscription.plan_details.summary.basic.sepa_transfer_month"
            values={{
              count: pricingPlan.limits.sepaTransferMonth.value,
              variableFee: (
                <FormattedNumber
                  currency="EUR"
                  maximumFractionDigits={2}
                  style="currency"
                  value={pricingPlan.fees.additionalSepaTransfer.fixed / 100}
                />
              ),
            }}
          />
        ),
        icon: 'bulk-transfer',
      },
    ],
  },
  [PlanId.Plus]: {
    card: PLANS_CARD_IMAGES[PlanId.Plus],
    lostFeaturesOnDowngrade: {
      [PlanId.Basic]: [
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.plus_to_basic.credit_card_limits_down" />
          ),
          icon: 'card-recto',
        },
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.plus_to_basic.product_and_services" />
          ),
          icon: 'tag',
        },
      ],
    },
    requiresInsuranceTermsAgreement: true,
    sellingPoints: [
      () => (
        <FormattedMessage id="subscription.change_plan.plus.checklist.intro" />
      ),
      (pricingPlan) => (
        <FormattedMessage
          id="subscription.change_plan.plus.checklist.physical_payment_card"
          values={{
            cardName: (
              <FormattedMessage id="subscription.plans.payment_card.mastercard_business_world_debit" />
            ),
            count: pricingPlan.limits.physicalPaymentCard.value,
          }}
        />
      ),
      (pricingPlan) => (
        <FormattedMessage
          id="subscription.change_plan.plus.checklist.sepa_transfer_month"
          values={{
            count: pricingPlan.limits.sepaTransferMonth.value,
          }}
        />
      ),
      (pricingPlan) => (
        <FormattedMessage
          id="subscription.change_plan.plus.checklist.cash_deposit"
          values={{
            amount: (
              <FormattedNumber
                currency="EUR"
                maximumFractionDigits={0}
                style="currency"
                value={
                  (pricingPlan.limits.cashDepositMonth.value *
                    pricingPlan.limits.cashDepositMaxAmountPerDeposit.value) /
                  100
                }
              />
            ),
          }}
        />
      ),
      (pricingPlan) => (
        <FormattedMessage
          id="subscription.change_plan.plus.checklist.advanced_functionalities"
          values={{
            checkDepositIncluded: pricingPlan.limits.checkDepositMonth.value,
            virtualCardsIncluded:
              pricingPlan.limits.virtualPaymentCardMonth.value,
          }}
        />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.plus.checklist.exclusive_insurances" />
      ),
    ],
    summary: [
      {
        description: (pricingPlan) => (
          <FormattedMessage
            id="subscription.plan_details.summary.plus.wallets"
            values={{
              count: pricingPlan.limits.activeBankAccounts.value,
            }}
          />
        ),
        icon: 'wallet',
      },
      {
        description: (pricingPlan) => (
          <FormattedMessage
            id="subscription.plan_details.summary.plus.physical_payment_card"
            values={{
              count: pricingPlan.limits.physicalPaymentCard.value,
            }}
          />
        ),
        icon: 'card-recto',
      },
      {
        description: (pricingPlan) => (
          <FormattedMessage
            id="subscription.plan_details.summary.plus.sepa_transfer_month"
            values={{
              count: pricingPlan.limits.sepaTransferMonth.value,
              variableFee: (
                <FormattedNumber
                  currency="EUR"
                  maximumFractionDigits={2}
                  style="currency"
                  value={pricingPlan.fees.additionalSepaTransfer.fixed / 100}
                />
              ),
            }}
          />
        ),
        icon: 'bulk-transfer',
      },
      {
        description: () => (
          <FormattedMessage id="subscription.plan_details.summary.plus.insurances" />
        ),
        icon: 'insurance',
      },
    ],
  },
  [PlanId.Pro]: {
    card: PLANS_CARD_IMAGES[PlanId.Pro],
    lostFeaturesOnDowngrade: {
      [PlanId.Plus]: [
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.pro_to_plus.team_access_down" />
          ),
          icon: 'team',
        },
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.pro_to_plus.credit_card_limits_down" />
          ),
          icon: 'card-recto',
        },
      ],
      [PlanId.Basic]: [
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.pro_to_basic.team_access_down" />
          ),
          icon: 'team',
        },
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.pro_to_basic.credit_card_limits_down" />
          ),
          icon: 'card-recto',
        },
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.pro_to_basic.services_down" />
          ),
          icon: 'tag',
        },
      ],
    },
    requiresInsuranceTermsAgreement: true,
    sellingPoints: [
      () => (
        <FormattedMessage id="subscription.change_plan.pro.checklist.intro" />
      ),
      (pricingPlan) => (
        <FormattedMessage
          id="subscription.change_plan.pro.checklist.sepa_transfer_month"
          values={{
            count: pricingPlan.limits.sepaTransferMonth.value,
          }}
        />
      ),
      (pricingPlan) => (
        <FormattedMessage
          id="subscription.change_plan.pro.checklist.cash_deposit"
          values={{
            amount: (
              <FormattedNumber
                currency="EUR"
                maximumFractionDigits={0}
                style="currency"
                value={
                  (pricingPlan.limits.cashDepositMonth.value *
                    pricingPlan.limits.cashDepositMaxAmountPerDeposit.value) /
                  100
                }
              />
            ),
          }}
        />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.pro.checklist.accountant_access" />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.pro.checklist.team_access" />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.pro.checklist.more_insurances" />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.pro.checklist.assistance" />
      ),
    ],
    summary: [
      {
        description: (pricingPlan) => (
          <FormattedMessage
            id="subscription.plan_details.summary.pro.wallets"
            values={{
              count: pricingPlan.limits.activeBankAccounts.value,
            }}
          />
        ),
        icon: 'wallet',
      },
      {
        description: (pricingPlan) => (
          <FormattedMessage
            id="subscription.plan_details.summary.pro.cards_and_members"
            values={{
              membersCount: pricingPlan.limits.companyUserAccess.value,
              physicalCardsCount: pricingPlan.limits.physicalPaymentCard.value,
            }}
          />
        ),
        icon: 'card-recto',
      },
      {
        description: (pricingPlan) => (
          <FormattedMessage
            id="subscription.plan_details.summary.pro.sepa_transfer_month"
            values={{
              count: pricingPlan.limits.sepaTransferMonth.value,
              variableFee: (
                <FormattedNumber
                  currency="EUR"
                  maximumFractionDigits={2}
                  style="currency"
                  value={pricingPlan.fees.additionalSepaTransfer.fixed / 100}
                />
              ),
            }}
          />
        ),
        icon: 'bulk-transfer',
      },
      {
        description: () => (
          <FormattedMessage id="subscription.plan_details.summary.pro.insurances" />
        ),
        icon: 'insurance',
      },
    ],
  },
  [PlanId.Business]: {
    card: PLANS_CARD_IMAGES[PlanId.Business],
    lostFeaturesOnDowngrade: {
      [PlanId.Basic]: [
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_basic.team_access_down" />
          ),
          icon: 'team',
        },
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_basic.credit_card_limits_down" />
          ),
          icon: 'card-recto',
        },
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_basic.services_down" />
          ),
          icon: 'tag',
        },
      ],
      [PlanId.Plus]: [
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_plus.team_access_down" />
          ),
          icon: 'team',
        },
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_plus.credit_card_limits_down" />
          ),
          icon: 'card-recto',
        },
      ],
      [PlanId.Pro]: [
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_pro.team_access_down" />
          ),
          icon: 'team',
        },
        {
          description: () => (
            <FormattedMessage id="subscription.plan_details.downgrade.lost_features.business_to_pro.credit_card_limits_down" />
          ),
          icon: 'card-recto',
        },
      ],
    },
    requiresInsuranceTermsAgreement: true,
    sellingPoints: [
      () => (
        <FormattedMessage id="subscription.change_plan.business.checklist.intro" />
      ),
      (pricingPlan) => (
        <FormattedMessage
          id="subscription.change_plan.business.checklist.sepa_transfer_month"
          values={{
            count: pricingPlan.limits.sepaTransferMonth.value,
          }}
        />
      ),
      (pricingPlan) => (
        <FormattedMessage
          id="subscription.change_plan.business.checklist.cash_deposit"
          values={{
            amount: (
              <FormattedNumber
                currency="EUR"
                maximumFractionDigits={0}
                style="currency"
                value={
                  (pricingPlan.limits.cashDepositMonth.value *
                    pricingPlan.limits.cashDepositMaxAmountPerDeposit.value) /
                  100
                }
              />
            ),
          }}
        />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.business.checklist.accountant_access" />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.business.checklist.team_access" />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.business.checklist.more_insurances" />
      ),
      () => (
        <FormattedMessage id="subscription.change_plan.business.checklist.assistance" />
      ),
    ],
    summary: [
      {
        description: (pricingPlan) => (
          <FormattedMessage
            id="subscription.plan_details.summary.business.wallets"
            values={{
              count: pricingPlan.limits.activeBankAccounts.value,
            }}
          />
        ),
        icon: 'wallet',
      },
      {
        description: (pricingPlan) => (
          <FormattedMessage
            id="subscription.plan_details.summary.business.cards_and_members"
            values={{
              membersCount: pricingPlan.limits.companyUserAccess.value,
              physicalCardsCount: pricingPlan.limits.physicalPaymentCard.value,
            }}
          />
        ),
        icon: 'card-recto',
      },
      {
        description: (pricingPlan) => (
          <FormattedMessage
            id="subscription.plan_details.summary.business.sepa_transfer_month"
            values={{
              count: pricingPlan.limits.sepaTransferMonth.value,
              variableFee: (
                <FormattedNumber
                  currency="EUR"
                  maximumFractionDigits={2}
                  style="currency"
                  value={pricingPlan.fees.additionalSepaTransfer.fixed / 100}
                />
              ),
            }}
          />
        ),
        icon: 'bulk-transfer',
      },
      {
        description: (pricingPlan) => (
          <FormattedMessage
            id="subscription.plan_details.summary.business.advanced_features"
            values={{
              checksDepositCount: pricingPlan.limits.checkDepositMonth.value,
            }}
          />
        ),
        icon: 'insurance',
      },
    ],
  },

  /**
   * Legacy plans
   */
  [PlanId.MoralPersonBasic]: {
    card: PLANS_CARD_IMAGES[PlanId.MoralPersonBasic],
    lostFeaturesOnDowngrade: {},
    requiresInsuranceTermsAgreement: false,
    sellingPoints: [],
    summary: [],
  },
  [PlanId.MoralPersonBusiness]: {
    card: PLANS_CARD_IMAGES[PlanId.MoralPersonBusiness],
    lostFeaturesOnDowngrade: {},
    requiresInsuranceTermsAgreement: true,
    sellingPoints: [],
    summary: [],
  },
  [PlanId.MoralPersonPremium]: {
    card: PLANS_CARD_IMAGES[PlanId.MoralPersonPremium],
    lostFeaturesOnDowngrade: {},
    requiresInsuranceTermsAgreement: true,
    sellingPoints: [],
    summary: [],
  },
  [PlanId.PhysicalPersonBasic]: {
    card: PLANS_CARD_IMAGES[PlanId.PhysicalPersonBasic],
    lostFeaturesOnDowngrade: {},
    requiresInsuranceTermsAgreement: false,
    sellingPoints: [],
    summary: [],
  },
  [PlanId.PhysicalPersonPremium]: {
    card: PLANS_CARD_IMAGES[PlanId.PhysicalPersonPremium],
    lostFeaturesOnDowngrade: {},
    requiresInsuranceTermsAgreement: true,
    sellingPoints: [],
    summary: [],
  },
};
