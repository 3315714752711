import { type FC } from 'react';
import { Divider } from '@chakra-ui/react';
import { View, YStack } from '@shinetools/sunshine-universal';

import SkeletonButton from 'components/SkeletonButton';
import SkeletonText from 'components/SkeletonText';

import { SubscriptionManagementLayout } from '../../components/SubscriptionManagementLayout';
import InsuranceTermsFormSkeleton from './components/InsuranceTermsForm/InsuranceTermsFormSkeleton';
import { PlanFrequencySwitchSkeleton } from './components/PlanFrequencySwitch';
import { PlanRecapSkeleton } from './components/PlanRecap';
import { PlanSwitchEstimateDetailsSkeleton } from './components/PlanSwitchEstimateDetails';

interface PlanDetailsSkeletonProps {
  prevRoute: string;
}

const PlanDetailsSkeleton: FC<PlanDetailsSkeletonProps> = ({ prevRoute }) => (
  <SubscriptionManagementLayout
    asideContent={<PlanRecapSkeleton />}
    loading
    prevRoute={prevRoute}
  >
    <SkeletonText minWidth="120px" />
    <View marginVertical="$space.40">
      <YStack gap="$space.32">
        <PlanFrequencySwitchSkeleton />
        <Divider />
        <PlanSwitchEstimateDetailsSkeleton />
        <Divider />
        <InsuranceTermsFormSkeleton />
      </YStack>
    </View>
    <SkeletonButton />
  </SubscriptionManagementLayout>
);

export default PlanDetailsSkeleton;
