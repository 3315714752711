import { FormattedMessage, type MessageDescriptor } from 'react-intl';
import {
  Icon,
  Typography,
  XStack,
  YStack,
} from '@shinetools/sunshine-universal';

import { type PricingPlan } from '__generated__/GQL';

import { PlanIcon } from '../PlanIcon';
import { AlternativePlanFree } from './alternative-plans/free';
import { AlternativePlanStart } from './alternative-plans/start';

type Item = {
  label: MessageDescriptor['id'];
  details?: MessageDescriptor['id'];
};

type AlternativePlan = 'free' | 'start';

export type BulletPointsProps = {
  newPlan: Pick<PricingPlan, 'id' | 'brandName'>;
  items: Item[];
  alternativePlan?: AlternativePlan;
};

export const BulletPoints: React.FC<BulletPointsProps> = ({
  alternativePlan,
  items,
  newPlan,
}) => {
  const notes = items.reduce<
    {
      identifier: string;
      index: number;
      details: MessageDescriptor['id'];
    }[]
  >((acc, item, index) => {
    if (!item.details) {
      return acc;
    }

    return [
      ...acc,
      {
        details: item.details,
        identifier: '*'.repeat(acc.length + 1),
        index,
      },
    ];
  }, []);

  const alternativePlanComponent = (function () {
    if (alternativePlan === 'free') {
      return <AlternativePlanFree />;
    }

    if (alternativePlan === 'start') {
      return <AlternativePlanStart />;
    }

    return null;
  })();

  return (
    <>
      <YStack
        backgroundColor="$grey.100"
        borderColor="$grey.800"
        borderRadius={16}
        borderWidth={1}
        gap="$space.24"
        padding="$space.24"
      >
        <YStack gap="$space.4">
          <Typography.Text size="tiny" variant="secondary">
            <FormattedMessage id="pricing.migration.page.new_plan" />
          </Typography.Text>
          <XStack alignItems="center" gap="$space.16">
            <PlanIcon planId={newPlan.id} size={14} />
            <Typography.HeaderNumber>
              {newPlan.brandName}
            </Typography.HeaderNumber>
          </XStack>
        </YStack>
        <YStack gap="$space.12">
          {items.map((item, index) => {
            const isLast = index === items.length - 1;
            const note = notes.find((n) => n.index === index);

            return (
              <XStack alignItems="center" gap="$space.16" key={item.label}>
                <Icon icon="tick" />
                <Typography.Text variant="secondary">
                  <FormattedMessage id={item.label} />
                  {note?.identifier ?? ''}
                  {isLast ? '.' : `\u00a0;`}
                </Typography.Text>
              </XStack>
            );
          })}
        </YStack>
      </YStack>

      {notes.length ? (
        <YStack gap="$space.16" marginTop="$space.24">
          {notes.map((note) => (
            <Typography.Text size="tiny" variant="secondary">
              *<FormattedMessage id={note?.details} />
            </Typography.Text>
          ))}
        </YStack>
      ) : null}

      {alternativePlan ? (
        <>
          <Typography.Text
            bold
            marginBottom="$space.8"
            marginTop="$space.40"
            size="large"
          >
            <FormattedMessage id="pricing.migration.page.features_not_needed.title" />
          </Typography.Text>

          {alternativePlanComponent}
        </>
      ) : null}
    </>
  );
};
