import { type SubscriptionHookOptions, useSubscription } from '@apollo/client';

import {
  StrongAuthenticationChallengeResultDocument,
  type StrongAuthenticationChallengeResultSubscription,
  type StrongAuthenticationChallengeResultSubscriptionVariables,
} from './strongAuthenticationChallengeResultSubscription.gql';

const useStrongAuthenticationChallengeSubscription = (
  challengeId?: string,
  options: SubscriptionHookOptions<
    StrongAuthenticationChallengeResultSubscription,
    StrongAuthenticationChallengeResultSubscriptionVariables
  > = {},
) => {
  return useSubscription<
    StrongAuthenticationChallengeResultSubscription,
    StrongAuthenticationChallengeResultSubscriptionVariables
  >(StrongAuthenticationChallengeResultDocument, {
    ...options,
    variables: { challengeId: challengeId ?? '' },
  });
};

export default useStrongAuthenticationChallengeSubscription;
