import { type Duration, intervalToDuration, isAfter } from 'date-fns';

import { type SubscriptionPlan } from '__generated__/GQL';

type TrialPeriodLoading = {
  isOngoing: false;
  duration: null;
  isLoading: true;
};

type TrialPeriodOngoing = {
  isOngoing: true;
  isLoading: false;
  duration: Duration;
};

type TrialPeriodEnded = {
  isOngoing: false;
  isLoading: false;
  duration: null;
};

type GenericSubscriptionPlan = Pick<
  SubscriptionPlan,
  'freePeriodEndAt' | 'trialPeriodEndDate'
>;

/**
 * Determines the current trial period status for a given subscription plan.
 *
 * @param subscriptionPlan - The subscription plan containing trial period information.
 * @param isCompanyCreation - Whether the plan is associated with a company creation.
 *
 * @returns One of the following states:
 * - `TrialPeriodLoading`: If the plan is not available or still loading.
 * - `TrialPeriodOngoing`: If the trial is currently active.
 * - `TrialPeriodEnded`: If the trial has ended or does not exist.
 *
 * ### Business Logic:
 * - Previously, company creation plans included 6 or 12 months "free" via coupons, but these months were effectively prepaid.
 * - From 2025 onwards, company creation switched to a subscription model where free months are part of a trial, not a prepaid period.
 * - **Legacy company creation plans** should NOT display a trial period since their free months were prepaid.
 * - **New company creation plans** (post-migration) should display a trial period when applicable.
 * - Trials can be represented by either:
 *   - `freePeriodEndAt` (for plans using coupons, e.g., 2023 plans).
 *   - `trialPeriodEndDate` (for plans using ChargeBee trials, e.g., 2025 plans).
 */
export const getTrialPeriod = <T extends GenericSubscriptionPlan>(
  subscriptionPlan?: T | null | undefined,
  isCompanyCreation?: boolean,
): TrialPeriodOngoing | TrialPeriodEnded | TrialPeriodLoading => {
  const isLegacyCompanyCreation =
    isCompanyCreation &&
    subscriptionPlan?.freePeriodEndAt &&
    isAfter(new Date(subscriptionPlan.freePeriodEndAt), new Date());

  if (!subscriptionPlan || isLegacyCompanyCreation) {
    return {
      duration: null,
      isLoading: true,
      isOngoing: false,
    };
  }

  const endOfTrialPeriod =
    subscriptionPlan.trialPeriodEndDate || subscriptionPlan.freePeriodEndAt;

  if (!endOfTrialPeriod) {
    return {
      duration: null,
      isLoading: false,
      isOngoing: false,
    };
  }

  const endDate = new Date(endOfTrialPeriod);

  if (isAfter(new Date(), endDate)) {
    return {
      duration: null,
      isLoading: false,
      isOngoing: false,
    };
  }

  const duration = intervalToDuration({
    end: endDate,
    start: new Date(),
  });

  return {
    duration,
    isLoading: false,
    isOngoing: true,
  };
};
