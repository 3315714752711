import '@shinetools/sunshine-universal/reset.css';

import { type FC } from 'react';
import { IntlProvider as LibIntlProvider } from 'react-intl';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { TamaguiProvider } from '@shinetools/sunshine-universal';
import { ThemeProvider } from 'styled-components';
import tamaguiConfig from 'tamagui.config';

import config from 'config';
import { FeatureFlagsSwitcherProvider } from 'common/hooks/useFeatureFlagsSwitcher';
import ChakraProvider from 'components/ChakraProvider';
import ClientProvider from 'components/ClientProvider';
import ErrorBoundary from 'components/ErrorBoundary';
import ErrorView from 'components/ErrorView';
import ErrorViewBlank from 'components/ErrorViewBlank';
import ToastProvider from 'components/ToastProvider';
import Analytics from 'features/Analytics';
import IntercomProvider from 'features/Intercom';
import { DeviceContext, getDeviceInformation } from 'helpers/device';
import legacyTheme from 'theme/legacyTheme';

import './index.css';
import { intlConfig } from '../intl.config';
import * as routes from './routes';
import SmallResScreen from './SmallResScreen';
import { AlertsContainer, AppContainer, ModalsContainer } from './styles';

const Main: FC = () => (
  <BrowserRouter>
    <Sentry.ErrorBoundary fallback={ErrorViewBlank}>
      <ClientProvider>
        <TamaguiProvider config={tamaguiConfig}>
          <LibIntlProvider {...intlConfig}>
            <Analytics
              consentKey={config.didomiKey}
              writeKey={config.segmentKey}
            >
              <ChakraProvider>
                <FeatureFlagsSwitcherProvider>
                  {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                  <ThemeProvider theme={legacyTheme as any}>
                    <ToastProvider>
                      <ErrorBoundary errorView={ErrorView}>
                        <SmallResScreen>
                          <IntercomProvider>
                            <AppContainer>
                              <DeviceContext.Provider
                                value={getDeviceInformation()}
                              >
                                <Switch>
                                  <Route
                                    component={routes.Signup}
                                    path="/register"
                                  />
                                  <Route
                                    component={
                                      routes.EmailFallbackTrustedDeviceManagement
                                    }
                                    path="/d"
                                  />
                                  <Route
                                    component={
                                      routes.AsyncEmailChallengeRequestPublic
                                    }
                                    path="/c"
                                  />
                                  <Route
                                    component={routes.HandleInvitation}
                                    path="/i/:slug"
                                  />
                                  <Route component={routes.SecuredRoutes} />
                                </Switch>
                              </DeviceContext.Provider>
                            </AppContainer>
                          </IntercomProvider>
                        </SmallResScreen>
                      </ErrorBoundary>
                      {/* TODO(alex): change these IDs to prefixed ones less prone to conflicts */}
                      <ModalsContainer id="modal" />
                      <AlertsContainer id="alert" />
                    </ToastProvider>
                  </ThemeProvider>
                </FeatureFlagsSwitcherProvider>
              </ChakraProvider>
            </Analytics>
          </LibIntlProvider>
        </TamaguiProvider>
      </ClientProvider>
    </Sentry.ErrorBoundary>
  </BrowserRouter>
);

export default Main;
