import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';

import {
  type ChallengeStateName,
  type VerifyChallengeOutput,
} from '__generated__/GQL';

import { VerifyChallengeDocument } from '../graphql/mutations/verifyChallenge.gql';

export interface VerifyChallengeParams {
  challengeId: string;
  name: ChallengeStateName;
}

export interface VerifyChallengeSMSParams extends VerifyChallengeParams {
  verificationCode: string;
}

export const useVerifyChallenge = () => {
  const [isVerifying, setIsVerifying] = useState(false);

  const [verifyChallenge] = useMutation(VerifyChallengeDocument);

  const approveWithCode = useCallback(
    async (
      payload: VerifyChallengeSMSParams,
    ): Promise<VerifyChallengeOutput | null> => {
      if (!isVerifying) {
        setIsVerifying(true);
        const { challengeId, name, verificationCode } = payload;
        const result = await verifyChallenge({
          variables: {
            input: { challengeId, name, verificationCode },
          },
        });
        return result.data?.verifyChallenge || null;
      }
      return null;
    },
    [isVerifying, verifyChallenge, setIsVerifying],
  );

  const decline = useCallback(
    async (
      payload: VerifyChallengeParams,
    ): Promise<VerifyChallengeOutput | null> => {
      if (!isVerifying) {
        setIsVerifying(true);
        const { challengeId, name } = payload;

        const result = await verifyChallenge({
          variables: {
            input: { challengeId, name },
          },
        });
        return result.data?.verifyChallenge || null;
      }
      return null;
    },
    [verifyChallenge, setIsVerifying, isVerifying],
  );

  return {
    approveWithCode,
    decline,
    isVerifying,
    setIsVerifying,
  };
};
