import { type FC } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { Box, type BoxProps, Flex } from '@chakra-ui/react';
import { Callout, Typography } from '@shinetools/sunshine-universal';

import Button from 'components/_core/Button';
import useToast from 'components/ToastProvider/useToast';

import { PricingPlanMigrationDocument } from '../../../../../PricingPlanMigration/graphql/pricingPlanMigration.gql';
import * as GQLOverview from '../../graphql/overview.gql';
import { getScheduledDowngrade } from '../../helpers/getScheduleDowngrade';
import * as GQL from './scheduledDowngrade.gql';

export type OverviewScheduledDowngradeProps = BoxProps & {
  companyProfileId: string;
  data?: GQLOverview.SubscriptionManagementOverviewQuery;
};

export const OverviewScheduledDowngrade: FC<OverviewScheduledDowngradeProps> = (
  props,
) => {
  const { companyProfileId, data, ...rest } = props;

  const intl = useIntl();
  const { showToast } = useToast();
  const [cancelDowngrade, mutation] = useMutation(
    GQL.CancelScheduledDowngradeDocument,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GQLOverview.SubscriptionManagementOverviewDocument,
          variables: {
            companyProfileId,
            includePlanChangeData: true,
          },
        },
        {
          query: PricingPlanMigrationDocument,
          variables: {
            companyProfileId,
          },
        },
      ],
    },
  );

  const downgrade = getScheduledDowngrade(data);

  if (!downgrade.hasScheduledDowngrade) {
    return null;
  }

  const onCancel = async () => {
    await cancelDowngrade({
      onCompleted: () => {
        showToast({
          message: intl.formatMessage({
            id: 'subscription.overview.scheduled_downgrade.cancel_success',
          }),
          type: 'success',
        });
      },
      onError: () => {
        showToast({
          message: intl.formatMessage({
            id: 'subscription.overview.scheduled_downgrade.cancel_error',
          }),
          type: 'error',
        });
      },
      variables: {
        input: {
          companyProfileId,
        },
      },
    });
  };

  return (
    <Box {...rest}>
      <Callout withIcon={false}>
        <Flex alignItems="flex-start" direction="column" gap="space-8">
          <Typography.Text size="small" variant="secondary">
            <FormattedMessage
              id="subscription.overview.scheduled_downgrade.description"
              values={{
                currentPlan: downgrade.currentPlanName,
                nextPlan: downgrade.nextPlanName,
                nextStart: (
                  <FormattedDate
                    day="numeric"
                    month="short"
                    value={downgrade.nextStartingAt}
                    year="numeric"
                  />
                ),
              }}
            />
          </Typography.Text>

          <Button
            isLoading={mutation.loading}
            onClick={onCancel}
            variant="inline-secondary"
          >
            <FormattedMessage id="subscription.overview.scheduled_downgrade.action" />
          </Button>
        </Flex>
      </Callout>
    </Box>
  );
};
