import { FormattedMessage, useIntl } from 'react-intl';
import { Typography, XStack } from '@shinetools/sunshine-universal';

import { PlanIcon } from '../../PlanIcon';
import { Table } from '../../Table';

import { type MigrationContentComponent } from '..';

export const PlusToPlusTable: MigrationContentComponent = ({ newPlan }) => {
  const { formatMessage } = useIntl();

  return (
    <Table
      highlightColumn={2}
      rows={[
        [
          null,
          <Table.Header
            title={formatMessage({
              id: 'pricing.migration.page.current',
            })}
          >
            <Typography.Text bold>Shine Plus</Typography.Text>
          </Table.Header>,
          <Table.Header
            title={formatMessage({
              id: 'pricing.migration.page.new',
            })}
          >
            <XStack alignItems="center" gap="$space.8">
              <PlanIcon planId={newPlan.id} />
              <Typography.Text bold>{newPlan.brandName}</Typography.Text>
            </XStack>
          </Table.Header>,
        ],
        [
          <Table.Header
            title={formatMessage({
              id: 'pricing.migration.page.tables.wallets',
            })}
          />,
          <Table.FeatureInfo variant="discreet">1</Table.FeatureInfo>,
          <Table.FeatureInfo>4</Table.FeatureInfo>,
        ],
        [
          <Table.Header
            title={formatMessage({
              id: 'pricing.migration.page.tables.business_card_team_access',
            })}
          />,
          <Table.FeatureInfo isAvailable={false} />,
          <Table.FeatureInfo>1</Table.FeatureInfo>,
        ],
        [
          <Table.Header
            title={formatMessage({
              id: 'pricing.migration.page.tables.virtual_cards',
            })}
          />,
          <Table.FeatureInfo variant="discreet">20</Table.FeatureInfo>,
          <Table.FeatureInfo>
            <FormattedMessage id="pricing.migration.page.tables.virtual_cards.unlimited" />
          </Table.FeatureInfo>,
        ],
        [
          <Table.Header
            title={formatMessage({
              id: 'pricing.migration.page.tables.transfers',
            })}
          />,
          <Table.FeatureInfo variant="discreet">60</Table.FeatureInfo>,
          <Table.FeatureInfo>100</Table.FeatureInfo>,
        ],
        [
          <Table.Header
            title={formatMessage({
              id: 'pricing.migration.page.tables.check_deposits',
            })}
          />,
          <Table.FeatureInfo variant="discreet">2</Table.FeatureInfo>,
          <Table.FeatureInfo>6</Table.FeatureInfo>,
        ],
        [
          <Table.Header
            title={formatMessage({
              id: 'pricing.migration.page.tables.cash_withdrawals',
            })}
          />,
          <Table.FeatureInfo variant="discreet">2</Table.FeatureInfo>,
          <Table.FeatureInfo>4</Table.FeatureInfo>,
        ],
        [
          <Table.Header
            title={formatMessage({
              id: 'pricing.migration.page.tables.phone_support',
            })}
          />,
          <Table.FeatureInfo isAvailable={false} />,
          <Table.FeatureInfo isAvailable={true} />,
        ],
      ]}
    />
  );
};
