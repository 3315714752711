import { publicApolloClient } from 'common/graphql/clients';

import { VerifyEmailChallengePublicDocument } from '../graphql/verifyEmailChallengePublic.gql';

export const verifyEmailChallenge = async (token: string) => {
  const { data } = await publicApolloClient.mutate({
    mutation: VerifyEmailChallengePublicDocument,
    variables: {
      input: {
        token,
      },
    },
  });

  return data;
};
