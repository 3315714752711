import { type FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Image, useDisclosure } from '@chakra-ui/react';
import { Button, Typography, YStack } from '@shinetools/sunshine-universal';

import {
  type Device,
  DeviceRole,
  type StrongAuthenticationRequired,
} from '__generated__/GQL';
import shieldImg from 'assets/brand/shield@2x.png';
import SunshineCard from 'components/_core/SunshineCard';
import StrongAuthenticationChallengeModal from 'components/StrongAuthenticationChallengeModal';
import { useDevice } from 'helpers/device';

import ConnectionAccepted from '../ConnectionAccepted';
import { enrollTrustedDeviceWithEmail } from './hooks/enrollTrustedDeviceWithEmail';

interface EnrollTrustedDeviceWithEmailProps {
  deviceId: string;
  cancel: () => void;
  passcode: string;
  submitPasscodeLoading: boolean;
  submitPasscodeErrorMessage: string;
  submitPasscode: (passcode: string) => Promise<boolean>;
}

const EnrollTrustedDeviceWithEmail: FC<EnrollTrustedDeviceWithEmailProps> = ({
  cancel,
  deviceId,
  passcode,
  submitPasscode,
  submitPasscodeErrorMessage,
  submitPasscodeLoading,
}) => {
  const [device, setDevice] = useState<Device | undefined>(undefined);
  const { token: uniqueId } = useDevice();

  const {
    isOpen: isChallengeVerificationModalOpen,
    onClose: onCloseChallengeVerificationModal,
    onToggle: onToggleChallengeVerificationModal,
  } = useDisclosure({
    defaultIsOpen: false,
  });

  const onClose = () => {
    onCloseChallengeVerificationModal();
  };

  const trustUnknownDeviceWithEmail = async (
    secureSessionToken: string,
  ): Promise<Device | StrongAuthenticationRequired> => {
    const result = await enrollTrustedDeviceWithEmail(
      deviceId,
      uniqueId,
      secureSessionToken,
    );
    if (result.__typename === 'StrongAuthenticationRequired') {
      return result;
    }

    setDevice(result);
    return result;
  };

  if (device && device.role === DeviceRole.Trusted) {
    return (
      <ConnectionAccepted
        passcode={passcode}
        submitPasscode={submitPasscode}
        submitPasscodeErrorMessage={submitPasscodeErrorMessage}
        submitPasscodeLoading={submitPasscodeLoading}
      />
    );
  }
  return (
    <>
      <StrongAuthenticationChallengeModal
        executeStrongAuthenticationOperation={trustUnknownDeviceWithEmail}
        isFormValid={Boolean(uniqueId)}
        isOpen={isChallengeVerificationModalOpen}
        onClose={onClose}
      />
      <Box height="100vh" marginX="auto" paddingTop="space-32" width="600px">
        <Button
          icon="cross"
          iconPosition="left"
          marginBottom="$space.16"
          onPress={() => {
            cancel();
          }}
          variant="discreet"
        >
          <FormattedMessage id="authentication.cancel" />
        </Button>
        <SunshineCard>
          <YStack
            alignItems="center"
            flex={1}
            gap="$space.24"
            marginHorizontal="$space.40"
          >
            <Image marginTop="space-8" src={shieldImg} width="140px" />
            <Typography.Text bold size="large">
              <FormattedMessage id="authentication.enroll_trusted_device.title" />
            </Typography.Text>
            <Typography.Text>
              <FormattedMessage id="authentication.enroll_trusted_device.fall_back_description" />
            </Typography.Text>
            <Button
              onPress={() => {
                onToggleChallengeVerificationModal();
              }}
            >
              <FormattedMessage id="authentication.continue" />
            </Button>
          </YStack>
        </SunshineCard>
      </Box>
    </>
  );
};

export default EnrollTrustedDeviceWithEmail;
