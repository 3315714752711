import { type FC, type ReactNode, useEffect, useMemo, useState } from 'react';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { Image } from '@chakra-ui/react';
import { ERROR_CODES } from '@shinetools/errors';
import { Typography, View } from '@shinetools/sunshine-universal';
import { validate as uuidValidate } from 'uuid';

import fadedFlowerImg from 'assets/brand/faded-flower@2x.png';
import shineLogo from 'assets/shine-logo.svg';
import { hasErrorCode } from 'common/hasErrorCode';
import useCompanyContext from 'common/hooks/useCompanyContext';
import SunshineCard from 'components/_core/SunshineCard';
import { ErrorPage } from 'components/ErrorPage';
import { ErrorPageType } from 'components/ErrorPage/types';
import HelpAndTermsLinks from 'components/HelpAndTermsLinks';
import Loader from 'components/Loader';
import Tooltip from 'components/Tooltip';
import { useUser } from 'helpers/auth';
import logger from 'helpers/logger';
import CenterCardLayout from 'layouts/CenterCardLayout';

import AccountSwitcher from './AccountSwitcher';
import { InvalidScopeError, InvalidUuidError } from './errors';
import { GetAuthenticationClientDocument } from './graphql/getAuthenticationClient.gql';
import { GrantAuthenticationClientDocument } from './graphql/grantAuthenticationClient.gql';
import locales from './locales';
import { cleanRedirectUri } from './sanitizeUri';
import {
  ActionButton,
  Actions,
  ClientLogo,
  ClientLogoContainer,
  ClientLogoContainerResponsive,
  Container,
  CustomText,
  Form,
  Item,
  List,
  Logo,
  Spacer,
  Title,
} from './styles';
import { type AccessItem, type OAuthClient, type Queries } from './types';
import { getAccessItems, hasInvalidParams, parseQueryString } from './utils';

const Authorize: FC = () => {
  const user = useUser();
  const queries: Queries = useMemo((): Queries => parseQueryString(), []);
  const [loading, setLoading] = useState(true);
  const [authorizing, setAuthorizing] = useState(false);
  const [error, setError] = useState<unknown | null>(null);
  const [client, setClient] = useState<OAuthClient | null>(null);
  const { companyProfileId } = useCompanyContext();
  // Parameters below comes from client and should be considered unsafe
  const {
    client_id: clientId,
    redirect_uri: unsafeRedirectUri,
    scope,
    state,
  } = queries;
  const redirectUri = cleanRedirectUri(unsafeRedirectUri);

  const [clientQuery] = useLazyQuery(GetAuthenticationClientDocument);
  const [grantMutation] = useMutation(GrantAuthenticationClientDocument);

  const getErrorLocale = (err: unknown) => {
    const isForbiddenError =
      err &&
      err instanceof ApolloError &&
      hasErrorCode(err.graphQLErrors, ERROR_CODES.FORBIDDEN);
    if (isForbiddenError) {
      return locales.errorDescriptionPermission;
    }

    if (err instanceof InvalidScopeError) {
      return locales.errorDescriptionScope;
    }
    return locales.errorDescription;
  };

  const accessItems: AccessItem[] = useMemo((): ReturnType<
    typeof getAccessItems
  > => {
    try {
      return getAccessItems(queries.scope);
    } catch (err) {
      setError(new InvalidScopeError(undefined, err));
      return [];
    }
  }, [queries.scope]);

  useEffect((): void => {
    const fetch = async (): Promise<void> => {
      try {
        if (!uuidValidate(clientId)) {
          throw new InvalidUuidError();
        }
        const { data } = await clientQuery({ variables: { clientId } });
        if (data) {
          setClient({
            displayName: data.getAuthenticationClient.displayName,
            logoURL: data.getAuthenticationClient.logoURL,
          });
        }
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, [clientId, clientQuery]);

  if (loading) {
    return <Loader />;
  }

  if (
    !user ||
    !companyProfileId ||
    !client ||
    hasInvalidParams(queries) ||
    error
  ) {
    return (
      <ErrorPage type={ErrorPageType.Generic}>
        <SunshineCard.Group maxWidth="31.25rem">
          <SunshineCard alignItems="center" textAlign="center">
            <Image
              alt={locales.errorTitle}
              marginBottom="space-16"
              marginX="auto"
              src={fadedFlowerImg}
              width="180px"
            />

            <Typography.Header marginBottom="$space.16" size="large">
              {locales.errorTitle}
            </Typography.Header>

            <View paddingVertical="$space.24">
              <Typography.Text>{getErrorLocale(error)}</Typography.Text>
            </View>
          </SunshineCard>
        </SunshineCard.Group>
      </ErrorPage>
    );
  }

  const cancelAuthorize = (): void => {
    window.location.href = `${redirectUri}?error=access_denied&state=${state}`;
  };

  const authorize = async (): Promise<void> => {
    setAuthorizing(true);
    try {
      const { data } = await grantMutation({
        variables: {
          input: {
            client_id: clientId,
            companyProfileId,
            redirect_uri: redirectUri,
            scope,
            state,
          },
        },
      });

      if (!data) {
        throw new Error('Could not grant authorization.');
      }

      const {
        grantAuthenticationClient: { redirectTo },
      } = data;

      window.location.href = redirectTo;
    } catch (e) {
      if (
        e instanceof ApolloError &&
        hasErrorCode(e.graphQLErrors, ERROR_CODES.FORBIDDEN)
      ) {
        logger.info('[oAuth] Forbidden error while granting authorization', {
          context: {
            error: e,
          },
        });
      } else {
        logger.error('[oAuth] Error while granting authorization', {
          context: {
            error: e,
          },
        });
      }

      setError(e);
    } finally {
      setAuthorizing(false);
    }
  };

  return (
    <CenterCardLayout footer={<HelpAndTermsLinks center />}>
      <Container>
        <ClientLogoContainer>
          <ClientLogo alt="client logo" src={client.logoURL} />
        </ClientLogoContainer>
        <Form>
          <a href="https://shine.fr" key="logo" target="__blank">
            <Logo alt="Shine logo" src={shineLogo} />
          </a>
          <AccountSwitcher />
          <Title>
            {locales.formatString(locales.title, client.displayName)}
            <ClientLogoContainerResponsive>
              <ClientLogo alt="client logo" src={client.logoURL} />
            </ClientLogoContainerResponsive>
          </Title>
          <List>
            {accessItems.map(
              (accessItem): ReactNode => (
                <Item key={accessItem.id}>
                  <CustomText>{accessItem.label}</CustomText>
                  {accessItem.tooltip ? (
                    <Tooltip text={accessItem.tooltip} />
                  ) : null}
                </Item>
              ),
            )}
          </List>
        </Form>
        <Actions>
          <ActionButton onPress={cancelAuthorize} variant="secondary">
            {locales.cancel}
          </ActionButton>
          <Spacer />
          <ActionButton isLoading={authorizing} onPress={authorize}>
            {locales.authorize}
          </ActionButton>
        </Actions>
      </Container>
    </CenterCardLayout>
  );
};

export default Authorize;
