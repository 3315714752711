import type React from 'react';
import { type PropsWithChildren } from 'react';
import { Typography, YStack } from '@shinetools/sunshine-universal';

export type HeaderProps = {
  title: string;
  highlight?: boolean;
};

export const Header: React.FC<PropsWithChildren<HeaderProps>> = ({
  children,
  title,
}) => {
  return (
    <YStack
      alignItems="stretch"
      flexGrow={1}
      gap="$space.2"
      justifyContent={children ? 'flex-start' : 'center'}
    >
      {title ? (
        <Typography.Text size="tiny" variant="secondary">
          {title}
        </Typography.Text>
      ) : null}

      {children}
    </YStack>
  );
};
