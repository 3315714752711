import type React from 'react';
import { type ReactElement } from 'react';
import {
  View,
  type ViewProps,
  XStack,
  YStack,
} from '@shinetools/sunshine-universal';

import { FeatureInfo } from './components/FeatureInfo';
import { Header } from './components/Header';

type Cell = React.ReactNode;

type Row = Cell[];

export type TableProps = {
  rows: Row[];
  highlightColumn?: number;
  backgroundColor?: ViewProps['backgroundColor'];
  borderColor?: ViewProps['borderColor'];
  borderRadius?: ViewProps['borderRadius'];
  highlightBackgroundColor?: ViewProps['backgroundColor'];
  highlightBorderColor?: ViewProps['backgroundColor'];
  highlightBorderWidth?: ViewProps['borderWidth'];
};

export const Table = ({
  backgroundColor = 'white',
  borderColor = '$grey.300',
  borderRadius = 8,
  highlightBackgroundColor = '$grey.100',
  highlightBorderColor = '$grey.800',
  highlightBorderWidth = 1,
  highlightColumn,
  rows,
}: TableProps): ReactElement => {
  const columnsCount = Math.max(...rows.map((row) => row.length));
  const columnWidth = `${100.0 / columnsCount}%` as const;

  return (
    <YStack
      alignItems="stretch"
      backgroundColor={backgroundColor}
      position="relative"
    >
      {rows.map((row, rowIndex) => {
        // Cells can be empty on the sides
        const firstColumnIndex = row.findIndex(Boolean);
        const lastColumnIndex = row.findLastIndex(Boolean);

        return (
          <XStack
            alignItems="stretch"
            justifyContent="space-between"
            key={rowIndex}
          >
            {row.map((cell, columnIndex) => {
              const highlight = columnIndex === highlightColumn;
              const previousHighlight =
                row[columnIndex - 1] && columnIndex - 1 === highlightColumn;

              const isFirstColumn = columnIndex === firstColumnIndex;
              const isLastColumn = columnIndex === lastColumnIndex;

              // Cells can be empty in first/last rows
              const isFirstRow =
                rowIndex === rows.findIndex((r) => Boolean(r[columnIndex]));
              const isLastRow =
                rowIndex === rows.findLastIndex((r) => Boolean(r[columnIndex]));

              const cellProps: ViewProps = {
                alignItems: 'stretch',
                borderColor,
                display: 'flex',
                flexGrow: 1,
                flexShrink: 1,
                justifyContent: 'center',
                maxWidth: columnWidth,
                padding: '$space.12',
              };

              if (cell) {
                // Borders
                cellProps.borderLeftWidth = 1;
                cellProps.borderTopWidth = 1;

                if (isLastColumn) {
                  cellProps.borderRightWidth = 1;
                }

                if (isLastRow) {
                  cellProps.borderBottomWidth = 1;
                }

                // Corners radius
                if (isFirstColumn && isFirstRow) {
                  cellProps.borderTopLeftRadius = borderRadius;
                } else if (isLastColumn && isFirstRow) {
                  cellProps.borderTopRightRadius = borderRadius;
                } else if (isLastColumn && isLastRow) {
                  cellProps.borderBottomRightRadius = borderRadius;
                } else if (isFirstColumn && isLastRow) {
                  cellProps.borderBottomLeftRadius = borderRadius;
                }

                // Highlight column
                if (highlight) {
                  cellProps.backgroundColor = highlightBackgroundColor;

                  cellProps.borderLeftColor = highlightBorderColor;
                  cellProps.borderLeftWidth = highlightBorderWidth;

                  cellProps.borderRightColor = highlightBorderColor;
                  cellProps.borderRightWidth = highlightBorderWidth;

                  if (isFirstRow) {
                    cellProps.borderTopColor = highlightBorderColor;
                    cellProps.borderTopWidth = highlightBorderWidth;
                  }

                  if (isLastRow) {
                    cellProps.borderBottomColor = highlightBorderColor;
                    cellProps.borderBottomWidth = highlightBorderWidth;
                  }
                } else if (previousHighlight) {
                  cellProps.borderLeftColor = highlightBorderColor;
                  cellProps.borderLeftWidth = highlightBorderWidth;
                }
              }

              return (
                <View key={columnIndex} {...cellProps}>
                  {cell}
                </View>
              );
            })}
          </XStack>
        );
      })}
    </YStack>
  );
};

Table.FeatureInfo = FeatureInfo;
Table.Header = Header;
