import { BulletPoints } from '../../BulletPoints';

import { type MigrationContentComponent } from '..';

export const PlusToPlusBulletPoints: MigrationContentComponent = ({
  isInPrepaidPeriod,
  newPlan,
}) => {
  return (
    <BulletPoints
      alternativePlan={isInPrepaidPeriod ? undefined : 'start'}
      items={[
        {
          label: 'pricing.migration.page.plans.plus.bullets.more_wallets',
        },
        {
          label:
            'pricing.migration.page.plans.plus.bullets.employee_business_card',
        },
        {
          label: 'pricing.migration.page.plans.plus.bullets.expenses_claims',
        },
        {
          label: 'pricing.migration.page.plans.plus.bullets.virtual_cards',
        },
        {
          label: 'pricing.migration.page.plans.plus.bullets.tranfers',
        },
        {
          label: 'pricing.migration.page.plans.plus.bullets.check_deposits',
        },
        {
          label: 'pricing.migration.page.plans.plus.bullets.cash_withdrawals',
        },
        {
          label: 'pricing.migration.page.plans.plus.bullets.phone_support',
        },
      ]}
      newPlan={newPlan}
    />
  );
};
