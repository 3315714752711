import { type FC, useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Image } from '@chakra-ui/react';
import { Button, Typography, View } from '@shinetools/sunshine-universal';

import fadedFlowerImg from 'assets/brand/faded-flower@2x.png';
import SunshineModal from 'components/_core/SunshineModal';
import { useUser } from 'helpers/auth';
import logger from 'helpers/logger';

import { ChallengeStateName } from '../../../../__generated__/GQL';
import RichText from '../../../_core/RichText';
import PasscodeInput from '../../../PasscodeInput';
import { getErrorLocale } from '../../../SMSVerificationModal/errors';
import { VerifyChallengeDocument } from '../../graphql/mutations/verifyChallenge.gql';
import { useVerifySmsSCAOperation } from '../../hooks/useVerifySmsSCAOperation';
import { type StrongAuthenticationChallengeChildModalProps } from '../../utils/types';
import locales from './locales';

const DEFAULT_TIMER = 180;

const SmsChallenge: FC<StrongAuthenticationChallengeChildModalProps> = ({
  challengeId,
  executeStrongAuthenticationOperation,
  isOpen,
  onClose,
  payload,
}) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [timer, setTimer] = useState(DEFAULT_TIMER);
  const [isExpired, setIsExpired] = useState(false);

  const user = useUser();
  const { phone } = user;

  const { isRequesting, isSmsVerifying, verifySmsOperation } =
    useVerifySmsSCAOperation({
      executeStrongAuthenticationOperation,
      payload,
    });

  // Hook executed when the user clicks on the confirm button
  const onSmsCodeSubmit = useCallback(
    async (code: string) => {
      try {
        if (!isSmsVerifying && !isRequesting) {
          await verifySmsOperation({
            challengeId,
            name: ChallengeStateName.ChallengeApproved,
            verificationCode: code,
          });
          onClose(false);
        }
      } catch (err) {
        setError(locales.unknownError);
      }
    },
    [isSmsVerifying, isRequesting, verifySmsOperation, challengeId, onClose],
  );

  const [verifyChallenge] = useMutation(VerifyChallengeDocument);

  const declineChallengeAndClose = async () => {
    const name = ChallengeStateName.ChallengeDeclined;
    try {
      await verifyChallenge({
        variables: {
          input: { challengeId, name, verificationCode: '' },
        },
      });
    } catch (err) {
      logger.log(err);
    } finally {
      onClose(true);
    }
  };

  useEffect(() => {
    if (isOpen) {
      const interval = setInterval(
        () =>
          setTimer((previousTimer) => {
            if (previousTimer === 0) {
              setIsExpired(true);
              return previousTimer;
            }

            return previousTimer - 1;
          }),
        1000,
      );

      return () => {
        clearInterval(interval);
      };
    }

    return () => {};
  }, [isOpen, setIsExpired]);

  const sendToInfo = phone
    ? locales.formatString(locales.sentToSingular, { phone })
    : '';

  const timerText =
    timer > 1
      ? locales.formatString(locales.expiresInPlural, {
          timer,
        })
      : locales.formatString(locales.expiresInSingular, {
          timer,
        });

  return (
    <SunshineModal isOpen={isOpen} onClose={declineChallengeAndClose}>
      <SunshineModal.Header />

      <SunshineModal.Body display="block">
        {isExpired ? (
          <>
            <Image
              alt={locales.codeExpired}
              marginX="auto"
              src={fadedFlowerImg}
              width="180px"
            />
            <Typography.Text marginTop="$space.8">
              {locales.codeExpired}
            </Typography.Text>
          </>
        ) : (
          <>
            <SunshineModal.Title>{locales.title}</SunshineModal.Title>
            <Typography.Text marginTop="$space.8">
              {locales.description}
            </Typography.Text>

            <View backgroundColor="transparent" marginVertical="$space.32">
              <PasscodeInput length={6} onSubmit={onSmsCodeSubmit} />
              {error === undefined ? null : (
                <Typography.Text marginTop="$space.16" variant="error">
                  {getErrorLocale(error)}
                </Typography.Text>
              )}
            </View>
            <View alignItems="center">
              {sendToInfo != '' ? (
                <RichText size="small">{sendToInfo}</RichText>
              ) : null}
              <Typography.Text size="small">{timerText}</Typography.Text>
            </View>
          </>
        )}
      </SunshineModal.Body>
      {!isExpired ? (
        <SunshineModal.Footer>
          <Button
            isLoading={isSmsVerifying || isRequesting}
            // There is an auto submit but we want the button to appear for consistency
            onPress={() => {}}
            variant="primary"
          >
            {locales.confirm}
          </Button>
        </SunshineModal.Footer>
      ) : null}
    </SunshineModal>
  );
};

export default SmsChallenge;
