import { publicApolloClient } from 'common/graphql/clients';
import logger from 'helpers/logger';

import { CanUseEnrollTrustedDevicePublicDocument } from './graphql/canUseEnrollTrustedDevicePublic.gql';

export const publicCanUseEnrollTrustedDevice = async (
  uid: string,
): Promise<boolean> => {
  try {
    const result = await publicApolloClient.query({
      query: CanUseEnrollTrustedDevicePublicDocument,
      variables: { uid },
    });
    return result.data.canUseEnrollTrustedDevicePublic;
  } catch (error) {
    logger.error(error, {
      tags: {
        userId: uid,
      },
    });
    return false;
  }
};
