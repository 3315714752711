import { PlanSwitchIneligibleReason } from '__generated__/GQL';

const reasonMap: Record<PlanSwitchIneligibleReason, string> = {
  [PlanSwitchIneligibleReason.InsufficientFunds]: 'hasInsufficientFunds',
  [PlanSwitchIneligibleReason.MoreThan_1MonthRemainingInContractTerm]:
    'hasMoreThanOneMonthLeftInContractTerm',
  [PlanSwitchIneligibleReason.HasOverdue]: 'hasOverdueInvoices',
  [PlanSwitchIneligibleReason.HasPrePaidPeriodInProgress]:
    'hasPrePaidPeriodInProgress',
  [PlanSwitchIneligibleReason.TooManyWallets]: 'hasTooManyMultiWallets',
};

export type FormattedPlanSwitchIneligibilityReasons = Record<
  (typeof reasonMap)[PlanSwitchIneligibleReason],
  boolean
>;

export const formatPlanSwitchIneligibilityReasons = (
  planSwitchIneligibilityReasons: Array<PlanSwitchIneligibleReason>,
): FormattedPlanSwitchIneligibilityReasons => {
  return planSwitchIneligibilityReasons.reduce<FormattedPlanSwitchIneligibilityReasons>(
    (acc, reason) => {
      const key = reasonMap[reason];

      if (key) {
        acc[key] = true;
      }

      return acc;
    },
    {
      hasInsufficientFunds: false,
      hasMoreThanOneMonthLeftInContractTerm: false,
      hasOverdueInvoices: false,
      hasPrePaidPeriodInProgress: false,
      hasTooManyMultiWallets: false,
    },
  );
};
