import { type FC, type ReactNode, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Divider, Flex } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Callout,
  Icon,
  Typography,
  YStack,
} from '@shinetools/sunshine-universal';
import { z } from 'zod';

import {
  PlanId,
  type PricingPlan,
  SubscriptionPlanFrequency,
} from '__generated__/GQL';
import useCompanyContext from 'common/hooks/useCompanyContext';
import useFeatureFlagsSwitcher from 'common/hooks/useFeatureFlagsSwitcher';
import usePageLogger from 'common/hooks/usePageLogger';
import Button from 'components/_core/Button';
import { formatDuration } from 'helpers/date';

import useToast from '../../../../components/ToastProvider/useToast';
import { INTERCOM_LAUNCHER_PROPS } from '../../../Intercom';
import { PricingPlanMigrationDocument } from '../../../PricingPlanMigration/graphql/pricingPlanMigration.gql';
import { SubscriptionManagementLayout } from '../../components/SubscriptionManagementLayout';
import { getTrialPeriod } from '../../lib/getTrialPeriod';
import * as routes from '../../routes';
import { SubscriptionManagementOverviewDocument } from '../Overview/graphql/overview.gql';
import { PLANS_METADATA } from '../Plans/libs/plansMetadata';
import InsuranceTermsForm from './components/InsuranceTermsForm';
import PlanFrequencySwitch from './components/PlanFrequencySwitch';
import PlanRecap from './components/PlanRecap';
import PlanSwitchEstimateDetails from './components/PlanSwitchEstimateDetails';
import { ChangePlanDocument } from './graphql/changePlan.gql';
import { SubscriptionManagementPlansDetailDocument } from './graphql/planDetails.gql';
import { PlanSwitchEstimateDocument } from './graphql/planSwitchEstimate.gql';
import { formatPlanSwitchIneligibilityReasons } from './helpers/formatPlanSwitchIneligibilityReasons';
import PlanDetailsSkeleton from './PlanDetailsSkeleton';

export const PlanDetails: FC = () => {
  const companyContext = useCompanyContext();
  const params = useParams<{ planId: PlanId }>();
  const [selectedPricingPlanId, setSelectedPricingPlanId] = useState<PlanId>(
    params.planId,
  );
  const history = useHistory();
  const intl = useIntl();
  const location = useLocation<{ fromOverviewPage?: boolean }>();
  const { showToast } = useToast();
  const [
    planSwitchEstimateInitialQueryCompleted,
    setPlanSwitchEstimateInitialQueryCompleted,
  ] = useState(false);

  const { featureFlags } = useFeatureFlagsSwitcher();

  const requiresInsuranceTermsAgreement =
    !!PLANS_METADATA[params.planId]?.requiresInsuranceTermsAgreement;

  const form = useForm({
    defaultValues: {
      hasAgreedToInsuranceTerms: false,
    },
    resolver: zodResolver(
      z.object({
        hasAgreedToInsuranceTerms: requiresInsuranceTermsAgreement
          ? z.literal(true, {
              errorMap: () => ({
                message: intl.formatMessage({
                  id: 'subscription.plan_details.insurance_terms.error',
                }),
              }),
            })
          : z.boolean(),
      }),
    ),
  });

  const query = useQuery(SubscriptionManagementPlansDetailDocument, {
    onError() {
      history.replace(routes.plans);
    },
    variables: {
      companyProfileId: companyContext.companyProfileId!,
      planId: params.planId,
      useNewPricingPlans: featureFlags.enable2025Pricing,
    },
  });

  const { data: planSwitchEstimateData, loading: planSwitchEstimateLoading } =
    useQuery(PlanSwitchEstimateDocument, {
      fetchPolicy: 'network-only',
      onCompleted() {
        setPlanSwitchEstimateInitialQueryCompleted(true);
      },
      onError() {
        setPlanSwitchEstimateInitialQueryCompleted(true);
      },
      variables: {
        companyProfileId: companyContext.companyProfileId!,
        planId: selectedPricingPlanId,
      },
    });

  const [changePlan] = useMutation(ChangePlanDocument, {
    awaitRefetchQueries: true,
    onError(error) {
      showToast({
        message: error.message,
        type: 'error',
      });
    },
    refetchQueries: [
      {
        query: SubscriptionManagementOverviewDocument,
        variables: {
          companyProfileId: companyContext.companyProfileId!,
          includePlanChangeData: true,
          planIds: Object.values(PlanId),
        },
      },
      {
        query: PricingPlanMigrationDocument,
        variables: {
          companyProfileId: companyContext.companyProfileId!,
        },
      },
    ],
  });

  const prevRoute = location.state?.fromOverviewPage
    ? routes.root
    : routes.plans;

  const targetPricingPlan = query?.data?.pricingPlansByTier.find(
    (plan) => plan.id === selectedPricingPlanId,
  );

  usePageLogger<{
    currentPlanId?: PlanId;
    targetPlanId?: PlanId;
  }>({
    name: 'Subscription Management - Plan Switch Screen',
    properties: {
      currentPlanId: query?.data?.viewer?.company?.currentPlan.id,
      targetPlanId: targetPricingPlan?.id,
    },
    trigger: Boolean(
      query?.data?.viewer?.company?.currentPlan.id && targetPricingPlan?.id,
    ),
  });

  if (
    query.loading ||
    !query.data ||
    !planSwitchEstimateInitialQueryCompleted
  ) {
    return <PlanDetailsSkeleton prevRoute={prevRoute} />;
  }

  const planSwitchEstimate =
    planSwitchEstimateData?.viewer?.company?.planSwitchEstimate;

  const {
    viewer: { company },
  } = query.data;

  if (!company.subscriptionPlan) {
    throw new Error('No subscription plan found');
  }

  if (!targetPricingPlan) {
    throw new Error(
      `No pricing plan found for planId: ${selectedPricingPlanId}`,
    );
  }

  const currentPlanIsSameTier = query.data.pricingPlansByTier.some(
    (plan) => plan?.id === company?.subscriptionPlan?.planId,
  );

  const trialPeriod = getTrialPeriod(
    company.subscriptionPlan,
    company.isCompanyCreation,
  );

  const { isSubmitting } = form.formState;

  const Layout: FC<{ children: ReactNode }> = ({ children }) => (
    <SubscriptionManagementLayout
      asideContent={<PlanRecap targetPricingPlan={targetPricingPlan} />}
      prevRoute={location.state?.fromOverviewPage ? routes.root : routes.plans}
    >
      <Flex direction="column" gap="space-8" marginBottom="space-40">
        <Typography.Header size="large">
          <FormattedMessage
            id="subscription.plan_details.header"
            values={{
              isSameTier: currentPlanIsSameTier,
            }}
          />
        </Typography.Header>

        {trialPeriod.isOngoing ? (
          <Flex align="center" gap="space-6">
            <Icon color="$grey.800" icon="sparkles" size="small" />

            <Typography.Text bold>
              <FormattedMessage
                id="subscription.duration_before_end_of_trial_period"
                values={{
                  duration: formatDuration(trialPeriod.duration, {
                    format: ['years', 'months', 'days'],
                  }),
                }}
              />
            </Typography.Text>
          </Flex>
        ) : null}
      </Flex>

      {children}
    </SubscriptionManagementLayout>
  );

  const ineligibleReasons = formatPlanSwitchIneligibilityReasons(
    planSwitchEstimate?.ineligibleReasons ?? [],
  );

  if (ineligibleReasons.hasPrePaidPeriodInProgress) {
    return (
      <Layout>
        <Callout variant="information">
          <Typography.Text>
            <FormattedMessage
              id="subscription.plan_details.has_pre_paid_period_remaining_callout"
              values={{
                support_link: (content) => (
                  <Typography.Link {...INTERCOM_LAUNCHER_PROPS}>
                    {content}
                  </Typography.Link>
                ),
              }}
            />
          </Typography.Text>
        </Callout>
      </Layout>
    );
  }

  const { monthlyPlan, yearlyPlan } = query.data.pricingPlansByTier.reduce(
    (acc, plan) => {
      if (plan.billingFrequency === 'MONTHLY') {
        acc.monthlyPlan = plan as PricingPlan;
      } else if (plan.billingFrequency === 'YEARLY') {
        acc.yearlyPlan = plan as PricingPlan;
      }
      return acc;
    },
    {} as { monthlyPlan?: PricingPlan; yearlyPlan?: PricingPlan },
  );

  const PlanFrequencySwitchComponent =
    yearlyPlan && monthlyPlan ? (
      <PlanFrequencySwitch
        currentPricingPlanId={company.currentPlan.id}
        discountPeriodEnd={company.planMigration?.discountPeriodEnd ?? null}
        isDisabled={isSubmitting}
        monthlyPlan={monthlyPlan}
        selectedPricingPlanId={selectedPricingPlanId}
        setSelectedPricingPlanId={setSelectedPricingPlanId}
        yearlyPlan={yearlyPlan}
      />
    ) : null;

  if (ineligibleReasons.hasMoreThanOneMonthLeftInContractTerm) {
    return (
      <Layout>
        <YStack gap="$space.32">
          <Callout variant="information">
            <Typography.Text>
              <FormattedMessage
                id="subscription.plan_details.one_more_month_left_in_contract_term_callout"
                values={{
                  contractEndDate: (
                    <FormattedDate
                      day="numeric"
                      month="short"
                      value={
                        new Date(
                          company.subscriptionPlan.billingPeriod.currentTermEndAt,
                        )
                      }
                      year="numeric"
                    />
                  ),
                  support_link: (content) => (
                    <Typography.Link {...INTERCOM_LAUNCHER_PROPS}>
                      {content}
                    </Typography.Link>
                  ),
                }}
              />
            </Typography.Text>
          </Callout>
          {PlanFrequencySwitchComponent}
        </YStack>
      </Layout>
    );
  }

  return (
    <Layout>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(async () => {
            const previousPlan = company.currentPlan.id;

            const { errors } = await changePlan({
              variables: {
                input: {
                  companyProfileId: companyContext.companyProfileId!,
                  planId: targetPricingPlan.id,
                },
              },
            });

            if (!errors) {
              history.replace(
                `${routes.root}?${new URLSearchParams({
                  'switched-from-plan': previousPlan,
                })}`,
              );
            }
          })}
        >
          <Box marginBottom="space-40">
            <YStack gap="$space.32">
              {PlanFrequencySwitchComponent}
              {targetPricingPlan.id !== company.subscriptionPlan.planId ? (
                <>
                  <Divider />
                  <PlanSwitchEstimateDetails
                    currentBillingPeriodTermEnd={
                      company.subscriptionPlan.billingPeriod.currentTermEndAt
                    }
                    loading={planSwitchEstimateLoading}
                    planSwitchEstimate={planSwitchEstimate}
                  />
                </>
              ) : null}

              {trialPeriod.isOngoing &&
              targetPricingPlan.billingFrequency ===
                SubscriptionPlanFrequency.Yearly ? (
                <Callout variant="warning">
                  <Typography.Text>
                    <FormattedMessage
                      id="subscription.plan_details.trial_period_end_callout"
                      values={{
                        duration: formatDuration(trialPeriod.duration, {
                          format: ['years', 'months', 'days'],
                        }),
                      }}
                    />
                  </Typography.Text>
                </Callout>
              ) : null}
              {ineligibleReasons.hasInsufficientFunds ? (
                <Callout variant="error">
                  <FormattedMessage
                    id="subscription.plan_details.insufficient_funds_callout"
                    values={{
                      planName: targetPricingPlan.brandName,
                    }}
                  />
                </Callout>
              ) : null}
              {requiresInsuranceTermsAgreement ? (
                <>
                  <Divider />
                  <InsuranceTermsForm
                    brandName={targetPricingPlan.brandName}
                    isDisabled={isSubmitting}
                    loading={planSwitchEstimateLoading}
                  />
                </>
              ) : null}
            </YStack>
          </Box>
          <Button
            alignSelf="flex-start"
            isDisabled={
              query.loading ||
              !query.data ||
              !planSwitchEstimate ||
              planSwitchEstimateLoading ||
              Object.values(ineligibleReasons).some(Boolean)
            }
            isLoading={isSubmitting}
            type="submit"
          >
            <FormattedMessage
              id="subscription.plan_details.change_plan_cta"
              values={{
                isSameTier: currentPlanIsSameTier,
                planName: targetPricingPlan.brandName,
              }}
            />
          </Button>
        </form>
      </FormProvider>
    </Layout>
  );
};
