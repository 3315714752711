import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Image } from '@chakra-ui/react';
import {
  Button,
  Typography,
  View,
  YStack,
} from '@shinetools/sunshine-universal';

import mail from 'assets/brand/mail.png';
import SunshineModal from 'components/_core/SunshineModal';

export type EmailVerifyingChallengeProps = {
  isOpen: boolean;
  onClose: (arg0: boolean) => void;
  isVerifying: boolean;
};

const EmailVerifyingChallenge: FC<EmailVerifyingChallengeProps> = ({
  isOpen,
  isVerifying,
  onClose,
}) => {
  return (
    <SunshineModal isOpen={isOpen} onClose={() => onClose(true)}>
      <YStack alignItems="flex-end" flexDirection="column">
        <View padding="$space.16">
          <Button
            hugContent={true}
            icon="cross"
            isLoading={isVerifying}
            onPress={() => {}}
            variant="secondary"
          >
            <FormattedMessage id="authentication.challenge.verifying" />
          </Button>
        </View>
      </YStack>
      <SunshineModal.Body padding={0}>
        <View backgroundColor="$grey.200" height={208} padding={0}>
          <Image marginX="auto" src={mail} width="200px" />
        </View>

        <View gap="$space.16" padding="$space.32">
          <SunshineModal.Title>
            <FormattedMessage id="authentication.challenge.verifying_email_title" />
          </SunshineModal.Title>
          <Typography.Text>
            <FormattedMessage id="authentication.challenge.verifying_email_description" />
          </Typography.Text>
        </View>
      </SunshineModal.Body>
    </SunshineModal>
  );
};

export default EmailVerifyingChallenge;
