export class InvalidUuidError extends Error {
  constructor(message = 'Invalid UUID') {
    super(message);
  }
}

export class OAuthBadRedirectURIError extends Error {
  constructor(message = 'Invalid redirect URI') {
    super(message);
  }
}

export class InvalidScopeError extends Error {
  constructor(message = 'Invalid scope', error: unknown) {
    super(message);
    this.cause = error;
  }
}
