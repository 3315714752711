import {
  defineMessage,
  type IntlShape,
  type MessageDescriptor,
} from 'react-intl';
import { type GetThemeValueForKey } from '@shinetools/sunshine-universal';

import {
  PackageId,
  PackageType,
  PlanId,
  PlanTier,
  type PricingPlan,
} from '__generated__/GQL';
import { type OnboardingPlanDisplay } from 'features/Onboarding/modules/Pricing/types';

/**
 * Plan Card Color
 */
const planCardColor = {
  black: '$grey.800',
  blue: '$blue.600',
  green: '$green.500',
  orange: '$orange.600',
} satisfies Record<PricingPlan['brandColor'], GetThemeValueForKey<'color'>>;

const isSupportedPlanColor = (
  brandColor: string,
): brandColor is keyof typeof planCardColor =>
  Object.keys(planCardColor).includes(brandColor);

export const getPlanCardColor = (brandColor: string) =>
  isSupportedPlanColor(brandColor)
    ? planCardColor[brandColor]
    : (brandColor as GetThemeValueForKey<'color'>);

/**
 * Plan Card Description
 */
const planCardDescription = {
  [PlanTier.Business]: defineMessage({
    id: 'onboarding.pricing_plan_2025.selection.plan_card.description.business',
  }),
  [PlanTier.Free]: defineMessage({
    id: 'onboarding.pricing_plan_2025.selection.plan_card.description.free',
  }),
  [PlanTier.Plus]: defineMessage({
    id: 'onboarding.pricing_plan_2025.selection.plan_card.description.plus',
  }),
  [PlanTier.Start]: defineMessage({
    id: 'onboarding.pricing_plan_2025.selection.plan_card.description.start',
  }),
  // Old plan tier - should not be displayed but here for TS completeness
  [PlanTier.Old]: defineMessage({
    id: 'onboarding.pricing_plan_2025.selection.plan_card.description.start',
  }),
} satisfies Record<PlanTier, MessageDescriptor>;

export const getPlanCardDescription = (tier: PlanTier): MessageDescriptor =>
  planCardDescription[tier];

const messages = {
  highlight: {
    advantageous: defineMessage({
      id: 'onboarding.pricing_plan_2025.selection.plan_card.highlight.advantageous',
    }),
    popular: defineMessage({
      id: 'onboarding.pricing_plan_2025.selection.plan_card.highlight.popular',
    }),
  },
};

export const getPlanCardHighlight = (
  plan: Pick<OnboardingPlanDisplay, 'planId' | 'packageId' | 'packageType'>,
  intl: IntlShape,
): string | undefined => {
  const { packageId, packageType, planId } = plan;

  // For Shine Micro, we can't check the planId
  // as all packages are linked to monthly plans, even for 6 months ones
  if (packageType === PackageType.ShineStart) {
    if (packageId === PackageId.MicroMonthlyStart_2025) {
      return intl.formatMessage(messages.highlight.popular);
    }

    if (packageId === PackageId.Micro_6MonthsPlus_2025) {
      return intl.formatMessage(messages.highlight.advantageous);
    }

    return undefined;
  }

  if (planId === PlanId.StartMonthly_2025) {
    return intl.formatMessage(messages.highlight.popular);
  }

  if (planId === PlanId.PlusYearly_2025) {
    return intl.formatMessage(messages.highlight.advantageous);
  }

  return undefined;
};
