import {
  Feature,
  isFeatureAvailable,
  PaymentCardType,
} from '@shinetools/pricing-plan-library';

import { PlanTier, SupportLevelType } from '__generated__/GQL';
import formatPercent from 'common/formatPercent';
import formatPrice from 'helpers/price/formatPrice';

import {
  PricingTableRow,
  PricingTableSection,
  type PricingTableSectionItem,
} from './types';

const isUnlimitedValue = ({ value }: { value: unknown }) =>
  value === 2147483647;

export const tableSections: PricingTableSectionItem[] = [
  {
    key: PricingTableSection.AccountAndCards,
    rows: [
      {
        getCellData: (plan) => ({
          text:
            String(plan.tier).charAt(0).toUpperCase() +
            String(plan.tier).slice(1).toLowerCase(),
        }),
        key: PricingTableRow.Subscription,
      },
      {
        getCellData: (plan) => ({
          text: plan.limits.activeBankAccounts.value.toString(10),
        }),
        key: PricingTableRow.IbanAccounts,
      },
      {
        getCellData: (plan, intl) => ({
          text:
            plan.paymentCard.type === PaymentCardType.BasicDebitCard
              ? intl.formatMessage(
                  {
                    id: 'onboarding.pricing_plan_2025.selection.row.physical_cards.basic_debit',
                  },
                  { quantity: plan.limits.physicalPaymentCard.value },
                )
              : intl.formatMessage(
                  {
                    id: 'onboarding.pricing_plan_2025.selection.row.physical_cards.business_world_debit',
                  },
                  { quantity: plan.limits.physicalPaymentCard.value },
                ),
        }),
        key: PricingTableRow.PhysicalCards,
      },
      {
        getCellData: (plan, intl) => ({
          text: plan.limits.virtualPaymentCardMonth.isUnlimited
            ? intl.formatMessage({
                id: 'onboarding.pricing_plan_2025.selection.row.virtual_cards.unlimited',
              })
            : intl.formatMessage(
                {
                  id: 'onboarding.pricing_plan_2025.selection.row.virtual_cards.cell',
                },
                {
                  amount: formatPrice(
                    plan.fees.additionalVirtualCardMonth.fixed,
                  ),
                },
              ),
        }),
        key: PricingTableRow.VirtualCards,
      },
      {
        getCellData: (plan) => ({
          text: plan.limits.sepaTransferMonth.value.toString(10),
        }),
        key: PricingTableRow.SepaTransfers,
      },
      {
        getCellData: () => ({
          isChecked: true,
        }),
        key: PricingTableRow.InstantTransfers,
      },
      {
        getCellData: (plan, intl) => ({
          text: intl.formatMessage(
            {
              id: 'onboarding.pricing_plan_2025.selection.row.incoming_international_transfers.cell',
            },
            {
              amount: formatPrice(plan.fees.swiftTransferPayin.fixed),
            },
          ),
        }),
        key: PricingTableRow.IncomingInternationalTransfers,
      },
      {
        getCellData: (plan, intl) => ({
          text: intl.formatMessage(
            {
              id: 'onboarding.pricing_plan_2025.selection.row.outgoing_international_transfers.cell',
            },
            {
              amount: formatPrice(
                plan.fees.internationalTransferPayout.minimum ?? NaN,
              ),
              percent: formatPercent(
                plan.fees.internationalTransferPayout.variable ?? NaN,
              ),
            },
          ),
        }),
        key: PricingTableRow.OutgoingInternationalTransfers,
      },
      {
        getCellData: (plan) => ({
          isChecked: plan.limits.transfersPerBulk.value > 0,
        }),
        key: PricingTableRow.BulkTransfers,
      },
      {
        getCellData: (plan, intl) => ({
          text: intl.formatMessage(
            {
              id: 'onboarding.pricing_plan_2025.selection.row.card_payment_abroad.cell',
            },
            {
              percent: formatPercent(plan.fees.cardPayoutInter.variable ?? NaN),
            },
          ),
        }),
        key: PricingTableRow.CardPaymentAbroad,
      },
      {
        getCellData: (plan) => ({
          text: formatPrice(
            plan.limits.physicalPaymentCardLimitsMonthlySlidingWindow.value,
          ),
        }),
        key: PricingTableRow.CardPaymentLimit,
      },
      {
        getCellData: (plan, intl) => ({
          text: (
            plan.limits.withdrawalMonth.value ||
            intl.formatMessage({
              id: 'onboarding.pricing_plan_2025.selection.row.cash_withdrawals.optional',
            })
          ).toString(),
        }),
        key: PricingTableRow.CashWithdrawals,
      },
      {
        getCellData: (plan) => ({
          text: formatPrice(
            plan.limits.withdrawalLimitsMonthlySlidingWindow.value,
          ),
        }),
        key: PricingTableRow.WithdrawalLimit,
      },
      {
        getCellData: (plan, intl) => ({
          text:
            plan.limits.checkDepositMonth.value.toString(10) ||
            intl.formatMessage({
              id: 'onboarding.pricing_plan_2025.selection.row.check_deposit.optional',
            }),
        }),
        key: PricingTableRow.CheckDeposit,
      },
      {
        getCellData: (plan, intl) => ({
          text: intl.formatMessage(
            {
              id: 'onboarding.pricing_plan_2025.selection.row.cash_deposit.cell',
            },
            { percent: formatPercent(plan.fees.cashDeposit.variable ?? NaN) },
          ),
        }),
        key: PricingTableRow.CashDeposit,
      },
    ],
  },
  {
    key: PricingTableSection.Invoicing,
    rows: [
      {
        getCellData: (plan, intl) => ({
          text: plan.limits.invoicingMonth.isUnlimited
            ? intl.formatMessage({
                id: 'onboarding.pricing_plan_2025.selection.row.invoicing_tool.unlimited',
              })
            : intl.formatMessage(
                {
                  id: 'onboarding.pricing_plan_2025.selection.row.invoicing_tool.cell',
                },
                { quantity: plan.limits.invoicingMonth.value },
              ),
        }),
        key: PricingTableRow.InvoicingTool,
      },
      {
        getCellData: (plan) => ({
          isChecked: isFeatureAvailable(plan, Feature.PersonalizedLogo),
        }),
        key: PricingTableRow.CustomisedQuotesAndInvoices,
      },
      {
        getCellData: (plan) => ({
          isChecked: isFeatureAvailable(plan, Feature.InvoicingItemsCatalog),
        }),
        key: PricingTableRow.ProductAndServicesCatalogue,
      },
      {
        getCellData: () => ({
          isChecked: true,
        }),
        key: PricingTableRow.AutoReminder,
      },
      {
        getCellData: () => ({
          isChecked: true,
        }),
        key: PricingTableRow.InvoiceCardPayment,
      },
      {
        getCellData: () => ({
          isChecked: true,
        }),
        key: PricingTableRow.PaymentTerminal,
      },
    ],
  },
  {
    key: PricingTableSection.TrackingAndPreAccounting,
    rows: [
      {
        getCellData: (plan) => ({
          isChecked: isFeatureAvailable(
            plan,
            Feature.CashflowManagementDashboard,
          ),
        }),
        key: PricingTableRow.CashflowManagementDashboard,
      },
      {
        getCellData: (plan) => ({
          isChecked: isFeatureAvailable(plan, Feature.UrssafEstimator),
        }),
        key: PricingTableRow.UrssafEstimator,
      },
      {
        getCellData: (plan) => ({
          isChecked: isFeatureAvailable(plan, Feature.AccountantAccess),
        }),
        key: PricingTableRow.AccountantAccess,
      },
      {
        getCellData: () => ({
          isChecked: true,
        }),
        key: PricingTableRow.AccountantExports,
      },
      {
        getCellData: () => ({
          isChecked: true,
        }),
        key: PricingTableRow.Receipts,
      },
      {
        getCellData: () => ({
          isChecked: true,
        }),
        key: PricingTableRow.AccountingSoftwareIntegration,
      },
    ],
  },
  {
    key: PricingTableSection.TeamExpenseManagement,
    rows: [
      {
        getCellData: (plan, intl) => {
          if (!plan.limits.companyUserAccess.value) {
            return { isChecked: false };
          }
          if (
            plan.limits.companyUserAccess.isUnlimited &&
            isUnlimitedValue(plan.limits.companyUserAccess)
          ) {
            return {
              text: intl.formatMessage({
                id: 'onboarding.pricing_plan_2025.selection.row.employee_access.unlimited',
              }),
            };
          }
          return {
            text: intl.formatMessage(
              {
                id: 'onboarding.pricing_plan_2025.selection.row.employee_access.cell',
              },
              {
                amount: formatPrice(plan.fees.extraSeatMonth.fixed),
                quantity: plan.limits.companyUserAccess.value,
              },
            ),
          };
        },
        key: PricingTableRow.EmployeeAccess,
      },
      {
        getCellData: (plan) => ({
          isChecked: isFeatureAvailable(plan, Feature.TeamManagement),
        }),
        key: PricingTableRow.ExpenseAccountManagement,
      },
      {
        getCellData: (plan) => ({
          isChecked: isFeatureAvailable(plan, Feature.TeamManagement),
        }),
        key: PricingTableRow.EmployeeCards,
      },
      {
        getCellData: (plan) => ({
          isChecked: isFeatureAvailable(plan, Feature.TeamManagement),
        }),
        key: PricingTableRow.EmployeeTransfers,
      },
    ],
  },
  {
    key: PricingTableSection.InsuranceAndSupport,
    rows: [
      {
        getCellData: (plan, intl) => {
          if (
            plan.customerSupport.urgentRequestsSupportLevel ===
            SupportLevelType.NormalPriority
          ) {
            return {
              isChecked: true,
            };
          }

          return {
            text: intl.formatMessage({
              id: 'onboarding.pricing_plan_2025.selection.row.online_support.priority',
            }),
          };
        },
        key: PricingTableRow.OnlineSupport,
      },
      {
        getCellData: (plan, intl) => {
          if (!plan.customerSupport?.phoneSupportLevel) {
            return { isChecked: false };
          }
          if (
            plan.customerSupport.phoneSupportLevel ===
            SupportLevelType.StandardPriority
          ) {
            return {
              isChecked: true,
            };
          }
          return {
            text: intl.formatMessage({
              id: 'onboarding.pricing_plan_2025.selection.row.phone_support.priority',
            }),
          };
        },

        key: PricingTableRow.PhoneSupport,
      },
      {
        getCellData: (plan, intl) => {
          if (!isFeatureAvailable(plan, Feature.InsuranceAssistance)) {
            return { isChecked: false };
          }

          return {
            text: intl.formatMessage(
              {
                id: 'onboarding.pricing_plan_2025.selection.row.legal_assistance.cell',
              },
              {
                maxCallsPerYear:
                  plan.insurances.coverage2024DetailedTerms.legalAssistance
                    .maxCallsPerYear,
              },
            ),
          };
        },
        key: PricingTableRow.LegalAssistance,
      },
      {
        getCellData: (plan, intl) => {
          if (!isFeatureAvailable(plan, Feature.InsuranceAssistance)) {
            return { isChecked: false };
          }

          return {
            text: intl.formatMessage(
              {
                id: 'onboarding.pricing_plan_2025.selection.row.debt_collection_assistance.cell',
              },
              {
                maxCallsPerYear:
                  plan.insurances.coverage2024DetailedTerms.debtRecovery
                    .maxCallsPerYear,
              },
            ),
          };
        },
        key: PricingTableRow.DebtCollectionAssistance,
      },
      {
        getCellData: (plan, intl) => {
          if (!isFeatureAvailable(plan, Feature.InsuranceAssistance)) {
            return { isChecked: false };
          }

          const { hospitalization } = plan.insurances.coverage2024DetailedTerms;

          return {
            text: intl.formatMessage(
              {
                id: 'onboarding.pricing_plan_2025.selection.row.accident_insurance.cell',
              },
              {
                compensationAmount: formatPrice(
                  hospitalization.compensation.amount,
                ),
                fromFirstDay: hospitalization.compensatedFrom === 'FIRST_DAY',
                //if Feature.InsuranceAssistance is available, maxAmountPerClaim should be defined
                numberOfNight: hospitalization.maxAmountPerClaim
                  ? hospitalization.maxAmountPerClaim /
                    hospitalization.compensation.amount
                  : 0,
              },
            ),
          };
        },
        key: PricingTableRow.AccidentInsurance,
      },
      {
        getCellData: (plan, intl) => {
          if (!isFeatureAvailable(plan, Feature.InsuranceAssistance)) {
            return { isChecked: false };
          }

          const { deductible, isCovered, maxAmountPerClaim } =
            plan.insurances.coverage2024DetailedTerms.mobileScreen;

          return {
            text: intl.formatMessage(
              {
                id: 'onboarding.pricing_plan_2025.selection.row.mobile_insurance.cell',
              },
              {
                //if Feature.InsuranceAssistance is available, maxAmountPerClaim should be defined
                compensationAmount: formatPrice(maxAmountPerClaim ?? 0),
                deductibleAmount: formatPrice(deductible.fixed),
                isUnlimited: isCovered && deductible.fixed === 0,
              },
            ),
          };
        },
        key: PricingTableRow.MobileInsurance,
      },
      {
        getCellData: (plan, intl) => {
          if (plan.tier !== PlanTier.Business) {
            return {
              isChecked: false,
            };
          }

          return {
            text: intl.formatMessage(
              {
                id: 'onboarding.pricing_plan_2025.selection.row.laptop_insurance.cell',
              },
              {
                //maxAmountPerClaim should always be defined for Business plan
                compensationAmount: formatPrice(
                  plan.insurances.coverage2024DetailedTerms.computerBreakage
                    .maxAmountPerClaim ?? 0,
                ),
              },
            ),
          };
        },
        key: PricingTableRow.LaptopInsurance,
      },
      {
        getCellData: (plan, intl) => {
          if (plan.tier !== PlanTier.Business) {
            return { isChecked: false };
          }
          return {
            text: intl.formatMessage(
              {
                id: 'onboarding.pricing_plan_2025.selection.row.purchase_protection.cell',
              },
              {
                //maxAmountPerClaim should always be defined for Business plan
                amount: formatPrice(
                  plan.insurances.coverage2024DetailedTerms.purchaseProtection
                    .maxAmountPerClaim ?? 0,
                ),
              },
            ),
          };
        },
        key: PricingTableRow.PurchaseProtection,
      },
      {
        getCellData: (plan, intl) => {
          if (!isFeatureAvailable(plan, Feature.InsuranceAssistance)) {
            return { isChecked: false };
          }

          const { compensation, maxAmountPerClaim } =
            plan.insurances.coverage2024DetailedTerms.transportDelay;

          return {
            text: intl.formatMessage(
              {
                id: 'onboarding.pricing_plan_2025.selection.row.transport_delays.cell',
              },
              {
                compensationAmount: formatPrice(compensation.amount),
                //if Feature.InsuranceAssistance is available, maxAmountPerClaim should be defined
                maxAmountPerClaim: formatPrice(maxAmountPerClaim ?? 0),
              },
            ),
          };
        },
        key: PricingTableRow.TransportDelays,
      },
      {
        getCellData: (plan, intl) => {
          if (plan.tier !== PlanTier.Business) {
            return { isChecked: false };
          }

          return {
            text: intl.formatMessage(
              {
                id: 'onboarding.pricing_plan_2025.selection.row.healthcare_abroad.cell',
              },
              {
                amount: formatPrice(
                  //maxAmountPerClaim should always be defined for Business plan
                  plan.insurances.coverage2024DetailedTerms.healthCostsAbroad
                    .maxAmountPerClaim ?? 0,
                ),
              },
            ),
          };
        },
        key: PricingTableRow.HealthcareAbroad,
      },
      {
        getCellData: (plan, intl) => {
          if (plan.tier !== PlanTier.Business) {
            return { isChecked: false };
          }

          return {
            text: intl.formatMessage(
              {
                id: 'onboarding.pricing_plan_2025.selection.row.rental_car.cell',
              },
              {
                amount: formatPrice(
                  //maxAmountPerClaim should always be defined for Business plan
                  plan.insurances.coverage2024DetailedTerms.rentalVehicle
                    .maxAmountPerClaim ?? 0,
                ),
              },
            ),
          };
        },
        key: PricingTableRow.RentalCar,
      },
      {
        getCellData: (plan, intl) => {
          if (!isFeatureAvailable(plan, Feature.InsuranceAssistance)) {
            return { isChecked: false };
          }

          return {
            text: intl.formatMessage(
              {
                id: 'onboarding.pricing_plan_2025.selection.row.fraud_insurance.cell',
              },
              {
                amount: formatPrice(
                  //if Feature.InsuranceAssistance is available, maxAmountPerClaim should be defined
                  plan.insurances.coverage2024DetailedTerms.paymentFraud
                    .maxAmountPerClaim ?? 0,
                ),
              },
            ),
          };
        },
        key: PricingTableRow.FraudInsurance,
      },
      {
        getCellData: (plan, intl) => {
          if (plan.tier !== PlanTier.Business) {
            return { isChecked: false };
          }
          return {
            text: intl.formatMessage(
              {
                id: 'onboarding.pricing_plan_2025.selection.row.phishing_protection.cell',
              },
              {
                amount: formatPrice(
                  //maxAmountPerClaim should always be defined for Business plan
                  plan.insurances.coverage2024DetailedTerms.phishing
                    .maxAmountPerClaim ?? 0,
                ),
              },
            ),
          };
        },
        key: PricingTableRow.PhishingProtection,
      },
      {
        getCellData: () => ({
          isChecked: true,
        }),
        key: PricingTableRow.ContractLibrary,
      },
      {
        getCellData: () => ({
          isChecked: true,
        }),
        key: PricingTableRow.Toolbox,
      },
      {
        getCellData: () => ({
          isChecked: true,
        }),
        key: PricingTableRow.PartnerNetwork,
      },
    ],
  },
];
