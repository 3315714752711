import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  type ServerError,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { type DocumentNode, type GraphQLError } from 'graphql';
import { path } from 'ramda';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import config from 'config';
import {
  apolloSentryBreadcrumbLink,
  reportGraphQLErrorToSentry,
} from 'common/sentry';
import webView from 'common/webView';
import { UPDATED_AUTHENTICATION_DEVICE_REQUEST_SUBSCRIPTION_NAME } from 'components/Login/LoginForm/components/NewDeviceAuthorization/hooks/useUpdatedAuthenticationDeviceRequest';
import { AUTHENTICATION_CHALLENGE_RESULT_PUBLIC_SUBSCRIPTION_NAME } from 'components/StrongAuthenticationChallengeModal/graphql/subscriptions/useAuthenticationChallengeResultPublicSubscription';
import {
  authenticationEmitter,
  AuthenticationEvents,
  getTokens,
} from 'helpers/auth';
import logger from 'helpers/logger';

import { possibleTypes } from './possibleTypes';
import typePolicies from './typePolicies';

interface ShineServerError extends GraphQLError {
  code?: string;
  status: number;
}

const isSubscription = (query: DocumentNode): boolean => {
  const definition = getMainDefinition(query);
  if (!('operation' in definition)) {
    return false;
  }
  const { kind, operation } = definition;
  return kind === 'OperationDefinition' && operation === 'subscription';
};

const middlewareAuthLink = setContext(
  async (_, context): Promise<JSONObject | null> => {
    const token = getTokens();
    return {
      headers: {
        authorization: token ? `Bearer ${token.access_token}` : null,
        platform: 'WEBAPP',
        ...(webView.isInWebView ? { 'web-view': 'true' } : {}),
        ...(context.headers || {}),
      },
    };
  },
);

const SENTRY_IGNORED_ERROR_MESSAGES = new Set(['Unknown error']);

const middlewareErrorLink = onError(
  ({ graphQLErrors, networkError, operation: ope, response }): void => {
    const operation = ope || {};
    if (networkError) {
      const serverError = networkError as ServerError;
      // networkError can be undefined
      if (serverError.statusCode === 401) {
        // Receiving a 401 error is part of normal operation - i.e. the user is no longer
        // authenticated - and therefore is not logged as an error. Any co-occurrent graphql
        // errors caused by this 401 are dropped too.
        logger.info('401 response received by GraphQL middleware');

        authenticationEmitter.emit(AuthenticationEvents.OnUnauthorized);

        return;
      }

      if (serverError && serverError.response) {
        logger.warn(
          `[Network Error]: ${serverError.statusCode} (${path(
            ['response', 'statusText'],
            serverError,
          )}) - ${serverError.message}`,
        );
      } else {
        logger.warn('[Network Error]: Network request failed');
      }
    }

    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        // eslint-disable-next-line no-param-reassign
        error.name = 'GraphQLError';
        const shineError = error as ShineServerError;
        if (shineError.code && shineError.status && shineError.status < 500) {
          return;
        }

        const { locations, message, path: requestPath } = error;

        logger.warn(
          `[GraphQL error]: Message: ${message}, Query: ${
            operation.operationName
          }, Location: ${JSON.stringify(locations)}, Path: ${requestPath}`,
        );

        if (SENTRY_IGNORED_ERROR_MESSAGES.has(message)) {
          return;
        }

        reportGraphQLErrorToSentry(error, operation, response);
      });
    }
  },
);

const authenticatedSchemaLink = new HttpLink({
  uri: config.graphqlUri,
});

const cache = new InMemoryCache({
  addTypename: true,
  possibleTypes,
  typePolicies,
});

export const authenticatedSchemaSubscriptionClient = new SubscriptionClient(
  config.authenticatedSchemaSubscriptionsUri,
  {
    connectionParams: async (): Promise<{
      token: string | null;
    }> => {
      const token = getTokens();

      return {
        token: token ? (token.access_token as string) : null,
      };
    },
    lazy: true,
    reconnect: true,
  },
);

authenticatedSchemaSubscriptionClient.onConnected((): void =>
  logger.info('Connected to ws'),
);

// Websocket authenticated subscriptions
const authenticatedSchemaWsLink = new WebSocketLink(
  authenticatedSchemaSubscriptionClient,
);

// This subscription client will only be useful for the updatedAuthenticationDeviceRequest
// subscription, which is only available on the "public schema".
export const publicSchemaSubscriptionClient = new SubscriptionClient(
  config.publicSchemaSubscriptionsUri,
  {
    lazy: true,
    reconnect: false,
  },
);

publicSchemaSubscriptionClient.onConnected(() =>
  logger.info('Connected to public schema ws'),
);

// Websocket public subscriptions
const publicSchemaWsLink = new WebSocketLink(publicSchemaSubscriptionClient);

// send "updatedAuthenticationDeviceRequest" subscription to the "public schema"
// send all other subscriptions to the "authenticated schema"
const wsLink = split(
  ({ query }) => {
    const { name } = getMainDefinition(query);
    return (
      name?.value === UPDATED_AUTHENTICATION_DEVICE_REQUEST_SUBSCRIPTION_NAME ||
      name?.value === AUTHENTICATION_CHALLENGE_RESULT_PUBLIC_SUBSCRIPTION_NAME
    );
  },
  publicSchemaWsLink,
  authenticatedSchemaWsLink,
);

// send subscriptions into the wsLink, queries & mutations in the batchLink
const splitLink = split(
  ({ query }): ReturnType<typeof isSubscription> => isSubscription(query),
  wsLink,
  authenticatedSchemaLink,
);

/*
 * Add apollo middleware
 */
const links = [
  middlewareErrorLink,
  middlewareAuthLink,
  apolloSentryBreadcrumbLink,
  splitLink,
];

/*
 * Init apollo client
 */
export const apolloClient = new ApolloClient({
  cache,
  connectToDevTools: import.meta.env.MODE === 'development',
  link: ApolloLink.from(links),
  resolvers: {},
});

export const publicApolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  connectToDevTools: import.meta.env.MODE === 'development',
  link: new HttpLink({
    uri: config.graphqlPublicUri,
  }),
});
