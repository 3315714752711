import { type FC } from 'react';
import { Image } from '@chakra-ui/react';
import { Button, View, YStack } from '@shinetools/sunshine-universal';

import fadedFlowerImg from 'assets/brand/faded-flower@2x.png';
import SunshineModal from 'components/_core/SunshineModal';

import locales from './locales';

export type AsyncChallengeProps = {
  isOpen: boolean;
  onClose: (arg0: boolean) => void;
  isDeclined: boolean;
};

const DeclinedChallenge: FC<AsyncChallengeProps> = ({
  isDeclined,
  isOpen,
  onClose,
}) => {
  return (
    <SunshineModal isOpen={isOpen} onClose={() => onClose(true)}>
      <YStack alignItems="flex-end" flexDirection="column">
        <View padding="$space.16">
          <Button
            hugContent={true}
            icon="cross"
            onPress={() => onClose(true)}
            variant="secondary"
          >
            {locales.close}
          </Button>
        </View>
      </YStack>

      <SunshineModal.Body padding={0}>
        <View backgroundColor="$grey.200" height={208} padding={0}>
          <Image
            alt={locales.title}
            marginX="auto"
            src={fadedFlowerImg}
            width="180px"
          />
        </View>

        <View gap="$space.16" padding="$space.32">
          <SunshineModal.Title>
            {isDeclined ? locales.refused : locales.expired}
          </SunshineModal.Title>
        </View>
      </SunshineModal.Body>
    </SunshineModal>
  );
};

export default DeclinedChallenge;
