import * as Types from '../../../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CanUseEnrollTrustedDevicePublicQueryVariables = Types.Exact<{
  uid: Types.Scalars['String']['input'];
}>;


export type CanUseEnrollTrustedDevicePublicQuery = { __typename: 'Query', canUseEnrollTrustedDevicePublic: boolean };


export const CanUseEnrollTrustedDevicePublicDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CanUseEnrollTrustedDevicePublic"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"uid"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"canUseEnrollTrustedDevicePublic"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"uid"},"value":{"kind":"Variable","name":{"kind":"Name","value":"uid"}}}]}]}}]} as unknown as DocumentNode<CanUseEnrollTrustedDevicePublicQuery, CanUseEnrollTrustedDevicePublicQueryVariables>;