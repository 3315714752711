import queryString from 'query-string';

import { reportCustomNetworkCallErrorToSentry } from 'common/sentry';
import { extractPartialTraceId } from 'common/sentry/utils';

import packageInfo from '../../../package.json';
import { type ShouldReportToSentryVerifier } from './types';

const checkStatus = async (
  response: Response,
): Promise<Response & { partialTraceId?: string }> => {
  if (response.ok) {
    return response;
  }

  const partialTraceId = extractPartialTraceId(response);

  const res = await response.json();

  if (partialTraceId) {
    res.partialTraceId = partialTraceId;
  }

  // status is not returned by response.json(), copy it from initial response object
  throw { ...res, status: response.status };
};

const parseJSON = <T>(response: Response): Promise<T> => {
  if (response.status === 204) {
    return {} as Promise<T>;
  }

  return response.json();
};

export const enhancedFetch = <T>(
  url: RequestInfo,
  options: RequestInit = {},
  shouldReportToSentry: ShouldReportToSentryVerifier = () => true,
): Promise<T> => {
  const params = {
    ...options,
    headers: {
      Accept: 'application/json',
      // set version from package.json as fallback to avoid missing value
      'app-version': import.meta.env.VITE_GIT_SHA || packageInfo.version,
      'Content-Type': 'application/json',
      platform: 'web',
      ...options.headers,
    },
  };

  if (typeof params.body !== 'string') {
    params.body = JSON.stringify(params.body);
  }

  return fetch(url, params)
    .then(checkStatus)
    .then((res: Response): Promise<T> => parseJSON<T>(res))
    .catch((err) => {
      if (shouldReportToSentry(err)) {
        reportCustomNetworkCallErrorToSentry({
          message: err.message,
          method: (params.method as 'GET' | 'POST') || 'GET',
          partialTraceId: err.partialTraceId || null,
          status: err.status,
          url: url.toString(),
        });
      }

      throw err;
    });
};

export const get = <T = JSONObject>(
  path: string,
  params?: JSONObject,
  options?: RequestInit,
  shouldReportToSentry?: ShouldReportToSentryVerifier,
): Promise<T> => {
  return enhancedFetch<T>(
    `${path}${params ? `?${queryString.stringify(params)}` : ''}`,
    options,
    shouldReportToSentry,
  );
};

export const post = <T = JSONObject>(
  path: string,
  params?: JSONObject,
  options?: RequestInit,
  shouldReportToSentry?: ShouldReportToSentryVerifier,
): Promise<T> => {
  return enhancedFetch<T>(
    `${path}`,
    {
      body: JSON.stringify(params),
      method: 'POST',
      ...options,
    },
    shouldReportToSentry,
  );
};

export const patch = <T = JSONObject>(
  path: string,
  params?: JSONObject,
  options?: RequestInit,
  shouldReportToSentry?: ShouldReportToSentryVerifier,
): Promise<T> => {
  return enhancedFetch<T>(
    `${path}`,
    {
      body: JSON.stringify(params),
      method: 'PATCH',
      ...options,
    },
    shouldReportToSentry,
  );
};

export default enhancedFetch;
