import {
  forwardRef,
  ModalHeader,
  type ModalHeaderProps,
} from '@chakra-ui/react';

import IconButton from 'components/_core/IconButton';

import { useStyles } from '../../context';
import { useModalContext } from '../../hooks/useModalContext';
import locales from './locales';

const Header = forwardRef<ModalHeaderProps, 'div'>(
  ({ children, ...props }, ref) => {
    const { isClosable, onClose } = useModalContext();
    const styles = useStyles();

    return (
      <ModalHeader
        alignItems="center"
        display="flex"
        justifyContent={children ? 'space-between' : 'flex-end'}
        ref={ref}
        {...props}
      >
        {children}

        {isClosable ? (
          /**
           * we have to use a custom `IconButton` component
           * because `ModalCloseButton` does not accept a custom icon
           */
          <IconButton
            __css={styles.closeButton}
            aria-label={locales.label}
            icon="cross"
            onClick={onClose}
          />
        ) : null}
      </ModalHeader>
    );
  },
);

export default Header;
