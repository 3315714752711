import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    unknownError: 'There was an unknown error',
  },
  fr: {
    unknownError: 'Une erreur inconnue est survenue',
  },
} as const);

export default locales;
