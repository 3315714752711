import { type FC, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import { Box, Image } from '@chakra-ui/react';
import { Button, Typography, YStack } from '@shinetools/sunshine-universal';

import hourglass from 'assets/images/hourglass.svg';
import successCheckConfettis from 'assets/images/successCheckConfettis.svg';
import useQueryParams from 'common/hooks/useQueryParams';
import SunshineCard from 'components/_core/SunshineCard';
import ErrorView from 'components/ErrorView';
import Loader from 'components/Loader';

import { verifyEmailChallenge } from '../../hooks/verifyEmailChallenge';

export const AsyncEmailChallengeRequestPublic: FC = () => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const token = queryParams.get('token') || '';

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [hasHasUnknownError, setHasUnknownError] = useState(false);

  const approve = useCallback(async () => {
    try {
      const result = await verifyEmailChallenge(token);
      if (!result) {
        setHasError(true);
      }
    } catch (err) {
      const { message = '' } = err as Error;

      if (/Invalid token/.test(message) || /Unauthorized/.test(message)) {
        setHasError(true);
      } else {
        setHasUnknownError(true);
      }
    } finally {
      setIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    approve();
  }, [approve]);

  if (hasHasUnknownError) {
    return <ErrorView />;
  }

  if (hasError) {
    return (
      <Box height="100vh" marginX="auto" paddingTop="space-32" width="600px">
        <Button
          icon="cross"
          iconPosition="left"
          marginBottom="$space.16"
          onPress={() => {
            history.replace('/');
          }}
          variant="discreet"
        >
          <FormattedMessage id="authentication.challenge.email_request_canceled" />
        </Button>
        <SunshineCard>
          <YStack
            alignItems="center"
            flex={1}
            gap="$space.24"
            marginHorizontal="$space.40"
          >
            <Image marginTop="space-8" src={hourglass} width="140px" />
            <Typography.Text bold size="large">
              <FormattedMessage id="authentication.challenge.email_request_expired" />
            </Typography.Text>
          </YStack>
        </SunshineCard>
      </Box>
    );
  }

  if (isLoading) {
    return (
      <Box height="100vh" marginX="auto" paddingTop="space-32" width="600px">
        <Loader />
      </Box>
    );
  }

  return (
    <Box height="100vh" marginX="auto" paddingTop="space-32" width="600px">
      <SunshineCard>
        <YStack
          alignItems="center"
          flex={1}
          gap="$space.24"
          marginHorizontal="$space.40"
        >
          <Image src={successCheckConfettis} width="180px" />
          <Typography.Text bold size="large">
            <FormattedMessage id="authentication.challenge.email_request_accepted" />
          </Typography.Text>
          <Typography.Text>
            <FormattedMessage id="authentication.challenge.email_request_success_description" />
          </Typography.Text>
        </YStack>
      </SunshineCard>
    </Box>
  );
};
