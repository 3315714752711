import { PaymentCardType, type PlanId } from '__generated__/GQL';

import {
  type PlanChangeSuccessDialogFragment,
  type PlanChangeSuccessDialogPlansFragment,
} from '../../graphql/overview.gql';
import allCards from './assets/all-cards.png';
import businessCards from './assets/business-cards.png';

type Plan = PlanChangeSuccessDialogPlansFragment;

/**
 * Returns metadata about a plan change
 */
export const getPlanChangeData = (
  switchedFromPlan: PlanId,
  {
    creditCards,
    currentPlan,
    subscriptionPlan,
  }: PlanChangeSuccessDialogFragment,
  plans: Plan[],
) => {
  if (!subscriptionPlan) {
    throw new Error('No subscription plan found');
  }

  const getPlan = (planId: PlanId) => {
    const plan = plans.find((p) => p.id === planId);

    if (!plan) {
      throw new Error(`Plan ${planId} not found`);
    }

    return plan;
  };

  const getImage = (nextPlan: Plan) => {
    // New plan has business card
    if (nextPlan.paymentCard.type === PaymentCardType.BusinessWorldDebitCard) {
      return businessCards;
    }

    return allCards;
  };

  /**
   * Handles a downgrade.
   * The only way to know the target plan is to check the `scheduledDowngrade` field (all other endpoints continue to return the pre-downgrade plan).
   */
  if (subscriptionPlan.scheduledDowngrade) {
    const nextPlan = getPlan(subscriptionPlan.scheduledDowngrade as PlanId);

    return {
      image: getImage(nextPlan),
      isDowngrade: true,
      nextPlan,
      previousPlan: getPlan(currentPlan.id),
    };
  }

  /**
   * Handles an upgrade.
   */
  const nextPlan = getPlan(currentPlan.id);
  const previousPlan = getPlan(switchedFromPlan);

  const paymentCardUpgradeAvailable =
    previousPlan.paymentCard.type === PaymentCardType.BasicDebitCard &&
    nextPlan.paymentCard.type === PaymentCardType.BusinessWorldDebitCard;

  const physicalCards = creditCards.filter((card) => card.isPhysical);

  return {
    image: getImage(nextPlan),
    isDowngrade: false,
    nextPlan,
    previousPlan,
    showOrderCardPrompt:
      physicalCards.length === 0 ||
      (paymentCardUpgradeAvailable &&
        physicalCards.every((card) => !card.isPremium)),
  };
};
