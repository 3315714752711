import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    codeExpired: 'Code expired',
    confirm: 'Confirm',
    description:
      'As a security measure, please verify your identity using the code you received on your phone.',
    expiresInPlural: 'The code expires in {timer} seconds',
    expiresInSingular: 'The code expires in {timer} second',
    resend: 'Send a new code',
    sentToPlural: 'Sent to **{phone}**.',
    sentToSingular: 'Sent to **{phone}**.',
    title: 'Confirm this action with the code received by text message',
    unknownError: 'There was an unknown error',
  },
  fr: {
    codeExpired: 'Code expiré',
    confirm: 'Confirmer',
    description:
      'Pour plus de sécurité, vérifiez votre identité à l’aide du code reçu sur votre téléphone.',
    expiresInPlural: 'Le code expire dans {timer} secondes',
    expiresInSingular: 'Le code expire dans {timer} seconde',
    resend: 'Envoyer un nouveau code',
    sentToPlural: 'Envoyé au **{phone}**.',
    sentToSingular: 'Envoyé au **{phone}**.',
    title: 'Confirmez cette action avec le code reçu par SMS',
    unknownError: 'Une erreur inconnue est survenue',
  },
} as const);

export default locales;
