import {
  type ChallengeMethod,
  type StrongAuthenticationRequired,
} from '__generated__/GQL';

export type ExecuteStrongAuthenticationOperationFunction = (
  secureSessionToken: string,
  payload?: unknown,
) => Promise<void | StrongAuthenticationRequired> | void;

export interface StrongAuthenticationChallengeChildModalProps {
  challengeId: string;
  challengeMethod: ChallengeMethod;
  executeStrongAuthenticationOperation: (token: string) => Promise<void> | void;
  isOpen: boolean;
  onClose: (isClosedByUser: boolean) => void;
  payload?: unknown;
  userId?: string;
}

export type ExecuteStrongAuthenticationOperationProps = {
  executeStrongAuthenticationOperation: ExecuteStrongAuthenticationOperationFunction;
  payload?: unknown;
  error?: Error | null;
};

export const publicAllowedOperation = [
  'EnrollTrustedDevice',
  'EnrollTrustedDeviceWithEmail',
];
