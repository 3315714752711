import { useIntl } from 'react-intl';
import { forwardRef } from '@chakra-ui/react';

import { isLegacyPlan } from '../../../lib/isLegacyPlan';
import { Table } from '../../Table';
import { type RowProps } from '../../Table/parts';
import { useFees, usePlan } from '../context';

export type AdditionalCardProps = RowProps;

export const AdditionalCard = forwardRef<AdditionalCardProps, 'div'>(
  (props, ref) => {
    const intl = useIntl();
    const { fees, toFixed, toVariable } = useFees();
    const plan = usePlan();

    const locales = {
      label: intl.formatMessage({
        id: 'subscription.fees_modal.additional_card.label',
      }),
      value: isLegacyPlan(plan.id)
        ? intl.formatMessage(
            {
              id: 'subscription.fees_modal.additional_card.value.legacy',
            },
            {
              fixed: toFixed(fees.additionalPhysicalPaymentCard),
              monthly: toFixed(fees.additionalPhysicalPaymentCardMonth),
              monthlyVariable: toVariable(
                fees.additionalPhysicalPaymentCardMonth,
              ),
              variable: toVariable(fees.additionalPhysicalPaymentCard),
            },
          )
        : intl.formatMessage(
            {
              id: 'subscription.fees_modal.additional_card.value',
            },
            {
              monthly: toFixed(fees.additionalPhysicalPaymentCardMonth),
            },
          ),
    };

    return (
      <Table.Row {...props} ref={ref}>
        <Table.CellText>{locales.label}</Table.CellText>
        <Table.CellAmount>{locales.value}</Table.CellAmount>
      </Table.Row>
    );
  },
);
