import type React from 'react';

import {
  PlanId,
  PlanMigrationFormat,
  type PricingPlan,
} from '__generated__/GQL';

import { BasicToStartBulletPoints } from './content/basic-start.bullets';
import { BasicToStartTable } from './content/basic-start.table';
import { BusinessToBusinessTable } from './content/business-business.table';
import { PlusToPlusBulletPoints } from './content/plus-plus.bullets';
import { PlusToPlusTable } from './content/plus-plus.table';
import { ProToPlusBulletPoints } from './content/pro-plus.bullets';

export type MigrationContentProps = {
  format: PlanMigrationFormat;
  currentPlan: Pick<PricingPlan, 'id' | 'brandName'>;
  newPlan: Pick<PricingPlan, 'id' | 'brandName'>;
  isInPrepaidPeriod: boolean;
};

export type MigrationContentComponent = React.FC<
  Omit<MigrationContentProps, 'format'>
>;

export const MigrationContent: React.FC<MigrationContentProps> = (
  props,
): React.ReactNode => {
  const { currentPlan, format, newPlan } = props;

  if (
    currentPlan.id === PlanId.Basic &&
    [PlanId.StartMonthly_2025, PlanId.StartYearly_2025].includes(newPlan.id)
  ) {
    return format === PlanMigrationFormat.Table ? (
      <BasicToStartTable {...props} />
    ) : (
      <BasicToStartBulletPoints {...props} />
    );
  }

  if (
    currentPlan.id === PlanId.Pro &&
    [PlanId.PlusMonthly_2025, PlanId.PlusYearly_2025].includes(newPlan.id)
  ) {
    return <ProToPlusBulletPoints {...props} />;
  }

  if (
    currentPlan.id === PlanId.Plus &&
    [PlanId.PlusMonthly_2025, PlanId.PlusYearly_2025].includes(newPlan.id)
  ) {
    return format === PlanMigrationFormat.Table ? (
      <PlusToPlusTable {...props} />
    ) : (
      <PlusToPlusBulletPoints {...props} />
    );
  }

  if (
    currentPlan.id === PlanId.Business &&
    [PlanId.BusinessMonthly_2025, PlanId.BusinessYearly_2025].includes(
      newPlan.id,
    )
  ) {
    return <BusinessToBusinessTable {...props} />;
  }

  throw new Error(
    `Unsupported plan migration: ${currentPlan} to ${newPlan} (${format})`,
  );
};
