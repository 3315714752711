import { SdkEnvironment } from '__generated__/GQL';

import staging from './staging';

const host = window.location.hostname;

const ONE_MINUTE_MS = 60 * 1000;

const config = {
  ...staging,
  authenticatedSchemaSubscriptionsUri: `ws://${host}:8080/v2/subscriptions`,
  gcloudApiKey: 'AIzaSyD9ah67v3eSYos9pa46dDJDiDkODb40LO4',
  graphqlPublicUri: `http://${host}:8088/v2/graphql-public`,
  graphqlUri: `http://${host}:8080/v2/graphql`,
  identityCheckSdkEnvironment: SdkEnvironment.Development,
  publicSchemaSubscriptionsUri: `ws://${host}:8088/v2/subscriptions-public`,
  remoteConfigLogLevel: 'debug',
  remoteConfigMinFetchIntervalMs: 10 * ONE_MINUTE_MS,
  shineApiHost: `http://${host}:10081/v2`,
  // shineApiHost: `http://localhost:10041/v2`, // for running public authentication testing locally
};

export default config;
