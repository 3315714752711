import { BulletPoints } from '../../BulletPoints';

import { type MigrationContentComponent } from '..';

export const BasicToStartBulletPoints: MigrationContentComponent = ({
  isInPrepaidPeriod,
  newPlan,
}) => {
  return (
    <BulletPoints
      alternativePlan={isInPrepaidPeriod ? undefined : 'free'}
      items={[
        { label: 'pricing.migration.page.plans.start.bullets.wallet' },
        { label: 'pricing.migration.page.plans.start.bullets.dashboard' },
        {
          label: 'pricing.migration.page.plans.start.bullets.virtual_cards',
        },
        {
          label: 'pricing.migration.page.plans.start.bullets.custom_invoices',
        },
        {
          label: 'pricing.migration.page.plans.start.bullets.accountant_access',
        },
        {
          label: 'pricing.migration.page.plans.start.bullets.international',
        },
        { label: 'pricing.migration.page.plans.start.bullets.support' },
      ]}
      newPlan={newPlan}
    />
  );
};
