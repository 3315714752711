import { createContext, useContext } from 'react';

import { type PlanId } from '__generated__/GQL';

import type * as GQL from '../../graphql/overview.gql';

type SubscriptionPlan = NonNullable<
  GQL.SubscriptionManagementOverviewQuery['viewer']['company']['subscriptionPlan']
>;
type CurrentPlan = NonNullable<
  GQL.SubscriptionManagementOverviewQuery['viewer']['company']['currentPlan']
>;
type Quotas = SubscriptionPlan['quotas'];
type Fees = CurrentPlan['fees'];

export type QuotaContext = {
  fees: Fees;
  quotas: Quotas;
  planId: PlanId;
  planName: string;
};

export const Quota = createContext<QuotaContext | null>(null);

export const useQuota = () => {
  const context = useContext(Quota);

  if (!context) {
    throw new Error('useQuota must be used within a Quota.Provider');
  }

  return context;
};
