import {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/client';
import { useDisclosure } from '@chakra-ui/react';

import useCompanyContext from 'common/hooks/useCompanyContext';
import useQueryParams from 'common/hooks/useQueryParams';
import { logEvent } from 'features/Analytics/analytics';

import { PlanMigrationModal } from './components/PlanMigrationModal';
import { PricingPlanMigrationDocument } from './graphql/pricingPlanMigration.gql';
import { type PlanMigrationData } from './types';

const PLAN_MIGRATION_QUERY_PARAM = 'planMigration';

const PLAN_MIGRATION_OPENED_STORAGE_KEY = 'PLAN_MIGRATION_2025_OPENED';

type PricingPlanMigrationContextType = {
  planMigrationData: PlanMigrationData | null;
  openPlanMigrationModal: () => void;
};

const PricingPlanMigrationContext = createContext(
  {} as PricingPlanMigrationContextType,
);

export const PricingPlanMigrationProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const history = useHistory();
  const queryParams = useQueryParams();

  const { companyProfileId } = useCompanyContext();

  const { data } = useQuery(PricingPlanMigrationDocument, {
    skip: !companyProfileId,
    variables: {
      companyProfileId: companyProfileId as string,
    },
  });

  const { isOpen, onClose, onOpen } = useDisclosure({
    isOpen: queryParams.has(PLAN_MIGRATION_QUERY_PARAM),
    onClose: () => {
      queryParams.delete(PLAN_MIGRATION_QUERY_PARAM);
      history.replace({
        hash: location.hash,
        search: queryParams.toString(),
      });
    },
    onOpen: () => {
      queryParams.set(PLAN_MIGRATION_QUERY_PARAM, 'true');
      history.replace({
        hash: location.hash,
        search: queryParams.toString(),
      });
    },
  });

  const contextValue = useMemo<PricingPlanMigrationContextType>(() => {
    if (
      !data?.viewer.company.currentPlan ||
      !data?.viewer.company.planMigration
    ) {
      return {
        openPlanMigrationModal: () => {},
        planMigrationData: null,
      };
    }

    const { currentPlan, planMigration } = data.viewer.company;

    return {
      openPlanMigrationModal: onOpen,
      planMigrationData: {
        currentPlan,
        discountPeriodEnd: planMigration.discountPeriodEnd
          ? new Date(planMigration.discountPeriodEnd)
          : null,
        format: planMigration.format,
        migrationDate: new Date(planMigration.migrationDate),
        prepaidPeriodEnd: planMigration.prepaidPeriodEnd
          ? new Date(planMigration.prepaidPeriodEnd)
          : null,
        targetPlan: planMigration.targetPlan,
      },
    };
  }, [data, onOpen]);

  useEffect(() => {
    // Skip if no company selected or no migration available
    if (!companyProfileId || !contextValue.planMigrationData) {
      return;
    }

    const storageKey = `${PLAN_MIGRATION_OPENED_STORAGE_KEY}:${companyProfileId}`;

    const wasOpenedOnce = Boolean(localStorage.getItem(storageKey));

    if (!wasOpenedOnce) {
      localStorage.setItem(storageKey, 'true');

      logEvent({
        name: `Plan Migration Modal Opened`,
        properties: {
          from: 'auto',
        },
      });

      onOpen();
    }
  }, [companyProfileId, contextValue, onOpen]);

  return (
    <PricingPlanMigrationContext.Provider value={contextValue}>
      {children}
      {contextValue.planMigrationData ? (
        <PlanMigrationModal
          data={contextValue.planMigrationData}
          isOpen={isOpen}
          onClose={onClose}
        />
      ) : null}
    </PricingPlanMigrationContext.Provider>
  );
};

export const usePricingPlanMigration = () =>
  useContext(PricingPlanMigrationContext);
