export const formatPrice = (price: number): string => {
  const decimal = price / 100.0;
  const integer = Math.trunc(decimal);

  if (decimal === integer) {
    return `${integer.toFixed(0)}`;
  }

  return `${decimal.toFixed(2)}`;
};
