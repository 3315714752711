import { type FC, useEffect, useState } from 'react';
import { Typography } from '@shinetools/sunshine-universal';

import {
  ChallengeMethod,
  ChallengeStateName,
  type VerifyChallengeOutput,
} from '__generated__/GQL';
import SunshineModal from 'components/_core/SunshineModal';
import Loader from 'components/Loader';

import {
  getErrorLocale,
  RejectStrongAuthenticationOperation,
} from '../../utils/errors';
import ApprovedChallenge from '../ApprovedChallenge';
import ConnectedDevicesVerifyingChallenge from '../ConnectedDevicesVerifyingChallenge';
import DeclinedChallenge from '../DeclinedChallenge';
import EmailVerifyingChallenge from '../EmailVerifyingChallenge';
import MdaVerifyingChallenge from '../MdaVerifyingChallenge';
import locales from './locales';

export type WaitingForValidationChallengeProps = {
  isOpen: boolean;
  onClose: (arg0: boolean) => void;
  isVerifying: boolean;
  isRequesting: boolean;
  method: ChallengeMethod;
  verifyMdaOperation: () => Promise<VerifyChallengeOutput | undefined>;
};

const WaitingForValidationChallenge: FC<WaitingForValidationChallengeProps> = ({
  isOpen,
  isRequesting,
  isVerifying,
  method,
  onClose,
  verifyMdaOperation,
}) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [isDeclined, setIsDeclined] = useState<boolean>(false);
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [isApproved, setIsApproved] = useState<boolean>(false);

  useEffect(() => {
    const startVerificationWatcher = async () => {
      try {
        if (!isVerifying && !isRequesting) {
          const result = await verifyMdaOperation();
          if (result?.name === ChallengeStateName.ChallengeApproved) {
            setIsApproved(true);
          }
        }
      } catch (err) {
        if (
          err instanceof RejectStrongAuthenticationOperation &&
          [
            ChallengeStateName.ChallengeDeclinedNotInitiator,
            ChallengeStateName.ChallengeDeclined,
            ChallengeStateName.ChallengeExpired,
          ].includes(err.reason)
        ) {
          if (err.reason === ChallengeStateName.ChallengeExpired) {
            setIsExpired(true);
          } else {
            setIsDeclined(true);
          }
        } else {
          setError(locales.unknownError);
        }
      }
    };
    startVerificationWatcher();
  }, [isVerifying, isRequesting, verifyMdaOperation, setIsDeclined]);
  if (isOpen && isApproved) {
    return <ApprovedChallenge isOpen={isOpen} onClose={onClose} />;
  }

  if (isOpen && isVerifying) {
    switch (method) {
      case ChallengeMethod.ConnectedDevices:
        return (
          <ConnectedDevicesVerifyingChallenge
            isOpen={isOpen}
            isVerifying={isVerifying}
            onClose={onClose}
          />
        );
      case ChallengeMethod.Email:
        return (
          <EmailVerifyingChallenge
            isOpen={isOpen}
            isVerifying={isVerifying}
            onClose={onClose}
          />
        );
      case ChallengeMethod.Mda:
      default:
        return (
          <MdaVerifyingChallenge
            isOpen={isOpen}
            isVerifying={isVerifying}
            onClose={onClose}
          />
        );
    }
  }

  if (isOpen && (isDeclined || isExpired)) {
    return (
      <DeclinedChallenge
        isDeclined={isDeclined}
        isOpen={isOpen}
        onClose={onClose}
      />
    );
  }

  if (error) {
    return (
      <SunshineModal isOpen={isOpen} onClose={() => onClose(true)}>
        <SunshineModal.Footer>
          <Typography.Text marginTop="$space.16" variant="error">
            {error ? getErrorLocale(error) : locales.unknownError}
          </Typography.Text>
        </SunshineModal.Footer>
      </SunshineModal>
    );
  }

  return (
    <SunshineModal isOpen={isOpen} onClose={() => onClose(true)}>
      <Loader />;
    </SunshineModal>
  );
};

export default WaitingForValidationChallenge;
