import type React from 'react';
import { View, type ViewProps } from '@shinetools/sunshine-universal';

import { PlanId } from '__generated__/GQL';

const planColors: { [ID in PlanId]?: ViewProps['backgroundColor'] } = {
  [PlanId.BusinessMonthly_2025]: '#25241D',
  [PlanId.BusinessYearly_2025]: '#25241D',
  [PlanId.PlusMonthly_2025]: '#A4D36E',
  [PlanId.PlusYearly_2025]: '#A4D36E',
  [PlanId.StartMonthly_2025]: '#2A93F4',
  [PlanId.StartYearly_2025]: '#2A93F4',
  [PlanId.FreeMonthly_2025]: '#F4722A',
};

export type PlanIconProps = {
  planId: PlanId;
  size?: number;
};

export const PlanIcon: React.FC<PlanIconProps> = ({ planId, size = 12 }) => {
  const color = planColors[planId];

  if (!color) {
    return null;
  }

  return (
    <View
      backgroundColor={color}
      borderRadius={4}
      height={size}
      width={size}
    ></View>
  );
};
