import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    close: 'Close',
    expired: 'The operation validation time has expired',
    refused: 'Operation has been declined by main device.',
    title: 'Confirm this operation on your main device',
  },
  fr: {
    close: 'Fermer',
    expired: 'Le délai de validation de l’opération a expiré',
    refused: 'L’opération a été refusée depuis le téléphone principal.',
    title:
      'Confirmez cette opération sur votre téléphone principal de confiance',
  },
} as const);

export default locales;
