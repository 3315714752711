import { type FC, type ReactNode, useEffect, useState } from 'react';

import { ChallengeMethod } from '__generated__/GQL';
import Loader from 'components/Loader';

import { useInitiateSCAOperation } from './hooks/useInitiateSCAOperation';
import AsyncChallenge from './screens/AsyncChallenge';
import PublicAsyncChallenge from './screens/PublicAsyncChallenge';
import SmsChallenge from './screens/SmsChallenge';
import { publicAllowedOperation } from './utils/types';

export type StrongAuthenticationChallengeModalProps = {
  executeStrongAuthenticationOperation: (challengeId: string) => void;
  isFormValid: boolean;
  isOpen: boolean;
  onClose: (isClosedByUser: boolean) => void;
  payload?: unknown;
  loaderComponent?: ReactNode;
};

const StrongAuthenticationChallengeModal: FC<
  StrongAuthenticationChallengeModalProps
> = ({
  executeStrongAuthenticationOperation,
  isFormValid,
  isOpen,
  loaderComponent,
  onClose,
  payload,
}: StrongAuthenticationChallengeModalProps) => {
  const [hasAlreadyBeenOpened, setHasAlreadyBeenOpened] = useState(false);

  const {
    challengeId,
    challengeMethod,
    error,
    initiateOperation,
    isInitiating,
    operation,
  } = useInitiateSCAOperation({
    executeStrongAuthenticationOperation,
    payload,
  });

  const handleOnClose = (isClosedByUser: boolean) => {
    setHasAlreadyBeenOpened(false);
    onClose(isClosedByUser);
  };

  useEffect(() => {
    // Hook that set hasAlreadyBeenOpened to true if the modal is opened
    if (isOpen && !hasAlreadyBeenOpened) {
      setHasAlreadyBeenOpened(true);
    }
    // Initiate the operation if the form is valid and the modal is opened
    if (isFormValid && !isInitiating && isOpen) {
      initiateOperation();
    }
  }, [
    initiateOperation,
    isFormValid,
    isInitiating,
    isOpen,
    challengeId,
    hasAlreadyBeenOpened,
  ]);

  // If modal is not opened, return nothing
  if (!isOpen || !challengeMethod || !challengeId || !operation) {
    return null;
  }

  // If challengeMethod and challengeId are not set, show a modal with loader
  if (!isInitiating) {
    return loaderComponent ? <>{loaderComponent}</> : <Loader />;
  }

  if (error) {
    return null;
  }

  if (
    publicAllowedOperation.includes(operation) &&
    (ChallengeMethod.Mda === challengeMethod ||
      ChallengeMethod.Email === challengeMethod)
  ) {
    return (
      <>
        <PublicAsyncChallenge
          challengeId={challengeId}
          challengeMethod={challengeMethod}
          executeStrongAuthenticationOperation={
            executeStrongAuthenticationOperation
          }
          isOpen={isOpen}
          onClose={handleOnClose}
        />
      </>
    );
  }

  if (challengeMethod === ChallengeMethod.Mda) {
    return (
      <>
        <AsyncChallenge
          challengeId={challengeId}
          challengeMethod={challengeMethod}
          executeStrongAuthenticationOperation={
            executeStrongAuthenticationOperation
          }
          isOpen={isOpen}
          onClose={handleOnClose}
        />
      </>
    );
  }

  return (
    <>
      <SmsChallenge
        challengeId={challengeId}
        challengeMethod={challengeMethod}
        executeStrongAuthenticationOperation={
          executeStrongAuthenticationOperation
        }
        isOpen={isOpen}
        onClose={handleOnClose}
      />
    </>
  );
};

export default StrongAuthenticationChallengeModal;
