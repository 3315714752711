import { useIntl } from 'react-intl';
import { forwardRef } from '@chakra-ui/react';

import { isLegacyPlan } from '../../../lib/isLegacyPlan';
import { Table } from '../../Table';
import { type RowProps } from '../../Table/parts';
import { useFees, usePlan } from '../context';

export type CashDepositProps = RowProps;

export const CashDeposit = forwardRef<CashDepositProps, 'div'>((props, ref) => {
  const intl = useIntl();
  const { fees, quotas, toFixed, toVariable } = useFees();
  const plan = usePlan();

  const locales = {
    label: intl.formatMessage({
      id: 'subscription.fees_modal.cash_deposit.label',
    }),
    value: isLegacyPlan(plan.id)
      ? intl.formatMessage(
          {
            id: 'subscription.fees_modal.cash_deposit.value.legacy',
          },
          {
            fixed: toFixed(fees.cashDeposit),
            limit: quotas.cashDepositMonthQuota.limit,
            variable: toVariable(fees.cashDeposit),
          },
        )
      : intl.formatMessage(
          {
            id: 'subscription.fees_modal.cash_deposit.value',
          },
          {
            variable: toVariable(fees.cashDeposit),
          },
        ),
  };

  return (
    <Table.Row {...props} ref={ref}>
      <Table.CellText>{locales.label}</Table.CellText>
      <Table.CellAmount>{locales.value}</Table.CellAmount>
    </Table.Row>
  );
});
