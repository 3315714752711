import { type SubscriptionHookOptions, useSubscription } from '@apollo/client';

import {
  AuthenticationChallengeResultPublicDocument,
  type AuthenticationChallengeResultPublicSubscription,
  type AuthenticationChallengeResultPublicSubscriptionVariables,
} from './authenticationChallengeResultPublicSubscription.gql';

export const AUTHENTICATION_CHALLENGE_RESULT_PUBLIC_SUBSCRIPTION_NAME =
  'authenticationChallengeResultPublic';

const usePublicAuthenticationChallengeSubscription = (
  challengeId?: string,
  options: SubscriptionHookOptions<
    AuthenticationChallengeResultPublicSubscription,
    AuthenticationChallengeResultPublicSubscriptionVariables
  > = {},
) => {
  return useSubscription<
    AuthenticationChallengeResultPublicSubscription,
    AuthenticationChallengeResultPublicSubscriptionVariables
  >(AuthenticationChallengeResultPublicDocument, {
    ...options,
    variables: { challengeId: challengeId ?? '' },
  });
};

export default usePublicAuthenticationChallengeSubscription;
