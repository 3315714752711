import { type FC } from 'react';

import ErrorCard from 'components/ErrorCard';

import WaitingForValidationChallenge from '../../components/WaitingForValidationChallenge';
import { useValidatePublicMdaSCAOperation } from '../../hooks/useValidatePublicMdaSCAOperation';
import { type StrongAuthenticationChallengeChildModalProps } from '../../utils/types';

const PublicAsyncChallenge: FC<
  StrongAuthenticationChallengeChildModalProps
> = ({
  challengeId,
  challengeMethod,
  executeStrongAuthenticationOperation,
  isOpen,
  onClose,
  payload,
}) => {
  // Hook that executed only if challengeId is set
  const { error, isRequesting, isVerifying, verifyMdaOperation } =
    useValidatePublicMdaSCAOperation({
      challengeId,
      challengeMethod,
      executeStrongAuthenticationOperation,
      payload,
    });

  if (error) {
    <ErrorCard />;
  }

  return (
    <WaitingForValidationChallenge
      isOpen={isOpen}
      isRequesting={isRequesting}
      isVerifying={isVerifying}
      method={challengeMethod}
      onClose={onClose}
      verifyMdaOperation={verifyMdaOperation}
    />
  );
};

export default PublicAsyncChallenge;
