import { useCallback } from 'react';

import { ChallengeMethod } from '__generated__/GQL';

import usePublicAuthenticationChallengeSubscription from '../graphql/subscriptions/useAuthenticationChallengeResultPublicSubscription';
import { type ExecuteStrongAuthenticationOperationFunction } from '../utils/types';
import { useExecuteSCAOperation } from './useExecuteSCAOperation';

export type ExecuteMdaSCAOperationPayload = {
  challengeId: string;
  challengeMethod: ChallengeMethod;
  payload: unknown;
  executeStrongAuthenticationOperation: ExecuteStrongAuthenticationOperationFunction;
};

export const useValidatePublicMdaSCAOperation = ({
  challengeId,
  challengeMethod,
  executeStrongAuthenticationOperation,
  payload,
}: ExecuteMdaSCAOperationPayload) => {
  const {
    data: verificationResult,
    error,
    loading: isVerifying,
  } = usePublicAuthenticationChallengeSubscription(challengeId, {
    skip: !challengeId && challengeMethod !== ChallengeMethod.Mda,
  });

  const { executeOperation, isRequesting } = useExecuteSCAOperation({
    executeStrongAuthenticationOperation,
    payload,
  });

  const verifyMdaOperation = useCallback(async () => {
    if (!verificationResult) {
      return undefined;
    }

    await executeOperation(
      verificationResult.authenticationChallengeResultPublic,
    );

    return verificationResult.authenticationChallengeResultPublic;
  }, [verificationResult, executeOperation]);

  return {
    error,
    isRequesting,
    isVerifying,
    verifyMdaOperation,
  };
};
