import {
  type Duration,
  formatDuration as dateFnsFormatDuration,
} from 'date-fns';

import { webAppLang } from '../../common/i18n';
import { dateFnsLocales } from './constants';

const formatDuration = (
  duration: Duration,
  options?: {
    format?: string[];
    zero?: boolean;
    delimiter?: string;
    locale?: 'en' | 'fr';
  },
): string => {
  return dateFnsFormatDuration(duration, {
    ...options,
    locale: dateFnsLocales[options?.locale ?? webAppLang],
  });
};

export default formatDuration;
