import { useCallback, useState } from 'react';

import { ChallengeStateName } from '__generated__/GQL';

import { type ExecuteStrongAuthenticationOperationFunction } from '../utils/types';
import { useExecuteSCAOperation } from './useExecuteSCAOperation';
import {
  useVerifyChallenge,
  type VerifyChallengeSMSParams,
} from './useVerifyChallenge';

export type ExecuteSmsSCAOperationPayload = {
  payload: unknown;
  executeStrongAuthenticationOperation: ExecuteStrongAuthenticationOperationFunction;
};

export const useVerifySmsSCAOperation = ({
  executeStrongAuthenticationOperation,
  payload,
}: ExecuteSmsSCAOperationPayload) => {
  const [error, setError] = useState<unknown | null>(null);
  const [retry, setRetry] = useState(0);
  const [canRetry, setCanRetry] = useState(true);

  const { executeOperation, isRequesting } = useExecuteSCAOperation({
    executeStrongAuthenticationOperation,
    payload,
  });

  const { approveWithCode, decline, isVerifying, setIsVerifying } =
    useVerifyChallenge();
  const verifySmsOperation = useCallback(
    async (request: VerifyChallengeSMSParams) => {
      if (isVerifying) {
        return false;
      }

      try {
        const result = await approveWithCode(request);

        if (result && result.name === ChallengeStateName.ChallengeApproved) {
          await executeOperation(result);
          return result;
        }

        if (
          result &&
          result.name === ChallengeStateName.ChallengeVerificationFailed
        ) {
          setRetry(retry + 1);
          setCanRetry(true);
          setIsVerifying(false);
          return false;
        }

        if (result && result.name === 'CHALLENGE_DECLINED_AFTER_MAX_ATTEMPTS') {
          setCanRetry(false);
          return result;
        }

        setError(null);
        return false;
      } catch (err) {
        setError(err as Error);
      } finally {
        setError(null);
      }

      return false;
    },
    [isVerifying, approveWithCode, executeOperation, retry, setIsVerifying],
  );

  return {
    canRetry,
    decline,
    error,
    isRequesting,
    isSmsVerifying: isVerifying,
    retry,
    verifySmsOperation,
  };
};
