import type React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { type ModalProps } from '@chakra-ui/react';
import {
  Button,
  Squircle,
  Typography,
  View,
  XStack,
  YStack,
} from '@shinetools/sunshine-universal';

import Modal from 'components/_core/Modal';

import { type PlanMigrationData } from '../../../PricingPlanMigration/types';
import { formatPrice } from '../../utils';
import { MigrateNowCallout } from './components/MigrateNowCallout';
import { MigrationContent } from './components/MigrationContent';

export type PlanMigrationModalProps = Omit<ModalProps, 'children'> & {
  data: PlanMigrationData;
};

export const PlanMigrationModal: React.FC<PlanMigrationModalProps> = ({
  data,
  ...modalProps
}) => {
  const {
    currentPlan,
    discountPeriodEnd,
    format,
    migrationDate,
    prepaidPeriodEnd,
    targetPlan,
  } = data;

  const isPriceIncreased =
    targetPlan.pricing.taxExcluded > currentPlan.pricing.taxExcluded;

  return (
    <Modal {...modalProps} size="lg">
      <Modal.Header>
        <XStack alignItems="center" gap="$space.16">
          <Squircle backgroundColor="$blue.200" icon="information" />
          <Typography.Header>
            <FormattedMessage id="pricing.migration.page.title" />
          </Typography.Header>
        </XStack>
      </Modal.Header>
      <Modal.Body maxHeight="80vh" overflow="auto">
        <YStack>
          <Typography.Text bold marginBottom="$space.8" size="large">
            <FormattedMessage
              id="pricing.migration.page.tldr"
              values={{
                migrationDate: migrationDate.toLocaleDateString(),
                newPlan: targetPlan.brandName,
                oldPlan: `Shine ${currentPlan.brandName}`,
              }}
            />
          </Typography.Text>

          <Typography.Text marginBottom="$space.24" variant="secondary">
            <FormattedMessage
              id="pricing.migration.page.description"
              values={{
                price: formatPrice(targetPlan.pricing.taxExcluded),
              }}
            />
          </Typography.Text>

          <MigrateNowCallout
            currentPlan={currentPlan}
            discountPeriodEnd={discountPeriodEnd}
            isPriceIncreased={isPriceIncreased}
            prepaidPeriodEnd={prepaidPeriodEnd}
            targetPlan={targetPlan}
          />

          <Typography.Text bold marginBottom="$space.24" size="large">
            <FormattedMessage id="pricing.migration.page.new_features" />
          </Typography.Text>

          <MigrationContent
            currentPlan={currentPlan}
            format={format}
            isInPrepaidPeriod={Boolean(prepaidPeriodEnd)}
            newPlan={targetPlan}
          />

          <Typography.Text
            marginBottom="$space.16"
            marginTop="$space.40"
            size="small"
            variant="secondary"
          >
            <FormattedMessage id="pricing.migration.page.conditions.part_1" />
          </Typography.Text>

          <Typography.Text size="small" variant="secondary">
            <FormattedMessage id="pricing.migration.page.conditions.part_2" />
          </Typography.Text>
        </YStack>
      </Modal.Body>
      <Modal.Footer>
        <Link to="/subscription/plans">
          <Typography.Link forceIcon={false} variant="primary">
            <FormattedMessage id="pricing.migration.page.compare_offers" />
          </Typography.Link>
        </Link>
        <View>
          <Button onPress={modalProps.onClose}>
            <FormattedMessage
              id="pricing.migration.page.migration_cta"
              values={{
                plan: targetPlan.brandName,
              }}
            />
          </Button>
        </View>
      </Modal.Footer>
    </Modal>
  );
};
