import { type GraphQLError } from 'graphql';

export const hasErrorCode = (errors: readonly GraphQLError[], code: string) => {
  return (
    errors.length > 0 &&
    (errors.some(
      (error) =>
        'exception' in error.extensions &&
        error.extensions.exception !== null &&
        typeof error.extensions.exception === 'object' &&
        'code' in error.extensions.exception &&
        error.extensions.exception.code === code,
    ) ||
      errors.some(
        (error) =>
          'exception' in error.extensions &&
          error.extensions.code !== null &&
          error.extensions.code === code,
      ))
  );
};
