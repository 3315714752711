import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Icon,
  Typography,
  View,
  XStack,
  YStack,
} from '@shinetools/sunshine-universal';

import { PlanId } from '__generated__/GQL';

import { PlanIcon } from '../../PlanIcon';

export const AlternativePlanFree: React.FC = () => {
  return (
    <>
      <Typography.Text marginBottom="$space.24" variant="secondary">
        <FormattedMessage id="pricing.migration.page.features_not_needed.subtitle" />
      </Typography.Text>

      <YStack
        borderColor="$grey.300"
        borderRadius={16}
        borderWidth={1}
        gap="$space.24"
        padding="$space.24"
      >
        <XStack
          alignItems="flex-start"
          gap="$space.32"
          justifyContent="space-between"
        >
          <YStack flexShrink={1} gap="$space.4">
            <XStack alignItems="center" gap="$space.16">
              <PlanIcon planId={PlanId.FreeMonthly_2025} size={14} />
              <Typography.HeaderNumber>Free</Typography.HeaderNumber>
            </XStack>
            <Typography.Text>
              <FormattedMessage id="pricing.migration.page.plans.free.catchphrase" />
            </Typography.Text>
          </YStack>
          <View>
            <Link to="/subscription/plans">
              <Typography.Link forceIcon={false} variant="primary">
                <FormattedMessage id="pricing.migration.page.discover_plan" />
              </Typography.Link>
            </Link>
          </View>
        </XStack>
        <XStack alignItems="baseline" gap="$space.8">
          <Typography.HeaderNumber size="veryLarge">
            0&nbsp;€
          </Typography.HeaderNumber>
          <Typography.Text size="large">
            <FormattedMessage id="pricing.migration.page.monthly_price" />
          </Typography.Text>
        </XStack>
        <YStack gap="$space.12">
          <XStack alignItems="center" gap="$space.16">
            <Icon icon="tick" />
            <Typography.Text variant="secondary">
              <FormattedMessage id="pricing.migration.page.plans.free.bullets.card" />
              {`\u00a0;`}
            </Typography.Text>
          </XStack>
          <XStack alignItems="center" gap="$space.16">
            <Icon icon="tick" />
            <Typography.Text variant="secondary">
              <FormattedMessage id="pricing.migration.page.plans.free.bullets.invoicing" />
              {`\u00a0;`}
            </Typography.Text>
          </XStack>
          <XStack alignItems="center" gap="$space.16">
            <Icon icon="tick" />
            <Typography.Text variant="secondary">
              <FormattedMessage id="pricing.migration.page.plans.free.bullets.support" />
              .
            </Typography.Text>
          </XStack>
        </YStack>
      </YStack>
    </>
  );
};
