import { ERROR_CODES } from '@shinetools/errors';

import { type ChallengeStateName } from '__generated__/GQL';

import locales from './locales';

export const getErrorLocale = (errorCode: string) => {
  if (errorCode === ERROR_CODES.RATE_LIMIT) {
    return locales.rateLimitError;
  }

  if (errorCode === ERROR_CODES.WRONG_SENSITIVE_OPERATION_VERIFICATION_CODE) {
    return locales.wrongPasscode;
  }

  return locales.unknownError;
};

export class RejectStrongAuthenticationOperation extends Error {
  reason: ChallengeStateName;

  constructor(reason: ChallengeStateName) {
    super('Strong authentication operation rejected');

    this.reason = reason;
  }
}
