import { type FC } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Flex, type FlexProps, Image } from '@chakra-ui/react';
import {
  Icon,
  Squircle,
  Typography,
  View,
  XStack,
} from '@shinetools/sunshine-universal';

import { SubscriptionPlanFrequency } from '__generated__/GQL';
import wallet from 'assets/brand/wallet.png';
import { getPlanCardColor } from 'common/plans/card';
import Button from 'components/_core/Button';
import Skeleton from 'components/Skeleton';
import SkeletonText from 'components/SkeletonText';
import { logEvent } from 'features/Analytics/analytics';
import { usePricingPlanMigration } from 'features/PricingPlanMigration';
import { formatDuration } from 'helpers/date';

import { getTrialPeriod } from '../../../../lib/getTrialPeriod';
import * as routes from '../../../../routes';
import type * as GQL from '../../graphql/overview.gql';
import { Body } from './components/Body';
import { Container } from './components/Container';
import { Footer } from './components/Footer';
import { FooterItem } from './components/FooterItem';
import { ModifyPlanButton } from './components/ModifyPlanButton';

export type OverviewHeaderProps = FlexProps & {
  data?: GQL.SubscriptionManagementOverviewQuery;
};

export const OverviewHeader: FC<OverviewHeaderProps> = (props) => {
  const { data, ...rest } = props;

  const currentPlan = data?.viewer.company.currentPlan;
  const subscriptionPlan = data?.viewer.company.subscriptionPlan;

  const hasScheduledDowngrade = subscriptionPlan?.scheduledDowngrade;
  const isLoaded = Boolean(data);

  const trialPeriod = getTrialPeriod(
    subscriptionPlan,
    data?.viewer.company.isCompanyCreation,
  );

  const nextBillingPeriod =
    subscriptionPlan?.commitmentRenewsAt || subscriptionPlan?.nextBillingAt;

  const { openPlanMigrationModal, planMigrationData } =
    usePricingPlanMigration();

  return (
    <>
      <Container {...rest}>
        {planMigrationData ? (
          <XStack
            alignItems="center"
            backgroundColor="$blue.100"
            borderBottomWidth={1}
            borderColor="$grey.300"
            borderTopLeftRadius={8}
            borderTopRightRadius={8}
            gap="$space.16"
            paddingHorizontal="$space.24"
            paddingVertical="$space.16"
          >
            <Squircle backgroundColor="$blue.200" icon="information" />
            <Typography.Text>
              <FormattedMessage
                id="pricing.migration.widget.body"
                values={{
                  migrationDate: new Date(
                    planMigrationData.migrationDate,
                  ).toLocaleDateString(),
                  newPlan: planMigrationData.targetPlan.brandName,
                }}
              />
            </Typography.Text>
            <View flexGrow={1}></View>
            <View
              onPress={() => {
                logEvent({
                  name: `Plan Migration Modal Opened`,
                  properties: {
                    from: 'account-subscription-page',
                  },
                });

                openPlanMigrationModal();
              }}
            >
              <Typography.Link forceIcon={false} variant="primary">
                <FormattedMessage id="pricing.migration.widget.more_info" />
              </Typography.Link>
            </View>
          </XStack>
        ) : null}
        <Body>
          <Flex direction="column" gap="space-12">
            <Flex align="center" gap="space-12">
              {currentPlan ? (
                <>
                  <View
                    backgroundColor={getPlanCardColor(currentPlan.brandColor)}
                    borderRadius={4}
                    height={14}
                    width={14}
                  />

                  <Typography.HeaderNumber size="large">
                    {currentPlan.brandName}
                  </Typography.HeaderNumber>
                </>
              ) : (
                <SkeletonText minWidth="space-120" skeletonHeight="36" />
              )}
            </Flex>

            <Flex direction="column" gap="space-4">
              {trialPeriod.isOngoing ? (
                <Flex align="center" gap="space-6">
                  <Icon color="$grey.800" icon="sparkles" size="small" />

                  <Typography.Text bold>
                    <FormattedMessage
                      id="subscription.overview.header.end_of_trial_period"
                      values={{
                        duration: formatDuration(trialPeriod.duration, {
                          format: ['years', 'months', 'days'],
                        }),
                      }}
                    />
                  </Typography.Text>
                </Flex>
              ) : null}

              {nextBillingPeriod ? (
                <Typography.Text variant="secondary">
                  <FormattedMessage
                    id="subscription.overview.header.next_billing_date_no_amount"
                    values={{
                      date: (
                        <FormattedDate
                          day="numeric"
                          month="short"
                          value={nextBillingPeriod}
                          year="numeric"
                        />
                      ),
                    }}
                  />
                </Typography.Text>
              ) : null}
            </Flex>
          </Flex>

          {isLoaded ? (
            <Button alignSelf="start" as={Link} to={routes.plans}>
              <FormattedMessage id="subscription.overview.header.compare_offers" />
            </Button>
          ) : (
            <Skeleton height="space-40" width="space-128" />
          )}
        </Body>
        <Footer>
          <FooterItem
            borderBottomLeftRadius="radius-8"
            borderRight="1px solid"
            borderRightColor="grey.300"
          >
            <Squircle
              backgroundColor="$grey.200"
              icon="calendar"
              size="$icon.massive"
            />

            <Flex justifyContent="space-between" width="100%">
              <Flex direction="column">
                <Typography.Text bold>
                  <FormattedMessage id="subscription.overview.header.billing.label" />
                </Typography.Text>

                {currentPlan ? (
                  <Typography.Text size="small" variant="secondary">
                    <FormattedMessage
                      id={`subscription.overview.header.billing.frequency.${currentPlan.billingFrequency === SubscriptionPlanFrequency.Monthly ? 'monthly' : 'yearly'}`}
                    />
                  </Typography.Text>
                ) : (
                  <SkeletonText skeletonHeight="20" />
                )}
              </Flex>
              {hasScheduledDowngrade ? null : (
                <ModifyPlanButton planId={currentPlan?.id} />
              )}
            </Flex>
          </FooterItem>

          <FooterItem borderBottomRightRadius="radius-8">
            <Image
              backgroundColor="grey.200"
              borderRadius="radius-8"
              boxSize="space-40"
              src={wallet}
            />

            <Flex direction="column">
              <Typography.Text bold>
                <FormattedMessage id="subscription.overview.header.payment_method.label" />
              </Typography.Text>

              {currentPlan ? (
                <Typography.Text size="small" variant="secondary">
                  <FormattedMessage id="subscription.overview.header.payment_method.value" />
                </Typography.Text>
              ) : (
                <SkeletonText skeletonHeight="20" />
              )}
            </Flex>
          </FooterItem>
        </Footer>
      </Container>
    </>
  );
};
