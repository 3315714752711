import { type FC } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Typography, XStack, YStack } from '@shinetools/sunshine-universal';

import { type PricingPlan } from '__generated__/GQL';
import { FormattedPrice } from 'components/FormattedPrice';

interface MonthlyPlanProps {
  monthlyPlan: PricingPlan;
  discountPeriodEnd: string | null;
}

export const MonthlyPlan: FC<MonthlyPlanProps> = ({
  discountPeriodEnd,
  monthlyPlan,
}) => (
  <>
    <YStack gap="$space.4">
      <XStack gap="$space.6">
        {monthlyPlan?.migrationPeriod &&
        monthlyPlan?.migrationPeriod.pricing?.taxExcluded !==
          monthlyPlan.pricing.taxExcluded ? (
          <Typography.Text textDecorationLine="line-through" variant="discreet">
            <FormattedPrice valueInCents={monthlyPlan.pricing.taxExcluded} />
          </Typography.Text>
        ) : null}
        <Typography.Text bold>
          <FormattedMessage
            id="subscription.plans_details.plan_options.monthly.price"
            values={{
              price: (
                <Typography.Text>
                  <FormattedPrice
                    valueInCents={
                      monthlyPlan?.migrationPeriod
                        ? monthlyPlan?.migrationPeriod?.pricing.taxExcluded
                        : monthlyPlan.pricing.taxExcluded
                    }
                  />
                </Typography.Text>
              ),
            }}
          />
        </Typography.Text>
      </XStack>

      {monthlyPlan?.migrationPeriod &&
      monthlyPlan?.migrationPeriod.numberOfDiscountedWeeks &&
      discountPeriodEnd ? (
        <Typography.Text bold size="small">
          <FormattedMessage
            id="subscription.plans_details.plan_options.migration_date"
            values={{
              migrationDate: (
                <FormattedDate
                  day="numeric"
                  month="short"
                  value={discountPeriodEnd}
                  year="numeric"
                />
              ),
            }}
          />
        </Typography.Text>
      ) : (
        <Typography.Text bold size="small">
          <FormattedMessage id="subscription.plans_details.plan_options.full_price" />
        </Typography.Text>
      )}
    </YStack>
  </>
);
