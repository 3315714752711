import { createContext, useContext } from 'react';

import { type PricingPlanFee } from '__generated__/GQL';

import type * as GQL from './fees.gql';

type SubscriptionPlan = NonNullable<
  GQL.SubscriptionManagementFeesQuery['viewer']['company']['subscriptionPlan']
>;
type CurrentPlan = NonNullable<
  GQL.SubscriptionManagementFeesQuery['viewer']['company']['currentPlan']
>;
type Quotas = SubscriptionPlan['quotas'];
type Fees = CurrentPlan['fees'];

export type FeesContext = {
  plan: CurrentPlan;
  fees: Fees;
  quotas: Quotas;
};

export const FeesCtx = createContext<FeesContext | null>(null);

export const usePlan = () => {
  const context = useContext(FeesCtx);

  if (!context) {
    throw new Error('usePlan must be used within a Fees.Provider');
  }

  return context.plan;
};

export const useFees = () => {
  const context = useContext(FeesCtx);

  if (!context) {
    throw new Error('useFees must be used within a Fees.Provider');
  }

  return {
    ...context,
    toFixed: (fee: Pick<PricingPlanFee, 'fixed'>) => {
      if (!fee.fixed) {
        return 0;
      }

      return fee.fixed / 100;
    },
    toVariable: (fee: Pick<PricingPlanFee, 'variable'>) => {
      if (!fee.variable) {
        return 0;
      }

      return fee.variable * 100;
    },
  };
};
