import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    rateLimitError:
      'You have reached the attempts limit. Please try again in 1 hour.',
    unknownError: 'There was an unknown error',
    wrongPasscode: 'Incorrect code',
  },
  fr: {
    rateLimitError:
      'Vous avez atteint la limite des tentatives autorisées. Veuillez réessayer dans 1 heure.',
    unknownError: 'Une erreur inconnue est survenue',
    wrongPasscode: 'Code erroné',
  },
} as const);

export default locales;
