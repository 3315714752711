/**
 * https://www.figma.com/file/GXtg4B5Uy32yVEBVUJahQF/%F0%9F%90%A5-NEW-DESIGN-SYSTEM?node-id=12444%3A56120&t=QqD0LjrrwAvmZccY-1
 */

import { modalAnatomy } from '@chakra-ui/anatomy';
import {
  type PartsStyleFunction,
  type SystemStyleFunction,
  type SystemStyleObject,
} from '@chakra-ui/theme-tools';

export type Parts = typeof modalAnatomy;

const baseStyleOverlay: SystemStyleObject = {
  backgroundColor: 'rgba(37, 36, 29, 0.48)',
  zIndex: 'modal',
};

const baseStyleDialogContainer: SystemStyleFunction = ({ scrollBehavior }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  overflow: scrollBehavior === 'inside' ? 'hidden' : 'auto',
  zIndex: 'modal',
});

const baseStyleDialog: SystemStyleFunction = ({ scrollBehavior }) => ({
  background: 'white',
  borderRadius: 'radius-8',
  color: 'inherit',
  marginY: 'space-40',
  maxHeight: scrollBehavior === 'inside' ? 'calc(100% - 7.5rem)' : undefined,
  zIndex: 'modal',
});

const baseStyleHeader: SystemStyleObject = {
  borderBottom: '1px solid',
  borderColor: 'border',
  display: 'flex',
  flexShrink: 0,
  fontSize: 'font-22',
  fontWeight: 'weight-500',
  minHeight: 'space-64',
  paddingX: 'space-16',
  paddingY: 'space-16',
};

const baseStyleCloseButton: SystemStyleObject = {
  _hover: {
    backgroundColor: 'grey.300',
  },
  backgroundColor: 'grey.200',
  borderRadius: 'radius-full',
  color: 'grey.800',
  fontSize: 'font-16',
  fontWeight: 'weight-700',
  height: 'space-40',
  transition: 'background-color 0.2s ease-out',
  width: 'space-40',
};

const baseStyleBody: SystemStyleFunction = ({ scrollBehavior }) => ({
  flex: 1,
  overflow: scrollBehavior === 'inside' ? 'auto' : undefined,
  padding: 'space-32',
});

const baseStyleFooter: SystemStyleObject = {
  border: '1px solid',
  borderBottomRadius: 'radius-8',
  borderColor: 'grey.3',
  boxShadow: 'shadow-small-2',
  display: 'flex',
  justifyContent: 'space-between',
  paddingX: 'space-32',
  paddingY: 'space-16',
};

const baseStyle: PartsStyleFunction<Parts> = (props) => ({
  body: baseStyleBody(props),
  closeButton: baseStyleCloseButton,
  dialog: baseStyleDialog(props),
  dialogContainer: baseStyleDialogContainer(props),
  footer: baseStyleFooter,
  header: baseStyleHeader,
  overlay: baseStyleOverlay,
});

type Size = 'md' | 'lg';

const sizes: Record<Size, SystemStyleObject> = {
  lg: { dialog: { maxWidth: '720px' } },
  md: { dialog: { maxWidth: '600px' } },
};

const defaultProps = {
  size: 'md',
};

const ModalTheme = {
  baseStyle,
  defaultProps,
  parts: modalAnatomy.keys,
  sizes,
};

export default ModalTheme;
