import * as Types from '../../../../../__generated__/GQL';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AuthenticationChallengeResultPublicSubscriptionVariables = Types.Exact<{
  challengeId: Types.Scalars['UUIDv4']['input'];
}>;


export type AuthenticationChallengeResultPublicSubscription = { __typename: 'Subscription', authenticationChallengeResultPublic: { __typename: 'VerifyChallengeOutput', name: string, method: string, secureSessionToken: string | null, challengeId: string } };


export const AuthenticationChallengeResultPublicDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"authenticationChallengeResultPublic"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"challengeId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUIDv4"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"authenticationChallengeResultPublic"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"challengeId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"challengeId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"method"}},{"kind":"Field","name":{"kind":"Name","value":"secureSessionToken"}},{"kind":"Field","name":{"kind":"Name","value":"challengeId"}}]}}]}}]} as unknown as DocumentNode<AuthenticationChallengeResultPublicSubscription, AuthenticationChallengeResultPublicSubscriptionVariables>;