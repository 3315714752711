import {
  type Device,
  type StrongAuthenticationRequired,
} from '__generated__/GQL';
import config from 'config';
import { patch } from 'helpers/fetch';

const { shineApiHost } = config;

export const enrollTrustedDeviceWithEmail = async (
  deviceId: string,
  uniqueId: string,
  secureSessionToken: string,
): Promise<Device | StrongAuthenticationRequired> => {
  return patch(
    `${shineApiHost}/authentication/sca/devices/${deviceId}/enroll-trusted-with-email`,
    {},
    {
      headers: {
        'origin-device-unique-id': uniqueId,
        'shine-secure-session-token': secureSessionToken,
      },
    },
  )
    .then((res) => {
      return res.data as Device;
    })
    .catch((err) => {
      if ('status' in err && err.status === 428) {
        const { status, ...strongAuthenticationRequired } = err;
        return strongAuthenticationRequired;
      }
      return Promise.reject(err);
    });
};
