import type React from 'react';
import { type PropsWithChildren } from 'react';
import {
  Icon,
  type TextProps,
  Typography,
  XStack,
} from '@shinetools/sunshine-universal';

export type FeatureInfoProps = {
  isAvailable?: boolean;
  variant?: TextProps['variant'];
};

const Center: React.FC<PropsWithChildren> = ({ children }) => (
  <XStack justifyContent="center">{children}</XStack>
);

export const FeatureInfo: React.FC<PropsWithChildren<FeatureInfoProps>> = ({
  children,
  isAvailable,
  variant,
}) => {
  if (children) {
    return (
      <Center>
        <Typography.Text size="tiny" variant={variant}>
          {children}
        </Typography.Text>
      </Center>
    );
  }

  return (
    <Center>
      {isAvailable ? (
        <Icon color="$green.600" icon="tick" size="small" />
      ) : (
        <Icon color="$red.600" icon="cross" size="small" />
      )}
    </Center>
  );
};
