import { type FC, type PropsWithChildren, type ReactNode } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Center,
  type CenterProps,
  SimpleGrid,
} from '@chakra-ui/react';
import { Icon, View, XStack } from '@shinetools/sunshine-universal';

import SkeletonButton from 'components/SkeletonButton';

const SubscriptionManagementContainer: FC<PropsWithChildren & CenterProps> = ({
  children,
  ...props
}) => (
  <Center
    alignItems="flex-start"
    paddingX={{ base: 40, lg: 60, xl: 92 }}
    paddingY={{ base: 'space-40', xl: 'space-64' }}
    {...props}
  >
    {children}
  </Center>
);

export type SubscriptionManagementLayoutProps = {
  prevRoute: string;
  loading?: boolean;
  asideContent?: ReactNode;
  headerContent?: ReactNode;
};

export const SubscriptionManagementLayout: FC<
  PropsWithChildren<SubscriptionManagementLayoutProps>
> = ({ asideContent, children, headerContent, loading, prevRoute }) => {
  const mainContainer = (
    <SubscriptionManagementContainer
      justifyContent={asideContent ? 'flex-end' : 'center'}
    >
      <Box maxWidth={asideContent ? 560 : 1256} width="100%">
        <XStack
          alignItems="flex-start"
          gap="$space.32"
          justifyContent="center"
          marginBottom="$space.40"
        >
          <View flexGrow={1} justifyContent="flex-start">
            {loading ? (
              <SkeletonButton />
            ) : (
              <Button
                as={Link}
                leftIcon={<Icon icon="arrow-left" />}
                marginRight="auto"
                to={prevRoute}
                variant="secondary"
              >
                Retour
              </Button>
            )}
          </View>
          {headerContent}
          <View flexGrow={1}></View>
        </XStack>

        {children}
      </Box>
    </SubscriptionManagementContainer>
  );

  return asideContent ? (
    <SimpleGrid columns={2} height="full">
      {mainContainer}

      <SubscriptionManagementContainer
        backgroundColor="grey.200"
        justifyContent="flex-start"
      >
        {asideContent}
      </SubscriptionManagementContainer>
    </SimpleGrid>
  ) : (
    mainContainer
  );
};
