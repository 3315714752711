import { BulletPoints } from '../../BulletPoints';

import { type MigrationContentComponent } from '..';

export const ProToPlusBulletPoints: MigrationContentComponent = ({
  isInPrepaidPeriod,
  newPlan,
}) => {
  return (
    <BulletPoints
      alternativePlan={isInPrepaidPeriod ? undefined : 'start'}
      items={[
        {
          label: 'pricing.migration.page.plans.plus.bullets.keep_pro_features',
        },
        { label: 'pricing.migration.page.plans.plus.bullets.employee' },
        {
          details:
            'pricing.migration.page.plans.plus.bullets.insurances.details',
          label: 'pricing.migration.page.plans.plus.bullets.insurances',
        },
        {
          label: 'pricing.migration.page.plans.plus.bullets.cash_deposit_fees',
        },
      ]}
      newPlan={newPlan}
    />
  );
};
