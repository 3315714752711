import { useCallback, useState } from 'react';

import {
  ChallengeStateName,
  type VerifyChallengeOutput,
} from '__generated__/GQL';

import { RejectStrongAuthenticationOperation } from '../utils/errors';
import { type ExecuteStrongAuthenticationOperationFunction } from '../utils/types';

export type UseExecuteSCAOperationPayload = {
  payload: unknown;
  executeStrongAuthenticationOperation: ExecuteStrongAuthenticationOperationFunction;
};

export const useExecuteSCAOperation = ({
  executeStrongAuthenticationOperation,
  payload,
}: UseExecuteSCAOperationPayload) => {
  const [isRequesting, setIsRequesting] = useState(false);

  const executeOperation = useCallback(
    async (verificationResult: VerifyChallengeOutput) => {
      setIsRequesting(true);
      if (
        verificationResult.name === ChallengeStateName.ChallengeApproved &&
        verificationResult.secureSessionToken
      ) {
        await executeStrongAuthenticationOperation(
          verificationResult.secureSessionToken,
          payload,
        );
        return;
      }

      if (verificationResult.name !== ChallengeStateName.ChallengeApproved) {
        // FIXME should have proper typing in GQL
        throw new RejectStrongAuthenticationOperation(
          verificationResult.name as ChallengeStateName,
        );
      }
    },
    [executeStrongAuthenticationOperation, payload],
  );

  return {
    executeOperation,
    isRequesting,
  };
};
