import { type FC } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Flex, Grid, Image } from '@chakra-ui/react';
import {
  Icon,
  Typography,
  View,
  XStack,
  YStack,
} from '@shinetools/sunshine-universal';

import { SubscriptionPlanFrequency } from '__generated__/GQL';
import SunshineCard from 'components/_core/SunshineCard';
import { PLANS_CARD_IMAGES } from 'features/SubscriptionManagement/lib/cardImages';
import { PLANS_METADATA } from 'features/SubscriptionManagement/pages/Plans/libs/plansMetadata';
import { type SubscriptionManagementPlansDetailFragment } from 'features/SubscriptionManagement/subscriptionManagementPlansDetail.gql';

import { getPlanCardColor } from '../../../../../../common/plans/card';

type PlanRecapProps = {
  targetPricingPlan: SubscriptionManagementPlansDetailFragment;
};

const PlanRecap: FC<PlanRecapProps> = ({ targetPricingPlan }) => (
  <SunshineCard.Group maxWidth={540} overflow="hidden">
    <SunshineCard
      backgroundColor="grey.100"
      borderRadius="radius-0"
      overflow="hidden"
      padding="0!"
    >
      <Grid gridTemplateColumns="1fr auto">
        <YStack paddingLeft="$space.32" paddingVertical="$space.32">
          <Flex align="center" gap="space-12" marginBottom="space-8">
            <View
              backgroundColor={getPlanCardColor(targetPricingPlan.brandColor)}
              borderRadius={4}
              height={14}
              width={14}
            />
            <Typography.Header size="large">
              <FormattedMessage
                id="subscription.plan_details.selected_offer_title"
                values={{
                  planName: targetPricingPlan.brandName,
                }}
              />
            </Typography.Header>
          </Flex>

          <Typography.Text bold variant="primary">
            <FormattedMessage
              id={
                targetPricingPlan.billingFrequency ===
                SubscriptionPlanFrequency.Yearly
                  ? 'subscription.plan_details.selected_offer_pricing.yearly'
                  : 'subscription.plan_details.selected_offer_pricing'
              }
              values={{
                price: (
                  <FormattedNumber
                    currency="EUR"
                    minimumFractionDigits={
                      targetPricingPlan.pricing.taxExcluded % 100 ? 2 : 0
                    }
                    style="currency"
                    value={targetPricingPlan.pricing.taxExcluded / 100}
                  />
                ),
                trialPeriodLength: 0,
              }}
            />
          </Typography.Text>
        </YStack>

        <Image
          alignSelf="end"
          height={105}
          marginRight="space-8"
          objectFit="cover"
          objectPosition="top"
          src={PLANS_CARD_IMAGES[targetPricingPlan.id]}
          width={215}
        />
      </Grid>
    </SunshineCard>

    <SunshineCard paddingX="space-32" paddingY="space-32!">
      <Typography.Text bold size="large">
        <FormattedMessage
          id="subscription.plan_details.summary.subtitle"
          values={{
            planName: targetPricingPlan.brandName,
          }}
        />
      </Typography.Text>

      <YStack gap="$space.24" marginTop="$space.32">
        {PLANS_METADATA[targetPricingPlan.id].summary.map(
          ({ description, icon }) => {
            const descriptionNode = description(targetPricingPlan);

            return (
              <XStack alignItems="center" gap="$space.16" key={icon}>
                <Icon icon={icon} />
                <Typography.Text>{descriptionNode}</Typography.Text>
              </XStack>
            );
          },
        )}
      </YStack>
    </SunshineCard>
  </SunshineCard.Group>
);

export default PlanRecap;
