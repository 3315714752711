import { useCallback, useState } from 'react';

import { type ChallengeMethod } from '__generated__/GQL';

import { type ExecuteStrongAuthenticationOperationProps } from '../utils/types';

export const useInitiateSCAOperation = ({
  executeStrongAuthenticationOperation,
  payload,
}: ExecuteStrongAuthenticationOperationProps) => {
  const [isInitiating, setIsInitiating] = useState(false);

  const [challengeId, setChallengeId] = useState<string | undefined>(undefined);
  const [challengeMethod, setChallengeMethod] = useState<
    ChallengeMethod | undefined
  >(undefined);
  const [operation, setOperation] = useState<string | undefined>(undefined);
  const [isMdaInitiator, setIsMdaInitiator] = useState(false);
  const [originDeviceId, setOriginDeviceId] = useState<string | null>(null);

  const [error, setError] = useState<Error | null>(null);

  const initiateOperation = useCallback(async () => {
    if (!challengeId) {
      setIsInitiating(true);
      try {
        if (!isInitiating) {
          const result = await executeStrongAuthenticationOperation(
            '',
            payload,
          );

          if (result) {
            setChallengeId(result.challengeId);
            setChallengeMethod(result.method);
            setIsMdaInitiator(result.isMdaInitiator);
            setOperation(result.operation);
            setOriginDeviceId(result.originDeviceId);
          }
        }
      } catch (err) {
        // otherwise send error to parent component to handle
        setError(err as Error);
      }
    }
  }, [
    challengeId,
    isInitiating,
    executeStrongAuthenticationOperation,
    payload,
    setChallengeMethod,
    setChallengeId,
    setError,
    setOperation,
    setIsMdaInitiator,
    setOriginDeviceId,
  ]);

  return {
    challengeId,
    challengeMethod,
    error,
    initiateOperation,
    isInitiating,
    isMdaInitiator,
    operation,
    originDeviceId,
  };
};
