import { differenceInMinutes } from 'date-fns';

import type * as GQL from '../graphql/overview.gql';

type ActiveInsurance = {
  isActive: true;
};

type DisabledInsurance = {
  isActive: false;
  plan: string;
};

export const getInsuranceCoverage = (
  data?: GQL.SubscriptionManagementOverviewQuery,
): ActiveInsurance | DisabledInsurance => {
  const subscription = data?.viewer.company.subscriptionPlan;
  const currentPlan = data?.viewer.company.currentPlan;

  if (!subscription || !currentPlan || !subscription.isLateOnPayment) {
    return {
      isActive: true,
    };
  }

  // This is to avoid showing the error message when the user is in the process of paying the invoice right after a plan change.
  if (
    subscription.isLateOnPayment &&
    Math.max(
      differenceInMinutes(new Date(), new Date(subscription.updatedAt)),
      0,
    ) < 15
  ) {
    return {
      isActive: true,
    };
  }

  return {
    isActive: false,
    plan: currentPlan.brandName,
  };
};
