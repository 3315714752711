import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    codeExpired: 'Code expired',
    confirm: 'Confirm',
    description:
      'As a security measure, please verify your identity using the code you received on your phone.',
    rateLimitError:
      'You have reached the attempts limit. Please try again in 1 hour.',
    resend: 'Send a new code',
    sentToPlural: 'Sent to **{phone}**. The code expires in {timer} seconds',
    sentToSingular: 'Sent to **{phone}**. The code expires in {timer} second',
    title: 'Confirm this action with the code received by text message',
    unknownError: 'There was an unknown error',
    wrongPasscode: 'Incorrect code',
  },
  fr: {
    codeExpired: 'Code expiré',
    confirm: 'Confirmer',
    description:
      'Pour plus de sécurité, vérifiez votre identité à l’aide du code reçu sur votre téléphone.',
    rateLimitError:
      'Vous avez atteint la limite des tentatives autorisées. Veuillez réessayer dans 1 heure.',
    resend: 'Envoyer un nouveau code',
    sentToPlural: 'Envoyé au **{phone}**. Le code expire dans {timer} secondes',
    sentToSingular:
      'Envoyé au **{phone}**. Le code expire dans {timer} seconde',
    title: 'Confirmez cette action avec le code reçu par SMS',
    unknownError: 'Une erreur inconnue est survenue',
    wrongPasscode: 'Code erroné',
  },
} as const);

export default locales;
