import type React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import {
  Callout,
  Icon,
  View,
  XStack,
  YStack,
} from '@shinetools/sunshine-universal';

import { getPlanDetails as getPlanDetailsPath } from 'features/SubscriptionManagement/routes';

import { type Plan } from '../../../../types';
import { formatPrice } from '../../../../utils';

export type MigrateNowCalloutProps = {
  targetPlan: Plan;
  currentPlan: Plan;
  discountPeriodEnd: Date | null;
  prepaidPeriodEnd: Date | null;
  isPriceIncreased: boolean;
};

export const MigrateNowCallout: React.FC<MigrateNowCalloutProps> = ({
  currentPlan,
  discountPeriodEnd,
  isPriceIncreased,
  prepaidPeriodEnd,
  targetPlan,
}) => {
  const history = useHistory();

  const renderContent = () => {
    if (prepaidPeriodEnd) {
      return (
        <YStack gap="$space.2">
          <Callout.Title>
            <FormattedMessage
              id="pricing.migration.page.callout.prepaid.title"
              values={{
                prepaidPeriodEnd: prepaidPeriodEnd.toLocaleDateString(),
              }}
            />
          </Callout.Title>
          <Callout.Content size="small">
            <FormattedMessage
              id="pricing.migration.page.callout.prepaid.content"
              values={{
                newPlan: targetPlan.brandName,
                newPrice: formatPrice(targetPlan.pricing.taxExcluded),
                oldPrice: formatPrice(currentPlan.pricing.taxExcluded),
              }}
            />
          </Callout.Content>
        </YStack>
      );
    }

    if (!isPriceIncreased) {
      return (
        <Callout.Content size="small">
          <FormattedMessage
            id="pricing.migration.page.callout.simple.content"
            values={{
              newPlan: targetPlan.brandName,
            }}
          />
        </Callout.Content>
      );
    }

    if (discountPeriodEnd) {
      return (
        <YStack gap="$space.2">
          <Callout.Title>
            <FormattedMessage
              id="pricing.migration.page.callout.discount.title"
              values={{
                newPlan: targetPlan.brandName,
              }}
            />
          </Callout.Title>
          <Callout.Content size="small">
            <FormattedMessage
              id="pricing.migration.page.callout.discount.content"
              values={{
                discountPeriodEnd: discountPeriodEnd.toLocaleDateString(),
              }}
            />
          </Callout.Content>
        </YStack>
      );
    }

    return null;
  };

  return (
    <View marginBottom="$space.40">
      <Callout variant="information" withIcon={false}>
        <XStack gap="$space.16">
          <Icon color="$blue.600" icon="sparkles" />
          <YStack flexShrink={1} gap="$space.12">
            {renderContent()}
            {prepaidPeriodEnd ? null : (
              <Callout.Action
                onPress={() => {
                  history.push({
                    pathname: getPlanDetailsPath(targetPlan.id),
                    state: {
                      fromOverviewPage: true,
                    },
                  });
                }}
              >
                <FormattedMessage
                  id="pricing.migration.page.callout.cta"
                  values={{
                    plan: targetPlan.brandName,
                  }}
                />
              </Callout.Action>
            )}
          </YStack>
        </XStack>
      </Callout>
    </View>
  );
};
